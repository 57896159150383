/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AdditionalData,
    AdditionalDataFromJSON,
    AdditionalDataFromJSONTyped,
    AdditionalDataToJSON,
} from './AdditionalData';
import {
    Address,
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';
import {
    PayTransactionCard,
    PayTransactionCardFromJSON,
    PayTransactionCardFromJSONTyped,
    PayTransactionCardToJSON,
} from './PayTransactionCard';
import {
    PayTransactionForeignExchangeInfo,
    PayTransactionForeignExchangeInfoFromJSON,
    PayTransactionForeignExchangeInfoFromJSONTyped,
    PayTransactionForeignExchangeInfoToJSON,
} from './PayTransactionForeignExchangeInfo';
import {
    PayTransactionInstallment,
    PayTransactionInstallmentFromJSON,
    PayTransactionInstallmentFromJSONTyped,
    PayTransactionInstallmentToJSON,
} from './PayTransactionInstallment';

/**
 * null
 * @export
 * @interface PayTransaction
 */
export interface PayTransaction {
    /**
     * The ID of a transaction.

> **Note** This transactions ID is the same that is returned in the response from the /initialize API.

     * @type {number}
     * @memberof PayTransaction
     */
    id: number;
    /**
     * ‘true’ indicates that customers want to store their card.

> **Note** Customers cannot save payment types such as Apple Pay, and VISA checkout.

     * @type {boolean}
     * @memberof PayTransaction
     */
    storeCard?: boolean;
    /**
     * 
     * @type {PayTransactionCard}
     * @memberof PayTransaction
     */
    card: PayTransactionCard;
    /**
     * The Message Authentication Code. It is calculated by creating a sha-512 hash comprising the following input fields in the listed order:

- clientid 
- account 
- orderid 
- callback-url 
- amount 
- auth-url 
- customer-ref 
- auth-token 
- email
- mobile
- [salt]

> **Note**: CPD provides the salt value to merchants.

MAC calculation secures the information sent by the merchant to Velocity by applying the SHA-512 encryption algorithm on key parts of the information sent to protect against tampering. The ‘salt’ is the merchant's shared secret string used to ensure that MAC is unique.

> **Note** If you opt foreign exchange, then HMAC values are from the foreign exchange API.

     * @type {string}
     * @memberof PayTransaction
     */
    hmac?: string | null;
    /**
     * 
     * @type {Address}
     * @memberof PayTransaction
     */
    billingAddress?: Address;
    /**
     * 
     * @type {PayTransactionInstallment}
     * @memberof PayTransaction
     */
    installment?: PayTransactionInstallment;
    /**
     * 
     * @type {PayTransactionForeignExchangeInfo}
     * @memberof PayTransaction
     */
    foreignExchangeInfo?: PayTransactionForeignExchangeInfo;
    /**
     * 
     * @type {AdditionalData}
     * @memberof PayTransaction
     */
    additionalData?: AdditionalData;
}

export function PayTransactionFromJSON(json: any): PayTransaction {
    return PayTransactionFromJSONTyped(json, false);
}

export function PayTransactionFromJSONTyped(json: any, ignoreDiscriminator: boolean): PayTransaction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'storeCard': !exists(json, 'store_card') ? undefined : json['store_card'],
        'card': PayTransactionCardFromJSON(json['card']),
        'hmac': !exists(json, 'hmac') ? undefined : json['hmac'],
        'billingAddress': !exists(json, 'billing_address') ? undefined : AddressFromJSON(json['billing_address']),
        'installment': !exists(json, 'installment') ? undefined : PayTransactionInstallmentFromJSON(json['installment']),
        'foreignExchangeInfo': !exists(json, 'foreign_exchange_info') ? undefined : PayTransactionForeignExchangeInfoFromJSON(json['foreign_exchange_info']),
        'additionalData': !exists(json, 'additional_data') ? undefined : AdditionalDataFromJSON(json['additional_data']),
    };
}

export function PayTransactionToJSON(value?: PayTransaction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'store_card': value.storeCard,
        'card': PayTransactionCardToJSON(value.card),
        'hmac': value.hmac,
        'billing_address': AddressToJSON(value.billingAddress),
        'installment': PayTransactionInstallmentToJSON(value.installment),
        'foreign_exchange_info': PayTransactionForeignExchangeInfoToJSON(value.foreignExchangeInfo),
        'additional_data': AdditionalDataToJSON(value.additionalData),
    };
}

