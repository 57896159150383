/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Format,
    FormatFromJSON,
    FormatFromJSONTyped,
    FormatToJSON,
} from './Format';

/**
 * The amount charged for the given entity.
 * @export
 * @interface Amount
 */
export interface Amount {
    /**
     * Amount charged in the currency's smallest monetary unit.
     * @type {number}
     * @memberof Amount
     */
    value: number;
    /**
     * Country code based on ISO 3166-1 alpha-2 standard:
  * US - usa
  * CN - china
  * JP - japan
  * DE - germany
  * GB - uk
  * IN - india
  * FR - france
  * IT - italy
  * CA - canada

Full list: https://www.iso.org/obp/ui

     * @type {string}
     * @memberof Amount
     */
    country?: string;
    /**
     * ISO 4217 currency code.
     * @type {string}
     * @memberof Amount
     */
    currency?: string;
    /**
     * The highest decimal round off used by the given currency.
     * @type {number}
     * @memberof Amount
     */
    decimals?: number;
    /**
     * 
     * @type {Format}
     * @memberof Amount
     */
    format?: Format;
    /**
     * The symbol that is used to represent the currency (kr. $, € etc.).
     * @type {string}
     * @memberof Amount
     */
    symbol?: string;
    /**
     * null
     * @type {string}
     * @memberof Amount
     */
    taxCode?: string;
    /**
     * null
     * @type {number}
     * @memberof Amount
     */
    typeId?: number;
}

export function AmountFromJSON(json: any): Amount {
    return AmountFromJSONTyped(json, false);
}

export function AmountFromJSONTyped(json: any, ignoreDiscriminator: boolean): Amount {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': json['value'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'currency': !exists(json, 'currency') ? undefined : json['currency'],
        'decimals': !exists(json, 'decimals') ? undefined : json['decimals'],
        'format': !exists(json, 'format') ? undefined : FormatFromJSON(json['format']),
        'symbol': !exists(json, 'symbol') ? undefined : json['symbol'],
        'taxCode': !exists(json, 'tax_code') ? undefined : json['tax_code'],
        'typeId': !exists(json, 'type_id') ? undefined : json['type_id'],
    };
}

export function AmountToJSON(value?: Amount | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': value.value,
        'country': value.country,
        'currency': value.currency,
        'decimals': value.decimals,
        'format': FormatToJSON(value.format),
        'symbol': value.symbol,
        'tax_code': value.taxCode,
        'type_id': value.typeId,
    };
}

