/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Action,
    ActionFromJSON,
    ActionFromJSONTyped,
    ActionToJSON,
} from './Action';

/**
 * The parsed challenge is an HTML form returned to instruct the client to invoke / submit the same to complete a third party verification process.
This process is usually the 3D verification payment authentication request that needs to be completed by the user's browser.

 * @export
 * @interface AuthorizeResponseParsedChallenge
 */
export interface AuthorizeResponseParsedChallenge {
    /**
     * 
     * @type {Action}
     * @memberof AuthorizeResponseParsedChallenge
     */
    action?: Action;
}

export function AuthorizeResponseParsedChallengeFromJSON(json: any): AuthorizeResponseParsedChallenge {
    return AuthorizeResponseParsedChallengeFromJSONTyped(json, false);
}

export function AuthorizeResponseParsedChallengeFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthorizeResponseParsedChallenge {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'action': !exists(json, 'action') ? undefined : ActionFromJSON(json['action']),
    };
}

export function AuthorizeResponseParsedChallengeToJSON(value?: AuthorizeResponseParsedChallenge | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'action': ActionToJSON(value.action),
    };
}

