/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * The point of sale (POS) / storefront used by the merchant to make the transaction. 
This is identified uniquely based on the ID and the markup.

 * @export
 * @interface Account
 */
export interface Account {
    /**
     * The unique ID associated with a merchant's storefront.
     * @type {number}
     * @memberof Account
     */
    id?: number;
    /**
     * The markup indicates the user-agent of the storefront. 
The system allowed values are:

- `app` - for native device apps.
- `xhtml` - for a web client. 
- `html5` - for a mobile web client.
- `ios` - for ios apps.
- `android` - for android apps.

     * @type {string}
     * @memberof Account
     */
    markup: string;
}

export function AccountFromJSON(json: any): Account {
    return AccountFromJSONTyped(json, false);
}

export function AccountFromJSONTyped(json: any, ignoreDiscriminator: boolean): Account {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'markup': json['markup'],
    };
}

export function AccountToJSON(value?: Account | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'markup': value.markup,
    };
}

