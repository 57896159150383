/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ClientInfo,
    ClientInfoFromJSON,
    ClientInfoFromJSONTyped,
    ClientInfoToJSON,
} from './ClientInfo';
import {
    PayTransaction,
    PayTransactionFromJSON,
    PayTransactionFromJSONTyped,
    PayTransactionToJSON,
} from './PayTransaction';

/**
 * 
 * @export
 * @interface PayRequest
 */
export interface PayRequest {
    /**
     * The unique ID configured for a merchant in Velocity.
     * @type {number}
     * @memberof PayRequest
     */
    clientId: number;
    /**
     * The number for a sub-account with which a payment transaction is associated.
The account ID is an integer greater than 100000 and account number is an integer smaller than 1000.

The payment transaction is associated with the default sub-account if merchants do not provide this parameter.

     * @type {number}
     * @memberof PayRequest
     */
    account?: number;
    /**
     * 
     * @type {PayTransaction}
     * @memberof PayRequest
     */
    transaction: PayTransaction;
    /**
     * Auth token used to perform a Single Sign-On (SSO) using third party user management system.
     * @type {string}
     * @memberof PayRequest
     */
    authToken?: string;
    /**
     * 
     * @type {ClientInfo}
     * @memberof PayRequest
     */
    clientInfo: ClientInfo;
}

export function PayRequestFromJSON(json: any): PayRequest {
    return PayRequestFromJSONTyped(json, false);
}

export function PayRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PayRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'clientId': json['client_id'],
        'account': !exists(json, 'account') ? undefined : json['account'],
        'transaction': PayTransactionFromJSON(json['transaction']),
        'authToken': !exists(json, 'auth_token') ? undefined : json['auth_token'],
        'clientInfo': ClientInfoFromJSON(json['client_info']),
    };
}

export function PayRequestToJSON(value?: PayRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'client_id': value.clientId,
        'account': value.account,
        'transaction': PayTransactionToJSON(value.transaction),
        'auth_token': value.authToken,
        'client_info': ClientInfoToJSON(value.clientInfo),
    };
}

