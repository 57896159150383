/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Airport
 */
export interface Airport {
    /**
     * 
     * @type {string}
     * @memberof Airport
     */
    value?: string;
    /**
     * 
     * @type {string}
     * @memberof Airport
     */
    externalId?: string;
    /**
     * Country code based on ISO 3166-1 alpha-2 standard:
  * US - usa
  * CN - china
  * JP - japan
  * DE - germany
  * GB - uk
  * IN - india
  * FR - france
  * IT - italy
  * CA - canada

Full list: https://www.iso.org/obp/ui

     * @type {string}
     * @memberof Airport
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof Airport
     */
    timeZone?: string;
    /**
     * 
     * @type {string}
     * @memberof Airport
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Airport
     */
    typeId?: string;
}

export function AirportFromJSON(json: any): Airport {
    return AirportFromJSONTyped(json, false);
}

export function AirportFromJSONTyped(json: any, ignoreDiscriminator: boolean): Airport {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': !exists(json, 'value') ? undefined : json['value'],
        'externalId': !exists(json, 'external_id') ? undefined : json['external_id'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'timeZone': !exists(json, 'time_zone') ? undefined : json['time_zone'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'typeId': !exists(json, 'type_id') ? undefined : json['type_id'],
    };
}

export function AirportToJSON(value?: Airport | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': value.value,
        'external_id': value.externalId,
        'country': value.country,
        'time_zone': value.timeZone,
        'name': value.name,
        'type_id': value.typeId,
    };
}

