import { CPDOptions } from "../../types/cpd-init";
import { Address } from "../generated-sources/openapi/models/Address";
import { getCardType } from "./get-card-type";
import { CPFields } from "./get-fields";

export interface CardDetails {
	cardHolderName: string;
	cardNumber: string;
	cardCVV: string;
	cardExpirationDate: string;
	cardTypeId: number;
	address?: Address;
}

export class CardValidationError extends Error {

}

export function validateCardDetails(fields: CPFields, options: CPDOptions): void {
	if (fields.cardName === '') {
		throw (new CardValidationError('missing name'));
	}

	if (fields.cardNumber === '') {
		throw (new CardValidationError('missing cardNumber'));
	}

	if (fields.cardCVV === '') {
		throw (new CardValidationError('missing cardCVV'));
	}

	if (fields.cardExpirationDate === '') {
		throw (new CardValidationError('missing cardExpirationDate'));
	}

	const cardType = getCardType(fields.cardNumber, options.cardsMeta);
    if (!cardType) {
		throw (new CardValidationError('This card type is not supported'));
    }

}
