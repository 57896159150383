/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Carrier,
    CarrierFromJSON,
    CarrierFromJSONTyped,
    CarrierToJSON,
} from './Carrier';

/**
 * 
 * @export
 * @interface TransportationCarriers
 */
export interface TransportationCarriers {
    /**
     * 
     * @type {Array<Carrier>}
     * @memberof TransportationCarriers
     */
    carrier?: Array<Carrier>;
}

export function TransportationCarriersFromJSON(json: any): TransportationCarriers {
    return TransportationCarriersFromJSONTyped(json, false);
}

export function TransportationCarriersFromJSONTyped(json: any, ignoreDiscriminator: boolean): TransportationCarriers {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'carrier': !exists(json, 'carrier') ? undefined : ((json['carrier'] as Array<any>).map(CarrierFromJSON)),
    };
}

export function TransportationCarriersToJSON(value?: TransportationCarriers | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'carrier': value.carrier === undefined ? undefined : ((value.carrier as Array<any>).map(CarrierToJSON)),
    };
}

