/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ClientInfo,
    ClientInfoFromJSON,
    ClientInfoFromJSONTyped,
    ClientInfoToJSON,
} from './ClientInfo';
import { GetStatusRequestTransaction } from './GetStatusRequestTransaction';
import {
    GetStatusRequestTransactions,
    GetStatusRequestTransactionsFromJSON,
    GetStatusRequestTransactionsFromJSONTyped,
    GetStatusRequestTransactionsToJSON,
} from './GetStatusRequestTransactions';

/**
 * 
 * @export
 * @interface GetStatusRequest
 */
export interface GetStatusRequest {
    /**
     * The unique ID configured for a merchant in Velocity.
     * @type {number}
     * @memberof GetStatusRequest
     */
    clientId?: number;
    /**
     * The number for a sub-account with which a payment transaction is associated.
The account ID is an integer greater than 100000 and account number is an integer smaller than 1000.

The payment transaction is associated with the default sub-account if merchants do not provide this parameter.

     * @type {number}
     * @memberof GetStatusRequest
     */
    account?: number;
    /**
     * 
     * @type {GetStatusRequestTransactions}
     * @memberof GetStatusRequest
     */
    transactions?: Array<GetStatusRequestTransaction>;
    /**
     * The ID of a session used for multiple payment transactions during one payment flow.
     * @type {number}
     * @memberof GetStatusRequest
     */
    sessionId?: number;
    /**
     * 
     * @type {ClientInfo}
     * @memberof GetStatusRequest
     */
    clientInfo?: ClientInfo;
}

export function GetStatusRequestFromJSON(json: any): GetStatusRequest {
    return GetStatusRequestFromJSONTyped(json, false);
}

export function GetStatusRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetStatusRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'clientId': !exists(json, 'client_id') ? undefined : json['client_id'],
        'account': !exists(json, 'account') ? undefined : json['account'],
        'transactions': !exists(json, 'transactions') ? undefined : GetStatusRequestTransactionsFromJSON(json['transactions']),
        'sessionId': !exists(json, 'session_id') ? undefined : json['session_id'],
        'clientInfo': !exists(json, 'client_info') ? undefined : ClientInfoFromJSON(json['client_info']),
    };
}

export function GetStatusRequestToJSON(value?: GetStatusRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'client_id': value.clientId,
        'account': value.account,
        'transactions': value.transactions,
        'session_id': value.sessionId,
        'client_info': ClientInfoToJSON(value.clientInfo),
    };
}

