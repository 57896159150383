/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PspInfoUrl
 */
export interface PspInfoUrl {
    /**
     * Datatype that defines a URL.
     * @type {string}
     * @memberof PspInfoUrl
     */
    value?: string;
    /**
     * The content type used for the request to the PSP.
     * @type {string}
     * @memberof PspInfoUrl
     */
    contentType: string;
    /**
     * 
     * @type {string}
     * @memberof PspInfoUrl
     */
    typeId?: string;
    /**
     * The method used for submitting the authorization request.
<table>
  <thead>
    <tr>
      <th>Method</th>
      <th>Description</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>App</td>
      <td>Used for payment with thirdparty wallet on device</td>
    </tr>
    <tr>
      <td>GET</td>
      <td>Using HTTP GET</td>
    </tr>
    <tr>
      <td>POST</td>
      <td>Using HTTP POST</td>
    </tr>
  </tbody>
</table>

     * @type {string}
     * @memberof PspInfoUrl
     */
    method: string;
}

export function PspInfoUrlFromJSON(json: any): PspInfoUrl {
    return PspInfoUrlFromJSONTyped(json, false);
}

export function PspInfoUrlFromJSONTyped(json: any, ignoreDiscriminator: boolean): PspInfoUrl {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': !exists(json, 'value') ? undefined : json['value'],
        'contentType': json['content_type'],
        'typeId': !exists(json, 'type_id') ? undefined : json['type_id'],
        'method': json['method'],
    };
}

export function PspInfoUrlToJSON(value?: PspInfoUrl | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': value.value,
        'content_type': value.contentType,
        'type_id': value.typeId,
        'method': value.method,
    };
}

