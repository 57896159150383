/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Amount,
    AmountFromJSON,
    AmountFromJSONTyped,
    AmountToJSON,
} from './Amount';

/**
 * 
 * @export
 * @interface PayTransactionCard
 */
export interface PayTransactionCard {
    /**
     * The type of card scheme, such as Master Card, VISA, and so on.
> **Note** This type ID is returned in the \"id\" field of the cards returned in the /initialize API response.

     * @type {number}
     * @memberof PayTransactionCard
     */
    typeId: number;
    /**
     * 
     * @type {Amount}
     * @memberof PayTransactionCard
     */
    amount: Amount;
    /**
     * The first six digits of a card number which can be used for fraud screening.
     * @type {number}
     * @memberof PayTransactionCard
     */
    issuerIdentificationNumber?: number;
    /**
     * Country code based on ISO 3166-1 alpha-2 standard:
  * US - usa
  * CN - china
  * JP - japan
  * DE - germany
  * GB - uk
  * IN - india
  * FR - france
  * IT - italy
  * CA - canada

Full list: https://www.iso.org/obp/ui

     * @type {string}
     * @memberof PayTransactionCard
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof PayTransactionCard
     */
    applePayToken?: string;
    /**
     * 
     * @type {string}
     * @memberof PayTransactionCard
     */
    visaKey?: string;
    /**
     * 
     * @type {string}
     * @memberof PayTransactionCard
     */
    visaData?: string;
    /**
     * 
     * @type {string}
     * @memberof PayTransactionCard
     */
    token?: string;
}

export function PayTransactionCardFromJSON(json: any): PayTransactionCard {
    return PayTransactionCardFromJSONTyped(json, false);
}

export function PayTransactionCardFromJSONTyped(json: any, ignoreDiscriminator: boolean): PayTransactionCard {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'typeId': json['type_id'],
        'amount': AmountFromJSON(json['amount']),
        'issuerIdentificationNumber': !exists(json, 'issuer_identification_number') ? undefined : json['issuer_identification_number'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'applePayToken': !exists(json, 'apple_pay_token') ? undefined : json['apple_pay_token'],
        'visaKey': !exists(json, 'visa_key') ? undefined : json['visa_key'],
        'visaData': !exists(json, 'visa_data') ? undefined : json['visa_data'],
        'token': !exists(json, 'token') ? undefined : json['token'],
    };
}

export function PayTransactionCardToJSON(value?: PayTransactionCard | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type_id': value.typeId,
        'amount': AmountToJSON(value.amount),
        'issuer_identification_number': value.issuerIdentificationNumber,
        'country': value.country,
        'apple_pay_token': value.applePayToken,
        'visa_key': value.visaKey,
        'visa_data': value.visaData,
        'token': value.token,
    };
}

