/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ClientConfig,
    ClientConfigFromJSON,
    ClientConfigFromJSONTyped,
    ClientConfigToJSON,
} from './ClientConfig';
import {
    InitializeResponseAccount,
    InitializeResponseAccountFromJSON,
    InitializeResponseAccountFromJSONTyped,
    InitializeResponseAccountToJSON,
} from './InitializeResponseAccount';
import {
    InitializeResponseCards,
    InitializeResponseCardsFromJSON,
    InitializeResponseCardsFromJSONTyped,
    InitializeResponseCardsToJSON,
} from './InitializeResponseCards';
import {
    InitializeResponseStoredCards,
    InitializeResponseStoredCardsFromJSON,
    InitializeResponseStoredCardsFromJSONTyped,
    InitializeResponseStoredCardsToJSON,
} from './InitializeResponseStoredCards';
import {
    Session,
    SessionFromJSON,
    SessionFromJSONTyped,
    SessionToJSON,
} from './Session';
import {
    SplitPayment,
    SplitPaymentFromJSON,
    SplitPaymentFromJSONTyped,
    SplitPaymentToJSON,
} from './SplitPayment';
import {
    Status,
    StatusFromJSON,
    StatusFromJSONTyped,
    StatusToJSON,
} from './Status';
import {
    Transaction,
    TransactionFromJSON,
    TransactionFromJSONTyped,
    TransactionToJSON,
} from './Transaction';

/**
 * 
 * @export
 * @interface InitializeResponse
 */
export interface InitializeResponse {
    /**
     * <table>
  <thead>
    <tr>
      <th>Status Code</th>
      <th>Transaction State</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>1</td>
      <td>Undefined Client ID</td>
    </tr>
    <tr>
      <td>2</td>
      <td>Invalid Client ID</td>
    </tr>
    <tr>
      <td>3</td>
      <td>Unknown Client ID</td>
    </tr>
    <tr>
      <td>4</td>
      <td>Client Disabled</td>
    </tr>
    <tr>
      <td>11</td>
      <td>Undefined Account</td>
    </tr>
    <tr>
      <td>12</td>
      <td>Invalid Account</td>
    </tr>
    <tr>
      <td>13</td>
      <td>Unknown Account</td>
    </tr>
    <tr>
      <td>14</td>
      <td>Account Disabled</td>
    </tr>
    <tr>
      <td>51</td>
      <td>Undefined Amount</td>
    </tr>
    <tr>
      <td>52</td>
      <td>Amount is too small; amount must be greater than 1 (0,01 of the country's currency)</td>
    </tr>
    <tr>
      <td>53</td>
      <td>Amount is too great; amount must be smaller than the max amount specified by the client</td>
    </tr>
    <tr>
      <td>200</td>
      <td>Request validated and accepted by Velocity</td>
    </tr>
    <tr>
      <td>400</td>
      <td>Request has been rejected by Velocity due to a validation error</td>
    </tr>
    <tr>
      <td>401</td>
      <td>Authentication required</td>
    </tr>
    <tr>
      <td>403</td>
      <td>Access denied</td>
    </tr>
    <tr>
      <td>415</td>
      <td>Message was transmitted in an unknown format or using an unsupported Content Type</td>
    </tr>
    <tr>
      <td>500</td>
      <td>Velocity server error</td>
    </tr>
    <tr>
      <td>502</td>
      <td>Payment Service Provider returned an unknown error</td>
    </tr>
    <tr>
      <td>504</td>
      <td>A timeout occurred while communicating with the PSP</td>
    </tr>
    <tr>
      <td>1001</td>
      <td>Internal database error - Unable to generate new Transaction ID. Contact support@cellpointdigital.com</td>
    </tr>
    <tr>
      <td>1002</td>
      <td>Internal database error - Unable to create a new Transaction. Contact support@cellpointdigital.com</td>
    </tr>
    <tr>
      <td>1003</td>
      <td>Internal Database Error - Unable to insert new message for Transaction. Contact support@cellpointdigital.com</td>
    </tr>
    <tr>
      <td>1004</td>
      <td>Internal database error - Unable to update Transaction. Contact support@cellpointdigital.com</td>
    </tr>
    <tr>
      <td>2002</td>
      <td>Payment refunded</td>
    </tr>
    <tr>
      <td>2003</td>
      <td>Payment cancelled</td>
    </tr>
    <tr>
      <td>2009</td>
      <td>Card stored</td>
    </tr>
    <tr>
      <td>2201</td>
      <td>Partial capture</td>
    </tr>
    <tr>
      <td>2202</td>
      <td>Partial cancel</td>
    </tr>
    <tr>
      <td>2203</td>
      <td>Partial refund</td>
    </tr>
    <tr>
      <td>2084</td>
      <td>Card not found</td>
    </tr>
    <tr>
      <td>20109</td>
      <td>Payment timeout</td>
    </tr>
  </tbody>
</table>

     * @type {Array<Status>}
     * @memberof InitializeResponse
     */
    status?: Array<Status>;
    /**
     * 
     * @type {ClientConfig}
     * @memberof InitializeResponse
     */
    clientConfig?: ClientConfig;
    /**
     * 
     * @type {SplitPayment}
     * @memberof InitializeResponse
     */
    splitPayment?: SplitPayment;
    /**
     * 
     * @type {Transaction}
     * @memberof InitializeResponse
     */
    transaction?: Transaction;
    /**
     * 
     * @type {Session}
     * @memberof InitializeResponse
     */
    session?: Session;
    /**
     * 
     * @type {InitializeResponseCards}
     * @memberof InitializeResponse
     */
    cards?: InitializeResponseCards;
    /**
     * 
     * @type {InitializeResponseStoredCards}
     * @memberof InitializeResponse
     */
    storedCards?: InitializeResponseStoredCards;
    /**
     * 
     * @type {InitializeResponseCards}
     * @memberof InitializeResponse
     */
    wallets?: InitializeResponseCards;
    /**
     * 
     * @type {InitializeResponseCards}
     * @memberof InitializeResponse
     */
    apms?: InitializeResponseCards;
    /**
     * 
     * @type {InitializeResponseCards}
     * @memberof InitializeResponse
     */
    aggregators?: InitializeResponseCards;
    /**
     * 
     * @type {InitializeResponseCards}
     * @memberof InitializeResponse
     */
    offline?: InitializeResponseCards;
    /**
     * 
     * @type {InitializeResponseCards}
     * @memberof InitializeResponse
     */
    vouchers?: InitializeResponseCards;
    /**
     * 
     * @type {InitializeResponseAccount}
     * @memberof InitializeResponse
     */
    account?: InitializeResponseAccount;
}

export function InitializeResponseFromJSON(json: any): InitializeResponse {
    return InitializeResponseFromJSONTyped(json, false);
}

export function InitializeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): InitializeResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': !exists(json, 'status') ? undefined : ((json['status'] as Array<any>).map(StatusFromJSON)),
        'clientConfig': !exists(json, 'client_config') ? undefined : ClientConfigFromJSON(json['client_config']),
        'splitPayment': !exists(json, 'split_payment') ? undefined : SplitPaymentFromJSON(json['split_payment']),
        'transaction': !exists(json, 'transaction') ? undefined : TransactionFromJSON(json['transaction']),
        'session': !exists(json, 'session') ? undefined : SessionFromJSON(json['session']),
        'cards': !exists(json, 'cards') ? undefined : InitializeResponseCardsFromJSON(json['cards']),
        'storedCards': !exists(json, 'stored_cards') ? undefined : InitializeResponseStoredCardsFromJSON(json['stored_cards']),
        'wallets': !exists(json, 'wallets') ? undefined : InitializeResponseCardsFromJSON(json['wallets']),
        'apms': !exists(json, 'apms') ? undefined : InitializeResponseCardsFromJSON(json['apms']),
        'aggregators': !exists(json, 'aggregators') ? undefined : InitializeResponseCardsFromJSON(json['aggregators']),
        'offline': !exists(json, 'offline') ? undefined : InitializeResponseCardsFromJSON(json['offline']),
        'vouchers': !exists(json, 'vouchers') ? undefined : InitializeResponseCardsFromJSON(json['vouchers']),
        'account': !exists(json, 'account') ? undefined : InitializeResponseAccountFromJSON(json['account']),
    };
}

export function InitializeResponseToJSON(value?: InitializeResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status === undefined ? undefined : ((value.status as Array<any>).map(StatusToJSON)),
        'client_config': ClientConfigToJSON(value.clientConfig),
        'split_payment': SplitPaymentToJSON(value.splitPayment),
        'transaction': TransactionToJSON(value.transaction),
        'session': SessionToJSON(value.session),
        'cards': InitializeResponseCardsToJSON(value.cards),
        'stored_cards': InitializeResponseStoredCardsToJSON(value.storedCards),
        'wallets': InitializeResponseCardsToJSON(value.wallets),
        'apms': InitializeResponseCardsToJSON(value.apms),
        'aggregators': InitializeResponseCardsToJSON(value.aggregators),
        'offline': InitializeResponseCardsToJSON(value.offline),
        'vouchers': InitializeResponseCardsToJSON(value.vouchers),
        'account': InitializeResponseAccountToJSON(value.account),
    };
}

