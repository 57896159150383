/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AdditionalData,
    AdditionalDataFromJSON,
    AdditionalDataFromJSONTyped,
    AdditionalDataToJSON,
} from './AdditionalData';
import {
    Address,
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';
import {
    AirlineDataFees,
    AirlineDataFeesFromJSON,
    AirlineDataFeesFromJSONTyped,
    AirlineDataFeesToJSON,
} from './AirlineDataFees';
import {
    Amount,
    AmountFromJSON,
    AmountFromJSONTyped,
    AmountToJSON,
} from './Amount';
import {
    Card,
    CardFromJSON,
    CardFromJSONTyped,
    CardToJSON,
} from './Card';
import {
    CardInstallment,
    CardInstallmentFromJSON,
    CardInstallmentFromJSONTyped,
    CardInstallmentToJSON,
} from './CardInstallment';
import {
    ForeignExchangeInfo,
    ForeignExchangeInfoFromJSON,
    ForeignExchangeInfoFromJSONTyped,
    ForeignExchangeInfoToJSON,
} from './ForeignExchangeInfo';
import {
    InitializeTransactionCustomVariables,
    InitializeTransactionCustomVariablesFromJSON,
    InitializeTransactionCustomVariablesFromJSONTyped,
    InitializeTransactionCustomVariablesToJSON,
} from './InitializeTransactionCustomVariables';
import {
    Order,
    OrderFromJSON,
    OrderFromJSONTyped,
    OrderToJSON,
} from './Order';
import {
    Reward,
    RewardFromJSON,
    RewardFromJSONTyped,
    RewardToJSON,
} from './Reward';
import {
    Voucher,
    VoucherFromJSON,
    VoucherFromJSONTyped,
    VoucherToJSON,
} from './Voucher';

/**
 * 
 * @export
 * @interface InitializeTransaction
 */
export interface InitializeTransaction {
    /**
     * The Unique Velocity Transaction ID.
     * @type {number}
     * @memberof InitializeTransaction
     */
    id?: number;
    /**
     * The order ID that a merchant generates. 
It is an alphanumeric string.

> **Note**: Some Payment Providers have strict guidelines for the order-no. The recommended pattern is "[a-zA-Z0-9._-]

     * @type {string}
     * @memberof InitializeTransaction
     */
    orderNo?: string;
    /**
     * The type of transaction used for making payment. 
Velocity supports the following transaction types.

<table>
  <thead>
    <tr>
      <th>Value</th>
      <th>Transaction Types</th>
      <th>Examples</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>1</td>
      <td>Online shopping</td>
      <td>S&B Purchase</td>
    </tr>
    <tr>
      <td>2</td>
      <td>Offline shopping</td>
      <td>S&B Purchase</td>
    </tr>
    <tr>
      <td>3</td>
      <td>Self Service Online</td>
      <td>MYB Ancillary Purchase, MYB Change Itinerary Payment, OLCI Ancillary Purchase</td>
    </tr>
    <tr>
      <td>4</td>
      <td>Self Service Offline</td>
      <td>MYB Pay Late</td>
    </tr>
  </tbody>
</table>

     * @type {number}
     * @memberof InitializeTransaction
     */
    typeId?: number;
    /**
     * The ID of a session used for multiple payment transactions during one payment flow, for example, split payment or retry of a failed payment transaction
     * @type {number}
     * @memberof InitializeTransaction
     */
    sessionId?: number;
    /**
     * The product of a merchant; for example, ticket, insurance, and so on.
     * @type {number}
     * @memberof InitializeTransaction
     */
    productType?: number;
    /**
     * The retail booking reference that could be used for tracing the transaction in the retail system.
This field is provided over the order-no, if order-no does act as the key param.
It is the PNR number for airlines.

     * @type {string}
     * @memberof InitializeTransaction
     */
    bookingRef?: string;
    /**
     * The total amount that a customer is charged for a payment transaction in a country’s smallest currency. For example, the smallest currency of USD is penny. If the transaction amount is $120.30, then the amount field contains value 12030.

> **Note**: The amount is always a non-decimal value in the request.

     * @type {Amount}
     * @memberof InitializeTransaction
     */
    amount: Amount | null;
    /**
     * 
     * @type {AirlineDataFees}
     * @memberof InitializeTransaction
     */
    fees?: AirlineDataFees;
    /**
     * 
     * @type {number}
     * @memberof InitializeTransaction
     */
    points?: number;
    /**
     * 
     * @type {Reward}
     * @memberof InitializeTransaction
     */
    reward?: Reward;
    /**
     * The absolute URL where Velocity directs customers if they cancel a payment transaction midway.

> **Note**: If you do not provide this parameter, Velocity uses the default URL. The URL is used for redirecting back to either HPP or Merchant’s page after payment cancellation.

     * @type {string}
     * @memberof InitializeTransaction
     */
    cancelUrl?: string | null;
    /**
     * The absolute URL to the back office of a merchant where Velocity POP sends the payment status.

> **Note**: If you do not provide this parameter, Velocity uses the default URL.

     * @type {string}
     * @memberof InitializeTransaction
     */
    callbackUrl?: string | null;
    /**
     * The URL where Velocity directs a customer after successfully completing a payment transaction.

> **Note**: If you do not provide this parameter, Velocity uses the default URL. The URL is used for redirecting back to either HPP or Merchant’s page after payment completion.

     * @type {string}
     * @memberof InitializeTransaction
     */
    acceptUrl?: string | null;
    /**
     * The absolute URL where Velocity directs customers if the payment transaction fails. The URL is used for redirecting back to either HPP or Merchant’s page after payment failure.
     * @type {string}
     * @memberof InitializeTransaction
     */
    declineUrl?: string | null;
    /**
     * The URL which used for SSO authentication if provided by external system.
     * @type {string}
     * @memberof InitializeTransaction
     */
    authUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InitializeTransaction
     */
    description?: string;
    /**
     * 
     * @type {InitializeTransactionCustomVariables}
     * @memberof InitializeTransaction
     */
    customVariables?: InitializeTransactionCustomVariables;
    /**
     * The Message Authentication Code. 
It is calculated by creating a sha-512 hash. 
The hash is comprised of the following input fields in the listed order:

- clientid 
- account 
- orderid 
- callback-url 
- amount 
- auth-url 
- customer-ref 
- auth-token 
- email
- mobile
- [salt]

> **Note**: CPD provides the salt value to merchants.

MAC calculation secures the information sent by the merchant to Velocity by applying the SHA-512 encryption algorithm on key parts of the information sent to protect against tampering. 
The ‘salt’ is the merchant's shared secret string used to ensure that MAC is unique.

     * @type {string}
     * @memberof InitializeTransaction
     */
    hmac?: string;
    /**
     * 
     * @type {ForeignExchangeInfo}
     * @memberof InitializeTransaction
     */
    foreignExchangeInfo?: ForeignExchangeInfo;
    /**
     * 
     * @type {AdditionalData}
     * @memberof InitializeTransaction
     */
    additionalData?: AdditionalData;
    /**
     * 
     * @type {Voucher}
     * @memberof InitializeTransaction
     */
    voucher?: Voucher;
    /**
     * 
     * @type {Card}
     * @memberof InitializeTransaction
     */
    card?: Card;
    /**
     * 
     * @type {Order}
     * @memberof InitializeTransaction
     */
    orders?: Order;
    /**
     * 
     * @type {CardInstallment}
     * @memberof InitializeTransaction
     */
    installment?: CardInstallment;
    /**
     * 
     * @type {Address}
     * @memberof InitializeTransaction
     */
    billingAddress?: Address;
    /**
     * Shows if you have stored card.
     * @type {boolean}
     * @memberof InitializeTransaction
     */
    storeCard?: boolean;
}

export function InitializeTransactionFromJSON(json: any): InitializeTransaction {
    return InitializeTransactionFromJSONTyped(json, false);
}

export function InitializeTransactionFromJSONTyped(json: any, ignoreDiscriminator: boolean): InitializeTransaction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'orderNo': !exists(json, 'order_no') ? undefined : json['order_no'],
        'typeId': !exists(json, 'type_id') ? undefined : json['type_id'],
        'sessionId': !exists(json, 'session_id') ? undefined : json['session_id'],
        'productType': !exists(json, 'product_type') ? undefined : json['product_type'],
        'bookingRef': !exists(json, 'booking_ref') ? undefined : json['booking_ref'],
        'amount': AmountFromJSON(json['amount']),
        'fees': !exists(json, 'fees') ? undefined : AirlineDataFeesFromJSON(json['fees']),
        'points': !exists(json, 'points') ? undefined : json['points'],
        'reward': !exists(json, 'reward') ? undefined : RewardFromJSON(json['reward']),
        'cancelUrl': !exists(json, 'cancel_url') ? undefined : json['cancel_url'],
        'callbackUrl': !exists(json, 'callback_url') ? undefined : json['callback_url'],
        'acceptUrl': !exists(json, 'accept_url') ? undefined : json['accept_url'],
        'declineUrl': !exists(json, 'decline_url') ? undefined : json['decline_url'],
        'authUrl': !exists(json, 'auth_url') ? undefined : json['auth_url'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'customVariables': !exists(json, 'custom_variables') ? undefined : InitializeTransactionCustomVariablesFromJSON(json['custom_variables']),
        'hmac': !exists(json, 'hmac') ? undefined : json['hmac'],
        'foreignExchangeInfo': !exists(json, 'foreign_exchange_info') ? undefined : ForeignExchangeInfoFromJSON(json['foreign_exchange_info']),
        'additionalData': !exists(json, 'additional_data') ? undefined : AdditionalDataFromJSON(json['additional_data']),
        'voucher': !exists(json, 'voucher') ? undefined : VoucherFromJSON(json['voucher']),
        'card': !exists(json, 'card') ? undefined : CardFromJSON(json['card']),
        'orders': !exists(json, 'orders') ? undefined : OrderFromJSON(json['orders']),
        'installment': !exists(json, 'installment') ? undefined : CardInstallmentFromJSON(json['installment']),
        'billingAddress': !exists(json, 'billing_address') ? undefined : AddressFromJSON(json['billing_address']),
        'storeCard': !exists(json, 'store_card') ? undefined : json['store_card'],
    };
}

export function InitializeTransactionToJSON(value?: InitializeTransaction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'order_no': value.orderNo,
        'type_id': value.typeId,
        'session_id': value.sessionId,
        'product_type': value.productType,
        'booking_ref': value.bookingRef,
        'amount': AmountToJSON(value.amount),
        'fees': AirlineDataFeesToJSON(value.fees),
        'points': value.points,
        'reward': RewardToJSON(value.reward),
        'cancel_url': value.cancelUrl,
        'callback_url': value.callbackUrl,
        'accept_url': value.acceptUrl,
        'decline_url': value.declineUrl,
        'auth_url': value.authUrl,
        'description': value.description,
        'custom_variables': InitializeTransactionCustomVariablesToJSON(value.customVariables),
        'hmac': value.hmac,
        'foreign_exchange_info': ForeignExchangeInfoToJSON(value.foreignExchangeInfo),
        'additional_data': AdditionalDataToJSON(value.additionalData),
        'voucher': VoucherToJSON(value.voucher),
        'card': CardToJSON(value.card),
        'orders': OrderToJSON(value.orders),
        'installment': CardInstallmentToJSON(value.installment),
        'billing_address': AddressToJSON(value.billingAddress),
        'store_card': value.storeCard,
    };
}

