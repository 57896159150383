export function getSiblingFrames(): Window[] {
    const res = [];

    for (let index = 0; index < window.parent.length; index++) {
        // ignore frames that are not same origin & does not contain an input
        try {
            window.parent[index].document;
        } catch {
            continue;
        }

        res.push(window.parent[index]);
    }

    return res;
}
