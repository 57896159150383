/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Format,
    FormatFromJSON,
    FormatFromJSONTyped,
    FormatToJSON,
} from './Format';

/**
 * 
 * @export
 * @interface InitializeResponseAmount
 */
export interface InitializeResponseAmount {
    /**
     * 
     * @type {number}
     * @memberof InitializeResponseAmount
     */
    value?: number;
    /**
     * Country code based on ISO 3166-1 alpha-2 standard:
  * US - usa
  * CN - china
  * JP - japan
  * DE - germany
  * GB - uk
  * IN - india
  * FR - france
  * IT - italy
  * CA - canada

Full list: https://www.iso.org/obp/ui

     * @type {string}
     * @memberof InitializeResponseAmount
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof InitializeResponseAmount
     */
    currency?: string;
    /**
     * 
     * @type {Format}
     * @memberof InitializeResponseAmount
     */
    format?: Format;
    /**
     * The symbol that is used to represent the currency (kr. $, € etc.).
     * @type {string}
     * @memberof InitializeResponseAmount
     */
    symbol?: string;
}

export function InitializeResponseAmountFromJSON(json: any): InitializeResponseAmount {
    return InitializeResponseAmountFromJSONTyped(json, false);
}

export function InitializeResponseAmountFromJSONTyped(json: any, ignoreDiscriminator: boolean): InitializeResponseAmount {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': !exists(json, 'value') ? undefined : json['value'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'currency': !exists(json, 'currency') ? undefined : json['currency'],
        'format': !exists(json, 'format') ? undefined : FormatFromJSON(json['format']),
        'symbol': !exists(json, 'symbol') ? undefined : json['symbol'],
    };
}

export function InitializeResponseAmountToJSON(value?: InitializeResponseAmount | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': value.value,
        'country': value.country,
        'currency': value.currency,
        'format': FormatToJSON(value.format),
        'symbol': value.symbol,
    };
}

