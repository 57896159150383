/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AdditionalData,
    AdditionalDataFromJSON,
    AdditionalDataFromJSONTyped,
    AdditionalDataToJSON,
} from './AdditionalData';
import {
    Amount,
    AmountFromJSON,
    AmountFromJSONTyped,
    AmountToJSON,
} from './Amount';
import {
    LineItemFees,
    LineItemFeesFromJSON,
    LineItemFeesFromJSONTyped,
    LineItemFeesToJSON,
} from './LineItemFees';
import {
    Product,
    ProductFromJSON,
    ProductFromJSONTyped,
    ProductToJSON,
} from './Product';

/**
 * The list products added to the cart, to be purchased by the end-user.
 * @export
 * @interface LineItem
 */
export interface LineItem {
    /**
     * 
     * @type {Product}
     * @memberof LineItem
     */
    product: Product;
    /**
     * 
     * @type {Amount}
     * @memberof LineItem
     */
    amount?: Amount;
    /**
     * 
     * @type {LineItemFees}
     * @memberof LineItem
     */
    fees?: LineItemFees;
    /**
     * Total number of points the customer will pay for the line item in the cart.
     * @type {number}
     * @memberof LineItem
     */
    points?: number;
    /**
     * Total number of points the customer will be rewarded for completing the line item in the cart.
     * @type {number}
     * @memberof LineItem
     */
    reward?: number;
    /**
     * Used to indicate the count of the line item purchased.
     * @type {number}
     * @memberof LineItem
     */
    quantity?: number;
    /**
     * 
     * @type {AdditionalData}
     * @memberof LineItem
     */
    additionalData?: AdditionalData;
}

export function LineItemFromJSON(json: any): LineItem {
    return LineItemFromJSONTyped(json, false);
}

export function LineItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): LineItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'product': ProductFromJSON(json['product']),
        'amount': !exists(json, 'amount') ? undefined : AmountFromJSON(json['amount']),
        'fees': !exists(json, 'fees') ? undefined : LineItemFeesFromJSON(json['fees']),
        'points': !exists(json, 'points') ? undefined : json['points'],
        'reward': !exists(json, 'reward') ? undefined : json['reward'],
        'quantity': !exists(json, 'quantity') ? undefined : json['quantity'],
        'additionalData': !exists(json, 'additional_data') ? undefined : AdditionalDataFromJSON(json['additional_data']),
    };
}

export function LineItemToJSON(value?: LineItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'product': ProductToJSON(value.product),
        'amount': AmountToJSON(value.amount),
        'fees': LineItemFeesToJSON(value.fees),
        'points': value.points,
        'reward': value.reward,
        'quantity': value.quantity,
        'additional_data': AdditionalDataToJSON(value.additionalData),
    };
}

