import { CreditCardField } from "../../types/cpd-init";
import { Address } from "../generated-sources/openapi/models/Address";
import { getFieldValue, getFieldValueUndefined } from "./get-field-value";

export interface CPFields {
	cardName: string;
	cardNumber: string;
	cardCVV: string;
	cardExpirationDate: string;

    address?: Address;
}

export function getFields(): CPFields {
	const fields ={
		// Card related
		cardName: getFieldValue(CreditCardField.name.toString()),
		cardNumber: getFieldValue(CreditCardField.number.toString()),
		cardCVV: getFieldValue(CreditCardField.cvv.toString()),
		cardExpirationDate: getFieldValue(CreditCardField.expirationDate.toString()),

		// address related
		address: {
			country: getFieldValueUndefined(CreditCardField.address.country.toString()),
			firstName: getFieldValueUndefined(CreditCardField.address.firstName.toString()),
			lastName: getFieldValueUndefined(CreditCardField.address.lastName.toString()),
			street: getFieldValueUndefined(CreditCardField.address.street.toString()),
			postalCode: getFieldValueUndefined(CreditCardField.address.postalCode.toString()),
			city: getFieldValueUndefined(CreditCardField.address.city.toString()),
			state: getFieldValueUndefined(CreditCardField.address.state.toString()),
		}
	};

    // Remove undefined props from field.address object
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Object.keys(fields.address).forEach(key => {
		if(fields.address[key] === undefined || fields.address[key] === '') {
			delete fields.address[key];
		}
	});

	return fields;
}
