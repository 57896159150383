import { CPInputStyles } from "../frame/controls/input";
import { Address } from "../frame/generated-sources/openapi/models/Address";
import { LanguageCode } from "../frame/generated-sources/openapi/models/LanguageCode";

export const CreditCardField = {
    name: 'name',
    number: 'number',
    cvv: 'cvv',
    expirationDate: 'expiration_date',
    submit: 'submit',

    address: {
        country: "country",
        firstName: "first_name",
        lastName: "last_name",
        street: "street",
        postalCode: "postal_code",
        city: "city",
        state: "states"
    }
}

export interface CardMeta {
    id: number;
    type_id: number; // usually same as id..
    name: string;
    payment_type: number;

    cvc_length: number;
    cvc_mandatory: boolean,
    dcc: boolean,
    enabled: boolean,

    installment: number;
    min_length: number;
    max_length: number;
    preferred: boolean;
    presentment_currency: boolean;
    processor_type: number;
    psp_id: number;
    state_id: number;

    prefixes: {
        id: number;
        min: number;
        max: number;
    }[]
}

export interface CPDOptions {
    txRef?: string,
    authToken?: string,
    field?: string;
    label?: string;
    styles?: CPInputStyles;
    hostResolver?: string;

    debug?: boolean;
    successRedirect?: string;
    errorRedirect?: string;
    framePath?: string;

    billingAddress?: Address;
    cardAddress?: Address;

    // These should' be saved on the transaction server-side
    // Due to the stateless API, they are currently required for the API methods
    meta: {
        clientId: number,
        account: number,
        language: LanguageCode,
        platform?: string;
        version?: string;
    },

    amount: {
        country: LanguageCode;
        currency: string;
        value: number;
    }

    cardsMeta: CardMeta[];

    // Credit card args
    cardNumberElm?: HTMLElement,
    cardCVVElm?: HTMLElement,
    cardExpirationElm?: HTMLElement
}
