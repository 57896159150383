/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Param,
    ParamFromJSON,
    ParamFromJSONTyped,
    ParamToJSON,
} from './Param';

/**
 * Custom data field that could be used for setting non-standard key-value pairs. 
These values are associated with the parent entity wherein they appear, ex, psp-config, client-config, transaction.

 * @export
 * @interface AdditionalData
 */
export interface AdditionalData {
    /**
     * 
     * @type {Array<Param>}
     * @memberof AdditionalData
     */
    param?: Array<Param>;
}

export function AdditionalDataFromJSON(json: any): AdditionalData {
    return AdditionalDataFromJSONTyped(json, false);
}

export function AdditionalDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdditionalData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'param': !exists(json, 'param') ? undefined : ((json['param'] as Array<any>).map(ParamFromJSON)),
    };
}

export function AdditionalDataToJSON(value?: AdditionalData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'param': value.param === undefined ? undefined : ((value.param as Array<any>).map(ParamToJSON)),
    };
}

