/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Complete shipping address of the cart items.
 * @export
 * @interface ShippingAddress
 */
export interface ShippingAddress {
    /**
     * Name in the shipping address.
     * @type {string}
     * @memberof ShippingAddress
     */
    name: string | null;
    /**
     * Street in the shipping address.
     * @type {string}
     * @memberof ShippingAddress
     */
    street: string;
    /**
     * Additional street information in the shipping address.
     * @type {string}
     * @memberof ShippingAddress
     */
    street2: string;
    /**
     * City in the shipping address.
     * @type {string}
     * @memberof ShippingAddress
     */
    city: string;
    /**
     * State in the shipping address.
     * @type {string}
     * @memberof ShippingAddress
     */
    state: string;
    /**
     * Zip Code in the shipping address.
     * @type {string}
     * @memberof ShippingAddress
     */
    zip: string;
    /**
     * Country Name in the shipping address.
     * @type {string}
     * @memberof ShippingAddress
     */
    country: string;
}

export function ShippingAddressFromJSON(json: any): ShippingAddress {
    return ShippingAddressFromJSONTyped(json, false);
}

export function ShippingAddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShippingAddress {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'street': json['street'],
        'street2': json['street2'],
        'city': json['city'],
        'state': json['state'],
        'zip': json['zip'],
        'country': json['country'],
    };
}

export function ShippingAddressToJSON(value?: ShippingAddress | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'street': value.street,
        'street2': value.street2,
        'city': value.city,
        'state': value.state,
        'zip': value.zip,
        'country': value.country,
    };
}

