/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AirlineData,
    AirlineDataFromJSON,
    AirlineDataFromJSONTyped,
    AirlineDataToJSON,
} from './AirlineData';

/**
 * Each cart item is called a product.
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    sku: string;
    /**
     * The name of a line item present in the cart.
     * @type {string}
     * @memberof Product
     */
    name?: string | null;
    /**
     * The Description of a line item present in the cart.
     * @type {string}
     * @memberof Product
     */
    description?: string | null;
    /**
     * The image URL of the line item in the cart.
     * @type {string}
     * @memberof Product
     */
    imageUrl?: string | null;
    /**
     * 
     * @type {AirlineData}
     * @memberof Product
     */
    airlineData?: AirlineData;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    type?: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    orderRef?: string;
}

export function ProductFromJSON(json: any): Product {
    return ProductFromJSONTyped(json, false);
}

export function ProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): Product {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sku': json['sku'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'imageUrl': !exists(json, 'image_url') ? undefined : json['image_url'],
        'airlineData': !exists(json, 'airline_data') ? undefined : AirlineDataFromJSON(json['airline_data']),
        'type': !exists(json, 'type') ? undefined : json['type'],
        'orderRef': !exists(json, 'order_ref') ? undefined : json['order_ref'],
    };
}

export function ProductToJSON(value?: Product | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sku': value.sku,
        'name': value.name,
        'description': value.description,
        'image_url': value.imageUrl,
        'airline_data': AirlineDataToJSON(value.airlineData),
        'type': value.type,
        'order_ref': value.orderRef,
    };
}

