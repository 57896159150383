/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Amount,
    AmountFromJSON,
    AmountFromJSONTyped,
    AmountToJSON,
} from './Amount';

/**
 * 
 * @export
 * @interface InitializeResponseAccount
 */
export interface InitializeResponseAccount {
    /**
     * 
     * @type {number}
     * @memberof InitializeResponseAccount
     */
    id?: number;
    /**
     * 
     * @type {Amount}
     * @memberof InitializeResponseAccount
     */
    balance?: Amount | null;
    /**
     * 
     * @type {Amount}
     * @memberof InitializeResponseAccount
     */
    points?: Amount | null;
}

export function InitializeResponseAccountFromJSON(json: any): InitializeResponseAccount {
    return InitializeResponseAccountFromJSONTyped(json, false);
}

export function InitializeResponseAccountFromJSONTyped(json: any, ignoreDiscriminator: boolean): InitializeResponseAccount {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'balance': !exists(json, 'balance') ? undefined : AmountFromJSON(json['balance']),
        'points': !exists(json, 'points') ? undefined : AmountFromJSON(json['points']),
    };
}

export function InitializeResponseAccountToJSON(value?: InitializeResponseAccount | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'balance': AmountToJSON(value.balance),
        'points': AmountToJSON(value.points),
    };
}

