/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Amount,
    AmountFromJSON,
    AmountFromJSONTyped,
    AmountToJSON,
} from './Amount';

/**
 * 
 * @export
 * @interface Voucher
 */
export interface Voucher {
    /**
     * 
     * @type {Amount}
     * @memberof Voucher
     */
    amount?: Amount;
    /**
     * Voucher number.
     * @type {string}
     * @memberof Voucher
     */
    id?: string;
    /**
     * The order ID that a merchant generates. 
It is an alphanumeric string.

> **Note**: Some Payment Providers have strict guidelines for the order-no. The recommended pattern is "[a-zA-Z0-9._-]

     * @type {string}
     * @memberof Voucher
     */
    orderNo?: string;
}

export function VoucherFromJSON(json: any): Voucher {
    return VoucherFromJSONTyped(json, false);
}

export function VoucherFromJSONTyped(json: any, ignoreDiscriminator: boolean): Voucher {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount': !exists(json, 'amount') ? undefined : AmountFromJSON(json['amount']),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'orderNo': !exists(json, 'orderNo') ? undefined : json['orderNo'],
    };
}

export function VoucherToJSON(value?: Voucher | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount': AmountToJSON(value.amount),
        'id': value.id,
        'orderNo': value.orderNo,
    };
}

