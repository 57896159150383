/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Passenger,
    PassengerFromJSON,
    PassengerFromJSONTyped,
    PassengerToJSON,
} from './Passenger';

/**
 * 
 * @export
 * @interface AirlineDataProfiles
 */
export interface AirlineDataProfiles {
    /**
     * 
     * @type {Array<Passenger>}
     * @memberof AirlineDataProfiles
     */
    profile?: Array<Passenger>;
}

export function AirlineDataProfilesFromJSON(json: any): AirlineDataProfiles {
    return AirlineDataProfilesFromJSONTyped(json, false);
}

export function AirlineDataProfilesFromJSONTyped(json: any, ignoreDiscriminator: boolean): AirlineDataProfiles {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'profile': !exists(json, 'profile') ? undefined : ((json['profile'] as Array<any>).map(PassengerFromJSON)),
    };
}

export function AirlineDataProfilesToJSON(value?: AirlineDataProfiles | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'profile': value.profile === undefined ? undefined : ((value.profile as Array<any>).map(PassengerToJSON)),
    };
}

