/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PaymentSummaryTransactionCardCryptogram,
    PaymentSummaryTransactionCardCryptogramFromJSON,
    PaymentSummaryTransactionCardCryptogramFromJSONTyped,
    PaymentSummaryTransactionCardCryptogramToJSON,
} from './PaymentSummaryTransactionCardCryptogram';

/**
 * 
 * @export
 * @interface PaymentSummaryTransactionCard
 */
export interface PaymentSummaryTransactionCard {
    /**
     * 
     * @type {PaymentSummaryTransactionCardCryptogram}
     * @memberof PaymentSummaryTransactionCard
     */
    cryptogram?: PaymentSummaryTransactionCardCryptogram;
    /**
     * The encrypted alpha-numeric string to represent card information used in third-part wallet.
     * @type {string}
     * @memberof PaymentSummaryTransactionCard
     */
    token?: string;
    /**
     * The OAuth verifier sent to the user application along with the access token. Used only for Amex checkout integration.
     * @type {string}
     * @memberof PaymentSummaryTransactionCard
     */
    verifier?: string;
    /**
     * Datatype that defines a URL.
     * @type {string}
     * @memberof PaymentSummaryTransactionCard
     */
    checkoutUrl?: string;
    /**
     * The type of card used for a transaction.
     * @type {number}
     * @memberof PaymentSummaryTransactionCard
     */
    typeId: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentSummaryTransactionCard
     */
    network?: PaymentSummaryTransactionCardNetworkEnum;
}

/**
* @export
* @enum {string}
*/
export enum PaymentSummaryTransactionCardNetworkEnum {
    Amex = 'amex',
    Dankort = 'dankort',
    Diners = 'diners',
    Dinersclub = 'dinersclub',
    Jcb = 'jcb',
    Maestro = 'maestro',
    Mastercard = 'mastercard',
    Visa = 'visa',
    Visaelectron = 'visaelectron',
    Discover = 'discover',
    Uatp = 'uatp'
}

export function PaymentSummaryTransactionCardFromJSON(json: any): PaymentSummaryTransactionCard {
    return PaymentSummaryTransactionCardFromJSONTyped(json, false);
}

export function PaymentSummaryTransactionCardFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentSummaryTransactionCard {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cryptogram': !exists(json, 'cryptogram') ? undefined : PaymentSummaryTransactionCardCryptogramFromJSON(json['cryptogram']),
        'token': !exists(json, 'token') ? undefined : json['token'],
        'verifier': !exists(json, 'verifier') ? undefined : json['verifier'],
        'checkoutUrl': !exists(json, 'checkout_url') ? undefined : json['checkout_url'],
        'typeId': json['type_id'],
        'network': !exists(json, 'network') ? undefined : json['network'],
    };
}

export function PaymentSummaryTransactionCardToJSON(value?: PaymentSummaryTransactionCard | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cryptogram': PaymentSummaryTransactionCardCryptogramToJSON(value.cryptogram),
        'token': value.token,
        'verifier': value.verifier,
        'checkout_url': value.checkoutUrl,
        'type_id': value.typeId,
        'network': value.network,
    };
}

