import { CPDOptions } from "../../types/cpd-init";
import { Address } from "../generated-sources/openapi/models/Address";
import { PayResponse } from "../generated-sources/openapi/models/PayResponse";
import { PayTransaction } from "../generated-sources/openapi/models/PayTransaction";
import { CardDetails } from "./validate-card-details";

export async function submitPay(options: CPDOptions , cardDetails: CardDetails, paymentsApi, billingAddress?: Address): Promise<PayResponse> {
    const transaction: PayTransaction = { // @TODO, add missing type
        id: parseInt(options.txRef, 10),
        billingAddress,
        card: {
            typeId: cardDetails.cardTypeId,
            amount: {
                country: options.amount.country,
                currency: options.amount.currency,
                value: options.amount.value,
            }
        }
    }

    const clientInfo = {  // Why are we sending this?
        language: options.meta.language,
        platform: options.meta.platform,
        version: options.meta.version
    };

	return await paymentsApi.pay({
		authorization: "Bearer " + options.authToken,
		hostResolver: options.hostResolver,
		contentType: "application/json",

		payRequest: {
			account: options.meta.account,
			clientId: options.meta.clientId,
			transaction,
			clientInfo // Why are we sending this?
		}
	});
}
