/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Fee,
    FeeFromJSON,
    FeeFromJSONTyped,
    FeeToJSON,
} from './Fee';

/**
 * 
 * @export
 * @interface AirlineDataFees
 */
export interface AirlineDataFees {
    /**
     * 
     * @type {Array<Fee>}
     * @memberof AirlineDataFees
     */
    fee?: Array<Fee>;
}

export function AirlineDataFeesFromJSON(json: any): AirlineDataFees {
    return AirlineDataFeesFromJSONTyped(json, false);
}

export function AirlineDataFeesFromJSONTyped(json: any, ignoreDiscriminator: boolean): AirlineDataFees {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fee': !exists(json, 'fee') ? undefined : ((json['fee'] as Array<any>).map(FeeFromJSON)),
    };
}

export function AirlineDataFeesToJSON(value?: AirlineDataFees | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fee': value.fee === undefined ? undefined : ((value.fee as Array<any>).map(FeeToJSON)),
    };
}

