/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AdditionalData,
    AdditionalDataFromJSON,
    AdditionalDataFromJSONTyped,
    AdditionalDataToJSON,
} from './AdditionalData';

/**
 * The flight itinerary data associated with the ticket if the cart item is an airline booking.
 * @export
 * @interface FlightDetail
 */
export interface FlightDetail {
    /**
     * The service class of the ticket. e.g. economy, premium economy, business, and first class.
     * @type {string}
     * @memberof FlightDetail
     */
    serviceClass?: string;
    /**
     * The flight number of the airline
     * @type {string}
     * @memberof FlightDetail
     */
    flightNumber?: string;
    /**
     * The departure IATA airport code
     * @type {string}
     * @memberof FlightDetail
     */
    departureAirport?: string;
    /**
     * The arrival IATA airport code.
     * @type {string}
     * @memberof FlightDetail
     */
    arrivalAirport?: string;
    /**
     * The IATA airline code.
     * @type {string}
     * @memberof FlightDetail
     */
    airlineCode?: string;
    /**
     * Date of departure.
     * @type {string}
     * @memberof FlightDetail
     */
    departureDate?: string;
    /**
     * Date of arrival.
     * @type {string}
     * @memberof FlightDetail
     */
    arrivalDate?: string;
    /**
     * 
     * @type {AdditionalData}
     * @memberof FlightDetail
     */
    additionalData?: AdditionalData;
    /**
     * 
     * @type {number}
     * @memberof FlightDetail
     */
    tag?: number;
    /**
     * For a journey with multiple legs, this number indicates the leg count.
     * @type {number}
     * @memberof FlightDetail
     */
    tripCount?: number;
    /**
     * 
     * @type {number}
     * @memberof FlightDetail
     */
    serviceLevel?: number;
}

export function FlightDetailFromJSON(json: any): FlightDetail {
    return FlightDetailFromJSONTyped(json, false);
}

export function FlightDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): FlightDetail {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'serviceClass': !exists(json, 'service_class') ? undefined : json['service_class'],
        'flightNumber': !exists(json, 'flight_number') ? undefined : json['flight_number'],
        'departureAirport': !exists(json, 'departure_airport') ? undefined : json['departure_airport'],
        'arrivalAirport': !exists(json, 'arrival_airport') ? undefined : json['arrival_airport'],
        'airlineCode': !exists(json, 'airline_code') ? undefined : json['airline_code'],
        'departureDate': !exists(json, 'departure_date') ? undefined : json['departure_date'],
        'arrivalDate': !exists(json, 'arrival_date') ? undefined : json['arrival_date'],
        'additionalData': !exists(json, 'additional_data') ? undefined : AdditionalDataFromJSON(json['additional_data']),
        'tag': !exists(json, 'tag') ? undefined : json['tag'],
        'tripCount': !exists(json, 'trip_count') ? undefined : json['trip_count'],
        'serviceLevel': !exists(json, 'service_level') ? undefined : json['service_level'],
    };
}

export function FlightDetailToJSON(value?: FlightDetail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'service_class': value.serviceClass,
        'flight_number': value.flightNumber,
        'departure_airport': value.departureAirport,
        'arrival_airport': value.arrivalAirport,
        'airline_code': value.airlineCode,
        'departure_date': value.departureDate,
        'arrival_date': value.arrivalDate,
        'additional_data': AdditionalDataToJSON(value.additionalData),
        'tag': value.tag,
        'trip_count': value.tripCount,
        'service_level': value.serviceLevel,
    };
}

