import { html } from "htm/preact";
import { CPInput } from "./input";
import styled from 'styled-components';
import { useEffect, useRef } from "preact/hooks";

const ExpirationInputElm = styled(CPInput)``;

export function ExpirationInput(props: any) {
	const ref = useRef();

	useEffect(() => {
		if (ref.current) {
			const elm: Element = ref.current as Element;

			const listener = (e: KeyboardEvent) => {
				const target = e.target as HTMLInputElement;
				let caretPos = target.selectionStart;
				let newVal = target.value.replace(/\D+/g, '');

				if(newVal && target.value.replace(/\//ig, '') !== newVal) {
					caretPos = caretPos-1;
				}

				if (newVal) {
					newVal = newVal.match(/.{1,2}/g).join('/');

					if (target.value.indexOf('/') === -1 && newVal.indexOf('/') > -1) {
						caretPos = caretPos+1;
					}
				}

				if (target.value !== newVal) {
					target.value = newVal.substring(0,5);
					target.setSelectionRange(caretPos, caretPos)
				}
			}

			elm.addEventListener('keyup', listener)
			return () => elm.removeEventListener('keyup', listener);
		}
	}, [ ref.current ])

	return html`<${ExpirationInputElm} ref=${ref} ...${props} />`
}
