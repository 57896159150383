import { CardMeta, CPDOptions, CreditCardField } from '../../types/cpd-init';
import { CPInput } from './input';
import { CPInputWrapper } from './input-wrapper';
import { getCardType } from '../methods/get-card-type';
import { getCVVHintImage } from '../methods/cvv_hint/get-cvv-hint-img';
import { getFieldValue } from '../methods/get-field-value';
import { html } from 'htm/preact';
import { useEffect, useRef, useState } from 'preact/hooks';

export function CVVInput(props: { opt: CPDOptions }) {
	const ref = useRef();
	const [ cardPreview, setCardPreview ] = useState<string | null>(null);
	const [ maxLength, setMaxLength ]  = useState<number>(3);

	function onFocus() {
		const cardNumber = getFieldValue(CreditCardField.number);
		const cardType: CardMeta | undefined = getCardType(cardNumber, props.opt.cardsMeta);

		if (cardType === undefined) {
			return;
		}

		if (maxLength !== cardType.cvc_length) {
			setMaxLength(cardType.cvc_length);
		}
	}

	useEffect(() => {
		if (ref.current) {
			const elm: Element = ref.current as Element;

			const listener = (e: KeyboardEvent) => {
				const target = e.target as HTMLInputElement

				setTimeout(() => {
					const newVal = target.value.replace(/\D+/g, '');
					let caretPos = target.selectionStart;

					if (target.value !== newVal) {
						target.value = newVal;

						caretPos -= 1;
						target.setSelectionRange(caretPos, caretPos)
					}
				}, 150)
			}

			elm.addEventListener('keydown', listener)
			elm.addEventListener('focus', () => {
				const cardNumber = getFieldValue(CreditCardField.number);
				const cardMeta: CardMeta = getCardType(cardNumber, props.opt.cardsMeta);
				if (cardMeta) {
					setCardPreview(getCVVHintImage(cardMeta));
				}
			});

			return () => {
				elm.removeEventListener('keydown', listener);
			}
		}
	}, [ ref.current ])

	const hintImage = cardPreview !== null ? html`<img src=${cardPreview} className="hint-image" />` : '';

	return html`
		<${CPInputWrapper}>
			<${CPInput} ref=${ref} ...${props} maxLength=${maxLength} onFocus=${onFocus} />

			${hintImage}
		</${CPInputWrapper}>
	`;
}
