/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PspInfo,
    PspInfoFromJSON,
    PspInfoFromJSONTyped,
    PspInfoToJSON,
} from './PspInfo';
import {
    Status,
    StatusFromJSON,
    StatusFromJSONTyped,
    StatusToJSON,
} from './Status';

/**
 * 
 * @export
 * @interface PayResponse
 */
export interface PayResponse {
    /**
     * <table>
  <thead>
    <tr>
      <th>Status Code</th>
      <th>Transaction State</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>1</td>
      <td>Undefined Client ID</td>
    </tr>
    <tr>
      <td>2</td>
      <td>Invalid Client ID</td>
    </tr>
    <tr>
      <td>3</td>
      <td>Unknown Client ID</td>
    </tr>
    <tr>
      <td>4</td>
      <td>Client Disabled</td>
    </tr>
    <tr>
      <td>11</td>
      <td>Undefined Account</td>
    </tr>
    <tr>
      <td>12</td>
      <td>Invalid Account</td>
    </tr>
    <tr>
      <td>13</td>
      <td>Unknown Account</td>
    </tr>
    <tr>
      <td>14</td>
      <td>Account Disabled</td>
    </tr>
    <tr>
      <td>90</td>
      <td>Unable to find configuration for Payment Service Provider</td>
    </tr>
    <tr>
      <td>91</td>
      <td>The connection timed out while initializing the payment with the Payment Service Provider</td>
    </tr>
    <tr>
      <td>92</td>
      <td>Payment Initialization rejected by Payment Service Provider / Acquirer</td>
    </tr>
    <tr>
      <td>99</td>
      <td>Unknown Payment Service Provider / Acquirer</td>
    </tr>
    <tr>
      <td>200</td>
      <td>Request validated and accepted by Velocity</td>
    </tr>
    <tr>
      <td>400</td>
      <td>Request has been rejected by Velocity due to a validation error</td>
    </tr>
    <tr>
      <td>401</td>
      <td>Authentication required</td>
    </tr>
    <tr>
      <td>403</td>
      <td>Access denied</td>
    </tr>
    <tr>
      <td>415</td>
      <td>Message was transmitted in an unknown format or using an unsupported Content Type</td>
    </tr>
    <tr>
      <td>500</td>
      <td>Velocity server error</td>
    </tr>
    <tr>
      <td>502</td>
      <td>Payment Service Provider returned an unknown error</td>
    </tr>
    <tr>
      <td>504</td>
      <td>A timeout occurred while communicating with the PSP</td>
    </tr>
    <tr>
      <td>2002</td>
      <td>Payment refunded</td>
    </tr>
    <tr>
      <td>2003</td>
      <td>Payment cancelled</td>
    </tr>
    <tr>
      <td>2009</td>
      <td>Card stored</td>
    </tr>
    <tr>
      <td>2201</td>
      <td>Partial capture</td>
    </tr>
    <tr>
      <td>2202</td>
      <td>Partial cancel</td>
    </tr>
    <tr>
      <td>2203</td>
      <td>Partial refund</td>
    </tr>
    <tr>
      <td>2084</td>
      <td>Card not found</td>
    </tr>
    <tr>
      <td>20109</td>
      <td>Payment timeout</td>
    </tr>
  </tbody>
</table>

     * @type {Array<Status>}
     * @memberof PayResponse
     */
    status?: Array<Status>;
    /**
     * 
     * @type {PspInfo}
     * @memberof PayResponse
     */
    pspInfo?: PspInfo;
}

export function PayResponseFromJSON(json: any): PayResponse {
    return PayResponseFromJSONTyped(json, false);
}

export function PayResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PayResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': !exists(json, 'status') ? undefined : ((json['status'] as Array<any>).map(StatusFromJSON)),
        'pspInfo': !exists(json, 'psp_info') ? undefined : PspInfoFromJSON(json['psp_info']),
    };
}

export function PayResponseToJSON(value?: PayResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status === undefined ? undefined : ((value.status as Array<any>).map(StatusToJSON)),
        'psp_info': PspInfoToJSON(value.pspInfo),
    };
}

