/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetStatusResponseMessage
 */
export interface GetStatusResponseMessage {
    /**
     * A unique ID for every state of the transaction.
     * @type {number}
     * @memberof GetStatusResponseMessage
     */
    id: number;
    /**
     * A state ID as described in the status codes.
     * @type {number}
     * @memberof GetStatusResponseMessage
     */
    stateId?: number;
    /**
     * Timestamp when the status change occurs.
     * @type {string}
     * @memberof GetStatusResponseMessage
     */
    timestamp?: string;
}

export function GetStatusResponseMessageFromJSON(json: any): GetStatusResponseMessage {
    return GetStatusResponseMessageFromJSONTyped(json, false);
}

export function GetStatusResponseMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetStatusResponseMessage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'stateId': !exists(json, 'state_id') ? undefined : json['state_id'],
        'timestamp': !exists(json, 'timestamp') ? undefined : json['timestamp'],
    };
}

export function GetStatusResponseMessageToJSON(value?: GetStatusResponseMessage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'state_id': value.stateId,
        'timestamp': value.timestamp,
    };
}

