/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * The price format that is used when displaying prices in the country.
 * @export
 * @enum {string}
 */
export enum Format {
    Currencyprice = '{CURRENCY}{PRICE}',
    PriceCurrency = '{PRICE} {CURRENCY}',
    Empty = ''
}

export function FormatFromJSON(json: any): Format {
    return FormatFromJSONTyped(json, false);
}

export function FormatFromJSONTyped(json: any, ignoreDiscriminator: boolean): Format {
    return json as Format;
}

export function FormatToJSON(value?: Format | null): any {
    return value as any;
}

