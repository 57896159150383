/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Foreign exchange parameters
 * @export
 * @interface PayTransactionForeignExchangeInfo
 */
export interface PayTransactionForeignExchangeInfo {
    /**
     * A unique identification number for an offer.
     * @type {string}
     * @memberof PayTransactionForeignExchangeInfo
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof PayTransactionForeignExchangeInfo
     */
    saleCurrencyId?: number;
    /**
     * 
     * @type {number}
     * @memberof PayTransactionForeignExchangeInfo
     */
    saleAmount?: number;
    /**
     * The rate at which a currency is converted.
     * @type {number}
     * @memberof PayTransactionForeignExchangeInfo
     */
    conversionRate: number;
    /**
     * The ID to indicate the type of exchange services used for a transaction. The ID indicates if the FX services such as DCC, MCP, PCC are opted. The Id is a two-digit number XY in which:

* X - Represents the type of exchange service used in a transaction.
* Y - Represents the service usedꟷ it can be opt-in or opt-outꟷ it can be:
  * opt-in, which has value as “1”
  * opt-out, which has value as “2”

The following table shows the possible values of exchange service ID:

<table>
  <thead>
    <tr>
      <th>ID (XY)</th>
      <th>Service (X)</th>
      <th>Opted</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>11</td>
      <td>DCC</td>
      <td>Opted</td>
    </tr>
    <tr>
      <td>12</td>
      <td>DCC</td>
      <td>Not opted</td>
    </tr>
    <tr>
      <td>21</td>
      <td>MCP</td>
      <td>Opted</td>
    </tr>
    <tr>
      <td>22</td>
      <td>MCP</td>
      <td>Not opted</td>
    </tr>
    <tr>
      <td>31</td>
      <td>External MCP</td>
      <td>Opted</td>
    </tr>
    <tr>
      <td>32</td>
      <td>External MCP</td>
      <td>Not opted</td>
    </tr>
    <tr>
      <td>41</td>
      <td>PCC</td>
      <td>Opted</td>
    </tr>
    <tr>
      <td>42</td>
      <td>PCC</td>
      <td>Not opted</td>
    </tr>
  </tbody>
</table>

     * @type {number}
     * @memberof PayTransactionForeignExchangeInfo
     */
    serviceTypeId?: number;
}

export function PayTransactionForeignExchangeInfoFromJSON(json: any): PayTransactionForeignExchangeInfo {
    return PayTransactionForeignExchangeInfoFromJSONTyped(json, false);
}

export function PayTransactionForeignExchangeInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PayTransactionForeignExchangeInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'saleCurrencyId': !exists(json, 'sale_currency_id') ? undefined : json['sale_currency_id'],
        'saleAmount': !exists(json, 'sale_amount') ? undefined : json['sale_amount'],
        'conversionRate': json['conversion_rate'],
        'serviceTypeId': !exists(json, 'service_type_id') ? undefined : json['service_type_id'],
    };
}

export function PayTransactionForeignExchangeInfoToJSON(value?: PayTransactionForeignExchangeInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'sale_currency_id': value.saleCurrencyId,
        'sale_amount': value.saleAmount,
        'conversion_rate': value.conversionRate,
        'service_type_id': value.serviceTypeId,
    };
}

