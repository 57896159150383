/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Fare,
    FareFromJSON,
    FareFromJSONTyped,
    FareToJSON,
} from './Fare';

/**
 * 
 * @export
 * @interface AirlineDataBillingSummaryFareDetail
 */
export interface AirlineDataBillingSummaryFareDetail {
    /**
     * 
     * @type {Array<Fare>}
     * @memberof AirlineDataBillingSummaryFareDetail
     */
    fare?: Array<Fare>;
}

export function AirlineDataBillingSummaryFareDetailFromJSON(json: any): AirlineDataBillingSummaryFareDetail {
    return AirlineDataBillingSummaryFareDetailFromJSONTyped(json, false);
}

export function AirlineDataBillingSummaryFareDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): AirlineDataBillingSummaryFareDetail {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fare': !exists(json, 'fare') ? undefined : ((json['fare'] as Array<any>).map(FareFromJSON)),
    };
}

export function AirlineDataBillingSummaryFareDetailToJSON(value?: AirlineDataBillingSummaryFareDetail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fare': value.fare === undefined ? undefined : ((value.fare as Array<any>).map(FareToJSON)),
    };
}

