/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AdditionalData,
    AdditionalDataFromJSON,
    AdditionalDataFromJSONTyped,
    AdditionalDataToJSON,
} from './AdditionalData';
import {
    Address,
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';
import {
    Amount,
    AmountFromJSON,
    AmountFromJSONTyped,
    AmountToJSON,
} from './Amount';
import {
    AuthorizeTransactionCard,
    AuthorizeTransactionCardFromJSON,
    AuthorizeTransactionCardFromJSONTyped,
    AuthorizeTransactionCardToJSON,
} from './AuthorizeTransactionCard';
import {
    AuthorizeTransactionForeignExchangeInfo,
    AuthorizeTransactionForeignExchangeInfoFromJSON,
    AuthorizeTransactionForeignExchangeInfoFromJSONTyped,
    AuthorizeTransactionForeignExchangeInfoToJSON,
} from './AuthorizeTransactionForeignExchangeInfo';
import {
    CardInstallment,
    CardInstallmentFromJSON,
    CardInstallmentFromJSONTyped,
    CardInstallmentToJSON,
} from './CardInstallment';
import {
    InitializeTransactionCustomVariables,
    InitializeTransactionCustomVariablesFromJSON,
    InitializeTransactionCustomVariablesFromJSONTyped,
    InitializeTransactionCustomVariablesToJSON,
} from './InitializeTransactionCustomVariables';
import {
    Order,
    OrderFromJSON,
    OrderFromJSONTyped,
    OrderToJSON,
} from './Order';
import {
    Reward,
    RewardFromJSON,
    RewardFromJSONTyped,
    RewardToJSON,
} from './Reward';
import {
    Voucher,
    VoucherFromJSON,
    VoucherFromJSONTyped,
    VoucherToJSON,
} from './Voucher';

/**
 * 
 * @export
 * @interface AuthorizeTransaction
 */
export interface AuthorizeTransaction {
    /**
     * The ID of the transaction.
     * @type {number}
     * @memberof AuthorizeTransaction
     */
    id: number;
    /**
     * A unique transaction identification number.
     * @type {number}
     * @memberof AuthorizeTransaction
     */
    typeId?: number;
    /**
     * A Boolean value which indicates if card details are to be stored after successful authorization.
     * @type {boolean}
     * @memberof AuthorizeTransaction
     */
    storeCard?: boolean;
    /**
     * 
     * @type {Voucher}
     * @memberof AuthorizeTransaction
     */
    voucher?: Voucher;
    /**
     * 
     * @type {AuthorizeTransactionCard}
     * @memberof AuthorizeTransaction
     */
    card?: AuthorizeTransactionCard;
    /**
     * The Message Authentication Code. 
It is calculated by creating a sha-512 hash. 
The hash is comprised of the following input fields in the listed order:

- clientid 
- account 
- orderid 
- callback-url 
- amount 
- auth-url 
- customer-ref 
- auth-token 
- email
- mobile
- [salt]

> **Note**: CPD provides the salt value to merchants.

MAC calculation secures the information sent by the merchant to Velocity by applying the SHA-512 encryption algorithm on key parts of the information sent to protect against tampering. 
The ‘salt’ is the merchant's shared secret string used to ensure that MAC is unique.

     * @type {string}
     * @memberof AuthorizeTransaction
     */
    hmac?: string;
    /**
     * The Description of a transaction performed.
     * @type {string}
     * @memberof AuthorizeTransaction
     */
    description?: string;
    /**
     * 
     * @type {CardInstallment}
     * @memberof AuthorizeTransaction
     */
    cardInstallment?: CardInstallment;
    /**
     * 
     * @type {AuthorizeTransactionForeignExchangeInfo}
     * @memberof AuthorizeTransaction
     */
    foreignExchangeInfo?: AuthorizeTransactionForeignExchangeInfo;
    /**
     * 
     * @type {AdditionalData}
     * @memberof AuthorizeTransaction
     */
    additionalData?: AdditionalData;
    /**
     * 
     * @type {Order}
     * @memberof AuthorizeTransaction
     */
    orders?: Order;
    /**
     * 
     * @type {CardInstallment}
     * @memberof AuthorizeTransaction
     */
    installment?: CardInstallment;
    /**
     * The total amount that a customer is charged for a payment transaction in a country’s smallest currency. For example, the smallest currency of USD is penny. If the transaction amount is $120.30, then the amount field contains value 12030.

> **Note**: The amount is always a non-decimal value in the request.

     * @type {Amount}
     * @memberof AuthorizeTransaction
     */
    amount?: Amount | null;
    /**
     * 
     * @type {number}
     * @memberof AuthorizeTransaction
     */
    points?: number;
    /**
     * 
     * @type {Reward}
     * @memberof AuthorizeTransaction
     */
    reward?: Reward;
    /**
     * The absolute URL where Velocity directs customers if they cancel a payment transaction midway.

> **Note**: If you do not provide this parameter, Velocity uses the default URL. The URL is used for redirecting back to either HPP or Merchant’s page after payment cancellation.

     * @type {string}
     * @memberof AuthorizeTransaction
     */
    cancelUrl?: string | null;
    /**
     * The absolute URL to the back office of a merchant where Velocity POP sends the payment status.

> **Note**: If you do not provide this parameter, Velocity uses the default URL.

     * @type {string}
     * @memberof AuthorizeTransaction
     */
    callbackUrl?: string | null;
    /**
     * The URL where Velocity directs a customer after successfully completing a payment transaction.

> **Note**: If you do not provide this parameter, Velocity uses the default URL. The URL is used for redirecting back to either HPP or Merchant’s page after payment completion.

     * @type {string}
     * @memberof AuthorizeTransaction
     */
    acceptUrl?: string | null;
    /**
     * The absolute URL where Velocity directs customers if the payment transaction fails. The URL is used for redirecting back to either HPP or Merchant’s page after payment failure.
     * @type {string}
     * @memberof AuthorizeTransaction
     */
    declineUrl?: string | null;
    /**
     * The URL which used for SSO authentication if provided by external system.
     * @type {string}
     * @memberof AuthorizeTransaction
     */
    authUrl?: string | null;
    /**
     * 
     * @type {InitializeTransactionCustomVariables}
     * @memberof AuthorizeTransaction
     */
    customVariables?: InitializeTransactionCustomVariables;
    /**
     * 
     * @type {Address}
     * @memberof AuthorizeTransaction
     */
    billingAddress?: Address;
    /**
     * 
     * @type {string}
     * @memberof AuthorizeTransaction
     */
    orderNo?: string;
    /**
     * The ID of a session used for multiple payment transactions during one payment flow, for example, split payment or retry of a failed payment transaction
     * @type {number}
     * @memberof AuthorizeTransaction
     */
    sessionId?: number;
    /**
     * The retail booking reference that could be used for tracing the transaction in the retail system.
This field is provided over the order-no, if order-no does act as the key param.
It is the PNR number for airlines.

     * @type {string}
     * @memberof AuthorizeTransaction
     */
    bookingRef?: string;
}

export function AuthorizeTransactionFromJSON(json: any): AuthorizeTransaction {
    return AuthorizeTransactionFromJSONTyped(json, false);
}

export function AuthorizeTransactionFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthorizeTransaction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'typeId': !exists(json, 'type_id') ? undefined : json['type_id'],
        'storeCard': !exists(json, 'store_card') ? undefined : json['store_card'],
        'voucher': !exists(json, 'voucher') ? undefined : VoucherFromJSON(json['voucher']),
        'card': !exists(json, 'card') ? undefined : AuthorizeTransactionCardFromJSON(json['card']),
        'hmac': !exists(json, 'hmac') ? undefined : json['hmac'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'cardInstallment': !exists(json, 'card_installment') ? undefined : CardInstallmentFromJSON(json['card_installment']),
        'foreignExchangeInfo': !exists(json, 'foreign_exchange_info') ? undefined : AuthorizeTransactionForeignExchangeInfoFromJSON(json['foreign_exchange_info']),
        'additionalData': !exists(json, 'additional_data') ? undefined : AdditionalDataFromJSON(json['additional_data']),
        'orders': !exists(json, 'orders') ? undefined : OrderFromJSON(json['orders']),
        'installment': !exists(json, 'installment') ? undefined : CardInstallmentFromJSON(json['installment']),
        'amount': !exists(json, 'amount') ? undefined : AmountFromJSON(json['amount']),
        'points': !exists(json, 'points') ? undefined : json['points'],
        'reward': !exists(json, 'reward') ? undefined : RewardFromJSON(json['reward']),
        'cancelUrl': !exists(json, 'cancel_url') ? undefined : json['cancel_url'],
        'callbackUrl': !exists(json, 'callback_url') ? undefined : json['callback_url'],
        'acceptUrl': !exists(json, 'accept_url') ? undefined : json['accept_url'],
        'declineUrl': !exists(json, 'decline_url') ? undefined : json['decline_url'],
        'authUrl': !exists(json, 'auth_url') ? undefined : json['auth_url'],
        'customVariables': !exists(json, 'custom_variables') ? undefined : InitializeTransactionCustomVariablesFromJSON(json['custom_variables']),
        'billingAddress': !exists(json, 'billing_address') ? undefined : AddressFromJSON(json['billing_address']),
        'orderNo': !exists(json, 'order_no') ? undefined : json['order_no'],
        'sessionId': !exists(json, 'session_id') ? undefined : json['session_id'],
        'bookingRef': !exists(json, 'booking_ref') ? undefined : json['booking_ref'],
    };
}

export function AuthorizeTransactionToJSON(value?: AuthorizeTransaction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type_id': value.typeId,
        'store_card': value.storeCard,
        'voucher': VoucherToJSON(value.voucher),
        'card': AuthorizeTransactionCardToJSON(value.card),
        'hmac': value.hmac,
        'description': value.description,
        'card_installment': CardInstallmentToJSON(value.cardInstallment),
        'foreign_exchange_info': AuthorizeTransactionForeignExchangeInfoToJSON(value.foreignExchangeInfo),
        'additional_data': AdditionalDataToJSON(value.additionalData),
        'orders': OrderToJSON(value.orders),
        'installment': CardInstallmentToJSON(value.installment),
        'amount': AmountToJSON(value.amount),
        'points': value.points,
        'reward': RewardToJSON(value.reward),
        'cancel_url': value.cancelUrl,
        'callback_url': value.callbackUrl,
        'accept_url': value.acceptUrl,
        'decline_url': value.declineUrl,
        'auth_url': value.authUrl,
        'custom_variables': InitializeTransactionCustomVariablesToJSON(value.customVariables),
        'billing_address': AddressToJSON(value.billingAddress),
        'order_no': value.orderNo,
        'session_id': value.sessionId,
        'booking_ref': value.bookingRef,
    };
}

