/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Param,
    ParamFromJSON,
    ParamFromJSONTyped,
    ParamToJSON,
} from './Param';

/**
 * Custom data field that could be used for setting non-standard key-value pairs. 
These values are associated with the parent entity wherein they appear, ex, psp-config, client-config, transaction.

 * @export
 * @interface AdditionalConfig
 */
export interface AdditionalConfig {
    /**
     * 
     * @type {Array<Param>}
     * @memberof AdditionalConfig
     */
    property?: Array<Param>;
}

export function AdditionalConfigFromJSON(json: any): AdditionalConfig {
    return AdditionalConfigFromJSONTyped(json, false);
}

export function AdditionalConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdditionalConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'property': !exists(json, 'property') ? undefined : ((json['property'] as Array<any>).map(ParamFromJSON)),
    };
}

export function AdditionalConfigToJSON(value?: AdditionalConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'property': value.property === undefined ? undefined : ((value.property as Array<any>).map(ParamToJSON)),
    };
}

