/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AuthorizeResponseCard,
    AuthorizeResponseCardFromJSON,
    AuthorizeResponseCardFromJSONTyped,
    AuthorizeResponseCardToJSON,
} from './AuthorizeResponseCard';
import {
    AuthorizeResponseParsedChallenge,
    AuthorizeResponseParsedChallengeFromJSON,
    AuthorizeResponseParsedChallengeFromJSONTyped,
    AuthorizeResponseParsedChallengeToJSON,
} from './AuthorizeResponseParsedChallenge';
import {
    Status,
    StatusFromJSON,
    StatusFromJSONTyped,
    StatusToJSON,
} from './Status';

/**
 * The Velocity server sends the response informing if the transaction authorization was a success or a failure.
The following example shows that a payment request was authorized successfully.

 * @export
 * @interface AuthorizeResponse
 */
export interface AuthorizeResponse {
    /**
     * <table>
  <thead>
    <tr>
      <th>Status Code</th>
      <th>Transaction State</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>1</td>
      <td>Undefined Client ID</td>
    </tr>
    <tr>
      <td>2</td>
      <td>Invalid Client ID</td>
    </tr>
    <tr>
      <td>3</td>
      <td>Unknown Client ID</td>
    </tr>
    <tr>
      <td>4</td>
      <td>Client Disabled</td>
    </tr>
    <tr>
      <td>11</td>
      <td>Undefined Account</td>
    </tr>
    <tr>
      <td>12</td>
      <td>Invalid Account</td>
    </tr>
    <tr>
      <td>13</td>
      <td>Unknown Account</td>
    </tr>
    <tr>
      <td>14</td>
      <td>Account Disabled</td>
    </tr>
    <tr>
      <td>21</td>
      <td>Undefined Card ID</td>
    </tr>
    <tr>
      <td>22</td>
      <td>Card ID is too small, min value is 1</td>
    </tr>
    <tr>
      <td>23</td>
      <td>Card not found</td>
    </tr>
    <tr>
      <td>24</td>
      <td>Card Disabled</td>
    </tr>
    <tr>
      <td>26</td>
      <td>Undefined Password</td>
    </tr>
    <tr>
      <td>27</td>
      <td>Password is too short; min length is 6 characters</td>
    </tr>
    <tr>
      <td>28</td>
      <td>Password is too long; max length is 50 characters</td>
    </tr>
    <tr>
      <td>29</td>
      <td>Password contains invalid characters: ‘or’</td>
    </tr>
    <tr>
      <td>31</td>
      <td>Authentication failed</td>
    </tr>
    <tr>
      <td>32</td>
      <td>Authentication failed – Next invalid attempt will delete the account</td>
    </tr>
    <tr>
      <td>33</td>
      <td>Authentication failed – Account deleted</td>
    </tr>
    <tr>
      <td>34</td>
      <td>User account not found</td>
    </tr>
    <tr>
      <td>37</td>
      <td>Mobile number not verified</td>
    </tr>
    <tr>
      <td>39</td>
      <td>User Account disabled</td>
    </tr>
    <tr>
      <td>41</td>
      <td>Insufficient balance on e-money account</td>
    </tr>
    <tr>
      <td>42</td>
      <td>Insufficient balance on loyalty account</td>
    </tr>
    <tr>
      <td>43</td>
      <td>Insufficient balance on voucher</td>
    </tr>
    <tr>
      <td>45</td>
      <td>Voucher and Redeem device-ids not equal</td>
    </tr>
    <tr>
      <td>48</td>
      <td>Voucher payment temporarily blocked</td>
    </tr>
    <tr>
      <td>49</td>
      <td>Authorization type not supported for Transaction</td>
    </tr>
    <tr>
      <td>90</td>
      <td>Unable to find configuration for Payment Service Provider</td>
    </tr>
    <tr>
      <td>91</td>
      <td>Internal Error: Unable to debit account</td>
    </tr>
    <tr>
      <td>92</td>
      <td>Authorization rejected by Payment Service Provider / Acquirer</td>
    </tr>
    <tr>
      <td>93</td>
      <td>The connection timed out while authorizing the payment with the Payment Service Provider</td>
    </tr>
    <tr>
      <td>99</td>
      <td>Unknown Payment Service Provider / Acquirer</td>
    </tr>
    <tr>
      <td>100</td>
      <td>Payment authorized using stored card</td>
    </tr>
    <tr>
      <td>101</td>
      <td>Payment authorized using e-money account</td>
    </tr>
    <tr>
      <td>102</td>
      <td>Payment authorized using loyalty account</td>
    </tr>
    <tr>
      <td>103</td>
      <td>Authorization already in progress</td>
    </tr>
    <tr>
      <td>104</td>
      <td>Payment authorized using voucher</td>
    </tr>
    <tr>
      <td>200</td>
      <td>Request validated and accepted by Velocity</td>
    </tr>
    <tr>
      <td>400</td>
      <td>Request has been rejected by Velocity due to a validation error</td>
    </tr>
    <tr>
      <td>401</td>
      <td>Authentication required</td>
    </tr>
    <tr>
      <td>403</td>
      <td>Access denied</td>
    </tr>
    <tr>
      <td>415</td>
      <td>Message was transmitted in an unknown format or using an unsupported Content Type</td>
    </tr>
    <tr>
      <td>500</td>
      <td>Velocity server error</td>
    </tr>
    <tr>
      <td>502</td>
      <td>Payment Service Provider returned an unknown error</td>
    </tr>
    <tr>
      <td>504</td>
      <td>A timeout occurred while communicating with the PSP</td>
    </tr>
    <tr>
      <td>1001</td>
      <td>Internal database error - Unable to generate new Transaction ID. Contact support@cellpointdigital.com</td>
    </tr>
    <tr>
      <td>1002</td>
      <td>Internal database error - Unable to create a new Transaction. Contact support@cellpointdigital.com</td>
    </tr>
    <tr>
      <td>1003</td>
      <td>Internal Database Error - Unable to insert new message for Transaction. Contact support@cellpointdigital.com</td>
    </tr>
    <tr>
      <td>1004</td>
      <td>Internal database error - Unable to update Transaction. Contact support@cellpointdigital.com</td>
    </tr>
    <tr>
      <td>2000</td>
      <td>Payment successfully Authorized by the Payment Service Provider / Acquirer</td>
    </tr>
    <tr>
      <td>2002</td>
      <td>Payment refunded</td>
    </tr>
    <tr>
      <td>2003</td>
      <td>Payment cancelled</td>
    </tr>
    <tr>
      <td>2005</td>
      <td>3d verification required</td>
    </tr>
    <tr>
      <td>2009</td>
      <td>Card stored</td>
    </tr>
    <tr>
      <td>2010</td>
      <td>Payment declined by the Payment Service Provider / Acquirer</td>
    </tr>
    <tr>
      <td>2201</td>
      <td>Partial capture</td>
    </tr>
    <tr>
      <td>2202</td>
      <td>Partial cancel</td>
    </tr>
    <tr>
      <td>2203</td>
      <td>Partial refund</td>
    </tr>
    <tr>
      <td>2084</td>
      <td>Card not found</td>
    </tr>
    <tr>
      <td>20109</td>
      <td>Payment timeout</td>
    </tr>
  </tbody>
</table>

     * @type {Array<Status>}
     * @memberof AuthorizeResponse
     */
    status: Array<Status>;
    /**
     * 
     * @type {AuthorizeResponseParsedChallenge}
     * @memberof AuthorizeResponse
     */
    parsedChallenge?: AuthorizeResponseParsedChallenge;
    /**
     * The URL passed to the third party to return the status of the verification done with the parsed challenge form.
     * @type {string}
     * @memberof AuthorizeResponse
     */
    returnUrl?: string | null;
    /**
     * Token that encapsulates the payment data and used as an alternate to the card details. Also know as the digital payment token.
     * @type {string}
     * @memberof AuthorizeResponse
     */
    token?: string;
    /**
     * 
     * @type {AuthorizeResponseCard}
     * @memberof AuthorizeResponse
     */
    card?: AuthorizeResponseCard;
}

export function AuthorizeResponseFromJSON(json: any): AuthorizeResponse {
    return AuthorizeResponseFromJSONTyped(json, false);
}

export function AuthorizeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthorizeResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': ((json['status'] as Array<any>).map(StatusFromJSON)),
        'parsedChallenge': !exists(json, 'parsed_challenge') ? undefined : AuthorizeResponseParsedChallengeFromJSON(json['parsed_challenge']),
        'returnUrl': !exists(json, 'return_url') ? undefined : json['return_url'],
        'token': !exists(json, 'token') ? undefined : json['token'],
        'card': !exists(json, 'card') ? undefined : AuthorizeResponseCardFromJSON(json['card']),
    };
}

export function AuthorizeResponseToJSON(value?: AuthorizeResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': ((value.status as Array<any>).map(StatusToJSON)),
        'parsed_challenge': AuthorizeResponseParsedChallengeToJSON(value.parsedChallenge),
        'return_url': value.returnUrl,
        'token': value.token,
        'card': AuthorizeResponseCardToJSON(value.card),
    };
}

