/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Fare
 */
export interface Fare {
    /**
     * 
     * @type {number}
     * @memberof Fare
     */
    profileSeq?: number;
    /**
     * 
     * @type {string}
     * @memberof Fare
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Fare
     */
    currency?: string;
    /**
     * 
     * @type {string}
     * @memberof Fare
     */
    amount?: string;
    /**
     * 
     * @type {string}
     * @memberof Fare
     */
    productCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Fare
     */
    productCategory?: string;
    /**
     * 
     * @type {string}
     * @memberof Fare
     */
    productItem?: string;
}

export function FareFromJSON(json: any): Fare {
    return FareFromJSONTyped(json, false);
}

export function FareFromJSONTyped(json: any, ignoreDiscriminator: boolean): Fare {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'profileSeq': !exists(json, 'profile_seq') ? undefined : json['profile_seq'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'currency': !exists(json, 'currency') ? undefined : json['currency'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'productCode': !exists(json, 'product_code') ? undefined : json['product_code'],
        'productCategory': !exists(json, 'product_category') ? undefined : json['product_category'],
        'productItem': !exists(json, 'product_item') ? undefined : json['product_item'],
    };
}

export function FareToJSON(value?: Fare | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'profile_seq': value.profileSeq,
        'description': value.description,
        'currency': value.currency,
        'amount': value.amount,
        'product_code': value.productCode,
        'product_category': value.productCategory,
        'product_item': value.productItem,
    };
}

