/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum Network {
    Dankort = 'dankort',
    Diners = 'diners',
    Dinersclub = 'dinersclub',
    Jcb = 'jcb',
    Maestro = 'maestro',
    Mastercard = 'mastercard',
    Visa = 'visa',
    Visaelectron = 'visaelectron',
    Discover = 'discover',
    Uatp = 'uatp'
}

export function NetworkFromJSON(json: any): Network {
    return NetworkFromJSONTyped(json, false);
}

export function NetworkFromJSONTyped(json: any, ignoreDiscriminator: boolean): Network {
    return json as Network;
}

export function NetworkToJSON(value?: Network | null): any {
    return value as any;
}

