/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PaymentType,
    PaymentTypeFromJSON,
    PaymentTypeFromJSONTyped,
    PaymentTypeToJSON,
} from './PaymentType';

/**
 * 
 * @export
 * @interface Combination
 */
export interface Combination {
    /**
     * 
     * @type {Array<PaymentType>}
     * @memberof Combination
     */
    paymentType?: Array<PaymentType>;
}

export function CombinationFromJSON(json: any): Combination {
    return CombinationFromJSONTyped(json, false);
}

export function CombinationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Combination {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'paymentType': !exists(json, 'payment_type') ? undefined : ((json['payment_type'] as Array<any>).map(PaymentTypeFromJSON)),
    };
}

export function CombinationToJSON(value?: Combination | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'payment_type': value.paymentType === undefined ? undefined : ((value.paymentType as Array<any>).map(PaymentTypeToJSON)),
    };
}

