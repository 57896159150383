/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * The Account Number used to authorize transactions on behalf of the user that may be physical or virtual in nature.
 * @export
 * @interface Card
 */
export interface Card {
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    orderRef: string;
    /**
     * Country code based on ISO 3166-1 alpha-2 standard:
  * US - usa
  * CN - china
  * JP - japan
  * DE - germany
  * GB - uk
  * IN - india
  * FR - france
  * IT - italy
  * CA - canada

Full list: https://www.iso.org/obp/ui

     * @type {string}
     * @memberof Card
     */
    country?: string;
    /**
     * 
     * @type {number}
     * @memberof Card
     */
    id: number;
    /**
     * 
     * @type {any}
     * @memberof Card
     */
    pspId: any | null;
    /**
     * 
     * @type {string}
     * @memberof Card
     */
    sku: string;
    /**
     * 
     * @type {number}
     * @memberof Card
     */
    type?: number;
    /**
     * The name of a line item present in the cart.
     * @type {string}
     * @memberof Card
     */
    name?: string | null;
    /**
     * The Description of a line item present in the cart.
     * @type {string}
     * @memberof Card
     */
    description?: string | null;
    /**
     * The image URL of the line item in the cart.
     * @type {string}
     * @memberof Card
     */
    imageUrl?: string | null;
}

export function CardFromJSON(json: any): Card {
    return CardFromJSONTyped(json, false);
}

export function CardFromJSONTyped(json: any, ignoreDiscriminator: boolean): Card {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'orderRef': json['order_ref'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'id': json['id'],
        'pspId': json['psp_id'],
        'sku': json['sku'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'imageUrl': !exists(json, 'image_url') ? undefined : json['image_url'],
    };
}

export function CardToJSON(value?: Card | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'order_ref': value.orderRef,
        'country': value.country,
        'id': value.id,
        'psp_id': value.pspId,
        'sku': value.sku,
        'type': value.type,
        'name': value.name,
        'description': value.description,
        'image_url': value.imageUrl,
    };
}

