/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Installment
 */
export interface Installment {
    /**
     * 
     * @type {number}
     * @memberof Installment
     */
    frequency?: number;
    /**
     * 
     * @type {number}
     * @memberof Installment
     */
    maxInstallments?: number;
    /**
     * 
     * @type {number}
     * @memberof Installment
     */
    type?: number;
}

export function InstallmentFromJSON(json: any): Installment {
    return InstallmentFromJSONTyped(json, false);
}

export function InstallmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Installment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'frequency': !exists(json, 'frequency') ? undefined : json['frequency'],
        'maxInstallments': !exists(json, 'max_installments') ? undefined : json['max_installments'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function InstallmentToJSON(value?: Installment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'frequency': value.frequency,
        'max_installments': value.maxInstallments,
        'type': value.type,
    };
}

