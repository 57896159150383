import { Address } from "../generated-sources/openapi/models/Address";

export function fixAddress(address: Address) {
	const cleanAddress = address && Object.keys(address).length > 0 ? address : undefined;

	if (cleanAddress === undefined) {
		return undefined;
	}

	if (!cleanAddress.city) {
		cleanAddress.city = "";
	}

	if (!cleanAddress.street) {
		cleanAddress.street = "";
	}

	if (!cleanAddress.country) {
		cleanAddress.country = "";
	}

	return cleanAddress;
}