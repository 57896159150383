/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Url.
 * @export
 * @interface AuthorizeResponseUrl
 */
export interface AuthorizeResponseUrl {
    /**
     * 
     * @type {string}
     * @memberof AuthorizeResponseUrl
     */
    contentType?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthorizeResponseUrl
     */
    method?: string;
    /**
     * 
     * @type {number}
     * @memberof AuthorizeResponseUrl
     */
    typeId?: number;
    /**
     * Datatype that defines a URL.
     * @type {string}
     * @memberof AuthorizeResponseUrl
     */
    value?: string;
}

export function AuthorizeResponseUrlFromJSON(json: any): AuthorizeResponseUrl {
    return AuthorizeResponseUrlFromJSONTyped(json, false);
}

export function AuthorizeResponseUrlFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthorizeResponseUrl {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'contentType': !exists(json, 'content_type') ? undefined : json['content_type'],
        'method': !exists(json, 'method') ? undefined : json['method'],
        'typeId': !exists(json, 'type_id') ? undefined : json['type_id'],
        'value': !exists(json, 'value') ? undefined : json['value'],
    };
}

export function AuthorizeResponseUrlToJSON(value?: AuthorizeResponseUrl | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'content_type': value.contentType,
        'method': value.method,
        'type_id': value.typeId,
        'value': value.value,
    };
}

