/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Amount,
    AmountFromJSON,
    AmountFromJSONTyped,
    AmountToJSON,
} from './Amount';
import {
    Email,
    EmailFromJSON,
    EmailFromJSONTyped,
    EmailToJSON,
} from './Email';
import {
    Mobile,
    MobileFromJSON,
    MobileFromJSONTyped,
    MobileToJSON,
} from './Mobile';
import {
    Order,
    OrderFromJSON,
    OrderFromJSONTyped,
    OrderToJSON,
} from './Order';

/**
 * 
 * @export
 * @interface Transaction
 */
export interface Transaction {
    /**
     * Shows if you have enabled auto-capture.
     * @type {boolean}
     * @memberof Transaction
     */
    autoCapture?: boolean;
    /**
     * A parameter which identifies if the API to be called is for saving account or saving card.
     * @type {number}
     * @memberof Transaction
     */
    euaId?: number;
    /**
     * The ID of a transaction.
     * @type {number}
     * @memberof Transaction
     */
    id?: number;
    /**
     * The language that Velocity uses as default when translating the payment pages.
     * @type {string}
     * @memberof Transaction
     */
    language?: string;
    /**
     * A parameter used in SDK to switch between production and staging environment.
     * @type {number}
     * @memberof Transaction
     */
    mode?: number;
    /**
     * The order number used for the transaction.
     * @type {string}
     * @memberof Transaction
     */
    orderNo?: string | null;
    /**
     * The type of application used for a transaction. 
For example: 40 indicates a mobile app purchase, and 30 indicates a web purchase.

     * @type {number}
     * @memberof Transaction
     */
    typeId?: number;
    /**
     * The ID of a session used for multiple payment transactions during one payment flow, for example, split payment or retry of a failed payment transaction.
     * @type {number}
     * @memberof Transaction
     */
    sessionId?: number;
    /**
     * 
     * @type {Amount}
     * @memberof Transaction
     */
    amount?: Amount;
    /**
     * 
     * @type {Order}
     * @memberof Transaction
     */
    orders?: Order;
    /**
     * 
     * @type {Mobile}
     * @memberof Transaction
     */
    mobile?: Mobile;
    /**
     * 
     * @type {Email}
     * @memberof Transaction
     */
    email?: Email;
    /**
     * The absolute URL to a merchant’s back office where Velocity sends the payment status. 
Velocity uses the default URL if you do not provide this parameter for the callback URL.

     * @type {string}
     * @memberof Transaction
     */
    callbackUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    acceptUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    cancelUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Transaction
     */
    customerRef?: string;
}

export function TransactionFromJSON(json: any): Transaction {
    return TransactionFromJSONTyped(json, false);
}

export function TransactionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Transaction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'autoCapture': !exists(json, 'auto_capture') ? undefined : json['auto_capture'],
        'euaId': !exists(json, 'eua_id') ? undefined : json['eua_id'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'language': !exists(json, 'language') ? undefined : json['language'],
        'mode': !exists(json, 'mode') ? undefined : json['mode'],
        'orderNo': !exists(json, 'order_no') ? undefined : json['order_no'],
        'typeId': !exists(json, 'type_id') ? undefined : json['type_id'],
        'sessionId': !exists(json, 'session_id') ? undefined : json['session_id'],
        'amount': !exists(json, 'amount') ? undefined : AmountFromJSON(json['amount']),
        'orders': !exists(json, 'orders') ? undefined : OrderFromJSON(json['orders']),
        'mobile': !exists(json, 'mobile') ? undefined : MobileFromJSON(json['mobile']),
        'email': !exists(json, 'email') ? undefined : EmailFromJSON(json['email']),
        'callbackUrl': !exists(json, 'callback_url') ? undefined : json['callback_url'],
        'acceptUrl': !exists(json, 'accept_url') ? undefined : json['accept_url'],
        'cancelUrl': !exists(json, 'cancel_url') ? undefined : json['cancel_url'],
        'customerRef': !exists(json, 'customer_ref') ? undefined : json['customer_ref'],
    };
}

export function TransactionToJSON(value?: Transaction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'auto_capture': value.autoCapture,
        'eua_id': value.euaId,
        'id': value.id,
        'language': value.language,
        'mode': value.mode,
        'order_no': value.orderNo,
        'type_id': value.typeId,
        'session_id': value.sessionId,
        'amount': AmountToJSON(value.amount),
        'orders': OrderToJSON(value.orders),
        'mobile': MobileToJSON(value.mobile),
        'email': EmailToJSON(value.email),
        'callback_url': value.callbackUrl,
        'accept_url': value.acceptUrl,
        'cancel_url': value.cancelUrl,
        'customer_ref': value.customerRef,
    };
}

