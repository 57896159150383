/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AirlineDataBillingSummary,
    AirlineDataBillingSummaryFromJSON,
    AirlineDataBillingSummaryFromJSONTyped,
    AirlineDataBillingSummaryToJSON,
} from './AirlineDataBillingSummary';
import {
    AirlineDataFees,
    AirlineDataFeesFromJSON,
    AirlineDataFeesFromJSONTyped,
    AirlineDataFeesToJSON,
} from './AirlineDataFees';
import {
    AirlineDataProfiles,
    AirlineDataProfilesFromJSON,
    AirlineDataProfilesFromJSONTyped,
    AirlineDataProfilesToJSON,
} from './AirlineDataProfiles';
import {
    AirlineDataTrips,
    AirlineDataTripsFromJSON,
    AirlineDataTripsFromJSONTyped,
    AirlineDataTripsToJSON,
} from './AirlineDataTrips';
import {
    Amount,
    AmountFromJSON,
    AmountFromJSONTyped,
    AmountToJSON,
} from './Amount';
import {
    FlightDetail,
    FlightDetailFromJSON,
    FlightDetailFromJSONTyped,
    FlightDetailToJSON,
} from './FlightDetail';
import {
    Passenger,
    PassengerFromJSON,
    PassengerFromJSONTyped,
    PassengerToJSON,
} from './Passenger';

/**
 * For airline merchants, the details of the trip and passengers.
 * @export
 * @interface AirlineData
 */
export interface AirlineData {
    /**
     * 
     * @type {Amount}
     * @memberof AirlineData
     */
    amount?: Amount;
    /**
     * 
     * @type {AirlineDataFees}
     * @memberof AirlineData
     */
    fees?: AirlineDataFees;
    /**
     * 
     * @type {Array<FlightDetail>}
     * @memberof AirlineData
     */
    flightDetail?: Array<FlightDetail>;
    /**
     * 
     * @type {Array<Passenger>}
     * @memberof AirlineData
     */
    passengerDetail?: Array<Passenger>;
    /**
     * 
     * @type {AirlineDataProfiles}
     * @memberof AirlineData
     */
    profiles?: AirlineDataProfiles;
    /**
     * 
     * @type {AirlineDataTrips}
     * @memberof AirlineData
     */
    trips?: AirlineDataTrips;
    /**
     * 
     * @type {AirlineDataBillingSummary}
     * @memberof AirlineData
     */
    billingSummary?: AirlineDataBillingSummary;
    /**
     * 
     * @type {string}
     * @memberof AirlineData
     */
    orderNo?: string;
    /**
     * 
     * @type {string}
     * @memberof AirlineData
     */
    orderType?: string;
}

export function AirlineDataFromJSON(json: any): AirlineData {
    return AirlineDataFromJSONTyped(json, false);
}

export function AirlineDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): AirlineData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount': !exists(json, 'amount') ? undefined : AmountFromJSON(json['amount']),
        'fees': !exists(json, 'fees') ? undefined : AirlineDataFeesFromJSON(json['fees']),
        'flightDetail': !exists(json, 'flight_detail') ? undefined : ((json['flight_detail'] as Array<any>).map(FlightDetailFromJSON)),
        'passengerDetail': !exists(json, 'passenger_detail') ? undefined : ((json['passenger_detail'] as Array<any>).map(PassengerFromJSON)),
        'profiles': !exists(json, 'profiles') ? undefined : AirlineDataProfilesFromJSON(json['profiles']),
        'trips': !exists(json, 'trips') ? undefined : AirlineDataTripsFromJSON(json['trips']),
        'billingSummary': !exists(json, 'billing_summary') ? undefined : AirlineDataBillingSummaryFromJSON(json['billing_summary']),
        'orderNo': !exists(json, 'order_no') ? undefined : json['order_no'],
        'orderType': !exists(json, 'order_type') ? undefined : json['order_type'],
    };
}

export function AirlineDataToJSON(value?: AirlineData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount': AmountToJSON(value.amount),
        'fees': AirlineDataFeesToJSON(value.fees),
        'flight_detail': value.flightDetail === undefined ? undefined : ((value.flightDetail as Array<any>).map(FlightDetailToJSON)),
        'passenger_detail': value.passengerDetail === undefined ? undefined : ((value.passengerDetail as Array<any>).map(PassengerToJSON)),
        'profiles': AirlineDataProfilesToJSON(value.profiles),
        'trips': AirlineDataTripsToJSON(value.trips),
        'billing_summary': AirlineDataBillingSummaryToJSON(value.billingSummary),
        'order_no': value.orderNo,
        'order_type': value.orderType,
    };
}

