/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AirlineDataFees,
    AirlineDataFeesFromJSON,
    AirlineDataFeesFromJSONTyped,
    AirlineDataFeesToJSON,
} from './AirlineDataFees';
import {
    Amount,
    AmountFromJSON,
    AmountFromJSONTyped,
    AmountToJSON,
} from './Amount';
import {
    LineItem,
    LineItemFromJSON,
    LineItemFromJSONTyped,
    LineItemToJSON,
} from './LineItem';
import {
    ShippingAddress,
    ShippingAddressFromJSON,
    ShippingAddressFromJSONTyped,
    ShippingAddressToJSON,
} from './ShippingAddress';

/**
 * The order details which contains event, location, date time, ticket type, cost, booking fee, quantity, and total price. 

> **Note**: This parameter is used only for airline data.

 * @export
 * @interface Order
 */
export interface Order {
    /**
     * 
     * @type {ShippingAddress}
     * @memberof Order
     */
    shippingAddress?: ShippingAddress;
    /**
     * 
     * @type {Amount}
     * @memberof Order
     */
    amount?: Amount;
    /**
     * 
     * @type {AirlineDataFees}
     * @memberof Order
     */
    fees?: AirlineDataFees;
    /**
     * 
     * @type {Array<LineItem>}
     * @memberof Order
     */
    lineItem: Array<LineItem>;
}

export function OrderFromJSON(json: any): Order {
    return OrderFromJSONTyped(json, false);
}

export function OrderFromJSONTyped(json: any, ignoreDiscriminator: boolean): Order {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shippingAddress': !exists(json, 'shipping_address') ? undefined : ShippingAddressFromJSON(json['shipping_address']),
        'amount': !exists(json, 'amount') ? undefined : AmountFromJSON(json['amount']),
        'fees': !exists(json, 'fees') ? undefined : AirlineDataFeesFromJSON(json['fees']),
        'lineItem': ((json['line_item'] as Array<any>).map(LineItemFromJSON)),
    };
}

export function OrderToJSON(value?: Order | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shipping_address': ShippingAddressToJSON(value.shippingAddress),
        'amount': AmountToJSON(value.amount),
        'fees': AirlineDataFeesToJSON(value.fees),
        'line_item': ((value.lineItem as Array<any>).map(LineItemToJSON)),
    };
}

