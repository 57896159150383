/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CardPrefix,
    CardPrefixFromJSON,
    CardPrefixFromJSONTyped,
    CardPrefixToJSON,
} from './CardPrefix';
import {
    InitializeResponseCardUrl,
    InitializeResponseCardUrlFromJSON,
    InitializeResponseCardUrlFromJSONTyped,
    InitializeResponseCardUrlToJSON,
} from './InitializeResponseCardUrl';
import {
    PaymentMethod,
    PaymentMethodFromJSON,
    PaymentMethodFromJSONTyped,
    PaymentMethodToJSON,
} from './PaymentMethod';

/**
 * 
 * @export
 * @interface InitializeResponseCard
 */
export interface InitializeResponseCard {
    /**
     * The length of CVC number on a card.
     * @type {number}
     * @memberof InitializeResponseCard
     */
    cvcLength?: number;
    /**
     * 
     * @type {boolean}
     * @memberof InitializeResponseCard
     */
    cvcMandatory?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InitializeResponseCard
     */
    dcc?: boolean;
    /**
     * Shows if the card was enabled.
     * @type {boolean}
     * @memberof InitializeResponseCard
     */
    enabled?: boolean;
    /**
     * - The ID of the card scheme for a new card payment. 
- The ID of the payment method for a wallet or APM. 
- The Velocity stored card ID for a stored card payment.

     * @type {number}
     * @memberof InitializeResponseCard
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof InitializeResponseCard
     */
    installment?: number;
    /**
     * The minimum length of a card number.
     * @type {number}
     * @memberof InitializeResponseCard
     */
    minLength?: number;
    /**
     * The maximum length of a card number.
     * @type {number}
     * @memberof InitializeResponseCard
     */
    maxLength?: number;
    /**
     * The type of payment – Card, Wallet, APM, and so on.
     * @type {number}
     * @memberof InitializeResponseCard
     */
    paymentType?: number;
    /**
     * 
     * @type {boolean}
     * @memberof InitializeResponseCard
     */
    preferred?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InitializeResponseCard
     */
    presentmentCurrency?: boolean;
    /**
     * The type of processor used to process payment.
     * @type {number}
     * @memberof InitializeResponseCard
     */
    processorType?: number;
    /**
     * The ID of a PSP used for a card.
     * @type {number}
     * @memberof InitializeResponseCard
     */
    pspId?: number;
    /**
     * A Boolean value to indicate if split payment is allowed.
     * @type {boolean}
     * @memberof InitializeResponseCard
     */
    splittable?: boolean;
    /**
     * The state of a card, for example, enabled, disabled by PSP, and so on.
     * @type {number}
     * @memberof InitializeResponseCard
     */
    stateId?: number;
    /**
     * The type of payment method. It can be one of the following:

- The ID of the card scheme for a new card payment. For example, 7 for Master Card and 8 for VISA.
- ID of the payment method for a wallet or APM.

     * @type {number}
     * @memberof InitializeResponseCard
     */
    typeId?: number;
    /**
     * The name of a card type. It can be your card scheme name.
     * @type {string}
     * @memberof InitializeResponseCard
     */
    name?: string;
    /**
     * 
     * @type {Array<CardPrefix>}
     * @memberof InitializeResponseCard
     */
    prefixes?: Array<CardPrefix>;
    /**
     * 
     * @type {InitializeResponseCardUrl}
     * @memberof InitializeResponseCard
     */
    url?: InitializeResponseCardUrl;
    /**
     * Hidden fields contain name value pairs inorder to prepare the request that will be sent to the <url>.

     * @type {{ [key: string]: string; }}
     * @memberof InitializeResponseCard
     */
    hiddenFields?: { [key: string]: string; };
    /**
     * 
     * @type {Array<PaymentMethod>}
     * @memberof InitializeResponseCard
     */
    activePaymentMethods?: Array<PaymentMethod>;
    /**
     * 
     * @type {Array<number>}
     * @memberof InitializeResponseCard
     */
    settlementCurrencies?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof InitializeResponseCard
     */
    head?: string;
    /**
     * 
     * @type {string}
     * @memberof InitializeResponseCard
     */
    body?: string;
    /**
     * 
     * @type {string}
     * @memberof InitializeResponseCard
     */
    authToken?: string;
}

export function InitializeResponseCardFromJSON(json: any): InitializeResponseCard {
    return InitializeResponseCardFromJSONTyped(json, false);
}

export function InitializeResponseCardFromJSONTyped(json: any, ignoreDiscriminator: boolean): InitializeResponseCard {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cvcLength': !exists(json, 'cvc_length') ? undefined : json['cvc_length'],
        'cvcMandatory': !exists(json, 'cvc_mandatory') ? undefined : json['cvc_mandatory'],
        'dcc': !exists(json, 'dcc') ? undefined : json['dcc'],
        'enabled': !exists(json, 'enabled') ? undefined : json['enabled'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'installment': !exists(json, 'installment') ? undefined : json['installment'],
        'minLength': !exists(json, 'min_length') ? undefined : json['min_length'],
        'maxLength': !exists(json, 'max_length') ? undefined : json['max_length'],
        'paymentType': !exists(json, 'payment_type') ? undefined : json['payment_type'],
        'preferred': !exists(json, 'preferred') ? undefined : json['preferred'],
        'presentmentCurrency': !exists(json, 'presentment_currency') ? undefined : json['presentment_currency'],
        'processorType': !exists(json, 'processor_type') ? undefined : json['processor_type'],
        'pspId': !exists(json, 'psp_id') ? undefined : json['psp_id'],
        'splittable': !exists(json, 'splittable') ? undefined : json['splittable'],
        'stateId': !exists(json, 'state_id') ? undefined : json['state_id'],
        'typeId': !exists(json, 'type_id') ? undefined : json['type_id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'prefixes': !exists(json, 'prefixes') ? undefined : ((json['prefixes'] as Array<any>).map(CardPrefixFromJSON)),
        'url': !exists(json, 'url') ? undefined : InitializeResponseCardUrlFromJSON(json['url']),
        'hiddenFields': !exists(json, 'hidden_fields') ? undefined : json['hidden_fields'],
        'activePaymentMethods': !exists(json, 'active_payment_methods') ? undefined : ((json['active_payment_methods'] as Array<any>).map(PaymentMethodFromJSON)),
        'settlementCurrencies': !exists(json, 'settlement_currencies') ? undefined : json['settlement_currencies'],
        'head': !exists(json, 'head') ? undefined : json['head'],
        'body': !exists(json, 'body') ? undefined : json['body'],
        'authToken': !exists(json, 'auth_token') ? undefined : json['auth_token'],
    };
}

export function InitializeResponseCardToJSON(value?: InitializeResponseCard | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cvc_length': value.cvcLength,
        'cvc_mandatory': value.cvcMandatory,
        'dcc': value.dcc,
        'enabled': value.enabled,
        'id': value.id,
        'installment': value.installment,
        'min_length': value.minLength,
        'max_length': value.maxLength,
        'payment_type': value.paymentType,
        'preferred': value.preferred,
        'presentment_currency': value.presentmentCurrency,
        'processor_type': value.processorType,
        'psp_id': value.pspId,
        'splittable': value.splittable,
        'state_id': value.stateId,
        'type_id': value.typeId,
        'name': value.name,
        'prefixes': value.prefixes === undefined ? undefined : ((value.prefixes as Array<any>).map(CardPrefixToJSON)),
        'url': InitializeResponseCardUrlToJSON(value.url),
        'hidden_fields': value.hiddenFields,
        'active_payment_methods': value.activePaymentMethods === undefined ? undefined : ((value.activePaymentMethods as Array<any>).map(PaymentMethodToJSON)),
        'settlement_currencies': value.settlementCurrencies,
        'head': value.head,
        'body': value.body,
        'auth_token': value.authToken,
    };
}

