/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AdditionalData,
    AdditionalDataFromJSON,
    AdditionalDataFromJSONTyped,
    AdditionalDataToJSON,
} from './AdditionalData';
import {
    Airport,
    AirportFromJSON,
    AirportFromJSONTyped,
    AirportToJSON,
} from './Airport';
import {
    Transportation,
    TransportationFromJSON,
    TransportationFromJSONTyped,
    TransportationToJSON,
} from './Transportation';
import {
    TripServiceLevel,
    TripServiceLevelFromJSON,
    TripServiceLevelFromJSONTyped,
    TripServiceLevelToJSON,
} from './TripServiceLevel';
import {
    TripTrafficInfo,
    TripTrafficInfoFromJSON,
    TripTrafficInfoFromJSONTyped,
    TripTrafficInfoToJSON,
} from './TripTrafficInfo';

/**
 * 
 * @export
 * @interface Trip
 */
export interface Trip {
    /**
     * 
     * @type {string}
     * @memberof Trip
     */
    seq: string;
    /**
     * 
     * @type {string}
     * @memberof Trip
     */
    tag: string;
    /**
     * 
     * @type {TripServiceLevel}
     * @memberof Trip
     */
    serviceLevel: TripServiceLevel;
    /**
     * 
     * @type {Airport}
     * @memberof Trip
     */
    origin?: Airport;
    /**
     * 
     * @type {Airport}
     * @memberof Trip
     */
    destination?: Airport;
    /**
     * 
     * @type {string}
     * @memberof Trip
     */
    departureTime: string;
    /**
     * 
     * @type {string}
     * @memberof Trip
     */
    arrivalTime: string;
    /**
     * 
     * @type {string}
     * @memberof Trip
     */
    bookingClass: string;
    /**
     * 
     * @type {Transportation}
     * @memberof Trip
     */
    transportation: Transportation;
    /**
     * 
     * @type {TripTrafficInfo}
     * @memberof Trip
     */
    trafficInfo?: TripTrafficInfo;
    /**
     * 
     * @type {AdditionalData}
     * @memberof Trip
     */
    additionalData?: AdditionalData;
}

export function TripFromJSON(json: any): Trip {
    return TripFromJSONTyped(json, false);
}

export function TripFromJSONTyped(json: any, ignoreDiscriminator: boolean): Trip {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'seq': json['seq'],
        'tag': json['tag'],
        'serviceLevel': TripServiceLevelFromJSON(json['service_level']),
        'origin': !exists(json, 'origin') ? undefined : AirportFromJSON(json['origin']),
        'destination': !exists(json, 'destination') ? undefined : AirportFromJSON(json['destination']),
        'departureTime': json['departure_time'],
        'arrivalTime': json['arrival_time'],
        'bookingClass': json['booking_class'],
        'transportation': TransportationFromJSON(json['transportation']),
        'trafficInfo': !exists(json, 'traffic_info') ? undefined : TripTrafficInfoFromJSON(json['traffic_info']),
        'additionalData': !exists(json, 'additional_data') ? undefined : AdditionalDataFromJSON(json['additional_data']),
    };
}

export function TripToJSON(value?: Trip | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'seq': value.seq,
        'tag': value.tag,
        'service_level': TripServiceLevelToJSON(value.serviceLevel),
        'origin': AirportToJSON(value.origin),
        'destination': AirportToJSON(value.destination),
        'departure_time': value.departureTime,
        'arrival_time': value.arrivalTime,
        'booking_class': value.bookingClass,
        'transportation': TransportationToJSON(value.transportation),
        'traffic_info': TripTrafficInfoToJSON(value.trafficInfo),
        'additional_data': AdditionalDataToJSON(value.additionalData),
    };
}

