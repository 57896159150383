/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TransportationCarriers,
    TransportationCarriersFromJSON,
    TransportationCarriersFromJSONTyped,
    TransportationCarriersToJSON,
} from './TransportationCarriers';
import {
    Vehicle,
    VehicleFromJSON,
    VehicleFromJSONTyped,
    VehicleToJSON,
} from './Vehicle';

/**
 * 
 * @export
 * @interface Transportation
 */
export interface Transportation {
    /**
     * 
     * @type {string}
     * @memberof Transportation
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Transportation
     */
    code: string;
    /**
     * 
     * @type {Vehicle}
     * @memberof Transportation
     */
    vehicle?: Vehicle;
    /**
     * 
     * @type {TransportationCarriers}
     * @memberof Transportation
     */
    carriers: TransportationCarriers;
}

export function TransportationFromJSON(json: any): Transportation {
    return TransportationFromJSONTyped(json, false);
}

export function TransportationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Transportation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'code': json['code'],
        'vehicle': !exists(json, 'vehicle') ? undefined : VehicleFromJSON(json['vehicle']),
        'carriers': TransportationCarriersFromJSON(json['carriers']),
    };
}

export function TransportationToJSON(value?: Transportation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'code': value.code,
        'vehicle': VehicleToJSON(value.vehicle),
        'carriers': TransportationCarriersToJSON(value.carriers),
    };
}

