import { getSiblingFrames } from "./get-sibling-frames";

export function getFieldValue(fieldName: string): string {
	const frames: Window[] = getSiblingFrames();
	let value: string | undefined = undefined;

	frames.forEach(f => {
		const input: HTMLInputElement | null = f.document.querySelector(`input[name=${fieldName}]`);

		if (input !== null) {
			value = input.value
		}
	})

	if (value === undefined) {
		throw(new Error(`Could not value for field "${fieldName}"`))
	}

	return value as string;
}

export function getFieldValueUndefined(fieldName: string): string | undefined {
	const frames: Window[] = getSiblingFrames();
	let value: string | undefined = undefined;

	frames.forEach(f => {
		const input: HTMLInputElement | null = f.document.querySelector(`input[name=${fieldName}]`);

		if (input !== null) {
			value = input.value
		}
	})

	if (value === undefined) {
		return undefined
	}

	return value as string;
}
