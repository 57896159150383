/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Message,
    MessageFromJSON,
    MessageFromJSONTyped,
    MessageToJSON,
} from './Message';
import {
    PspInfoUrl,
    PspInfoUrlFromJSON,
    PspInfoUrlFromJSONTyped,
    PspInfoUrlToJSON,
} from './PspInfoUrl';
import {
    SessionData,
    SessionDataFromJSON,
    SessionDataFromJSONTyped,
    SessionDataToJSON,
} from './SessionData';

/**
 * 
 * @export
 * @interface PspInfo
 */
export interface PspInfo {
    /**
     * Internal id of the PSP in Velocity
     * @type {number}
     * @memberof PspInfo
     */
    id: number;
    /**
     * Used for merchant-account for third-party wallet on device.
     * @type {string}
     * @memberof PspInfo
     */
    merchantAccount: string;
    /**
     * 
     * @type {number}
     * @memberof PspInfo
     */
    type: number;
    /**
     * 
     * @type {PspInfoUrl}
     * @memberof PspInfo
     */
    url: PspInfoUrl;
    /**
     * The creditcard number
     * @type {string}
     * @memberof PspInfo
     */
    cardNumber?: string;
    /**
     * The creditcards expiration month.
     * @type {string}
     * @memberof PspInfo
     */
    expiryMonth?: string;
    /**
     * The creditcards expiration year.
     * @type {string}
     * @memberof PspInfo
     */
    expiryYear?: string;
    /**
     * The creditcards cvc.
     * @type {string}
     * @memberof PspInfo
     */
    cvc?: string;
    /**
     * 
     * @type {string}
     * @memberof PspInfo
     */
    clientInfo?: string;
    /**
     * The absolute URL where Velocity directs customers if the payment transaction fails. The URL is used for redirecting back to either HPP or Merchant’s page after payment failure.
     * @type {string}
     * @memberof PspInfo
     */
    declineUrl?: string | null;
    /**
     * The absolute URL where Velocity directs customers if they cancel a payment transaction midway.
     * @type {string}
     * @memberof PspInfo
     */
    cancelUrl?: string | null;
    /**
     * The URL where Velocity directs a customer after successfully completing a payment transaction.
     * @type {string}
     * @memberof PspInfo
     */
    acceptUrl?: string | null;
    /**
     * The URL passed to the third party to return the status of the verification done with the parsed challenge form.
     * @type {string}
     * @memberof PspInfo
     */
    returnUrl?: string | null;
    /**
     * Shows if you have stored card.
     * @type {boolean}
     * @memberof PspInfo
     */
    storedCard?: boolean;
    /**
     * The name of a customer.
     * @type {string}
     * @memberof PspInfo
     */
    name?: string;
    /**
     * Auth token used to perform a Single Sign-On (SSO) using third party user management system.
     * @type {string}
     * @memberof PspInfo
     */
    authToken?: string;
    /**
     * 
     * @type {Message}
     * @memberof PspInfo
     */
    message?: Message;
    /**
     * Hidden fields contain name value pairs inorder to prepare the request that will be sent to the <url>.

     * @type {{ [key: string]: string; }}
     * @memberof PspInfo
     */
    hiddenFields?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof PspInfo
     */
    inputFields?: { [key: string]: string; };
    /**
     * 
     * @type {SessionData}
     * @memberof PspInfo
     */
    sessionData?: SessionData;
    /**
     * 
     * @type {object}
     * @memberof PspInfo
     */
    head?: object;
}

export function PspInfoFromJSON(json: any): PspInfo {
    return PspInfoFromJSONTyped(json, false);
}

export function PspInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PspInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'merchantAccount': json['merchant_account'],
        'type': json['type'],
        'url': PspInfoUrlFromJSON(json['url']),
        'cardNumber': !exists(json, 'card_number') ? undefined : json['card_number'],
        'expiryMonth': !exists(json, 'expiry_month') ? undefined : json['expiry_month'],
        'expiryYear': !exists(json, 'expiry_year') ? undefined : json['expiry_year'],
        'cvc': !exists(json, 'cvc') ? undefined : json['cvc'],
        'clientInfo': !exists(json, 'client_info') ? undefined : json['client_info'],
        'declineUrl': !exists(json, 'decline_url') ? undefined : json['decline_url'],
        'cancelUrl': !exists(json, 'cancel_url') ? undefined : json['cancel_url'],
        'acceptUrl': !exists(json, 'accept_url') ? undefined : json['accept_url'],
        'returnUrl': !exists(json, 'return_url') ? undefined : json['return_url'],
        'storedCard': !exists(json, 'stored_card') ? undefined : json['stored_card'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'authToken': !exists(json, 'auth_token') ? undefined : json['auth_token'],
        'message': !exists(json, 'message') ? undefined : MessageFromJSON(json['message']),
        'hiddenFields': !exists(json, 'hidden_fields') ? undefined : json['hidden_fields'],
        'inputFields': !exists(json, 'input_fields') ? undefined : json['input_fields'],
        'sessionData': !exists(json, 'session_data') ? undefined : SessionDataFromJSON(json['session_data']),
        'head': !exists(json, 'head') ? undefined : json['head'],
    };
}

export function PspInfoToJSON(value?: PspInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'merchant_account': value.merchantAccount,
        'type': value.type,
        'url': PspInfoUrlToJSON(value.url),
        'card_number': value.cardNumber,
        'expiry_month': value.expiryMonth,
        'expiry_year': value.expiryYear,
        'cvc': value.cvc,
        'client_info': value.clientInfo,
        'decline_url': value.declineUrl,
        'cancel_url': value.cancelUrl,
        'accept_url': value.acceptUrl,
        'return_url': value.returnUrl,
        'stored_card': value.storedCard,
        'name': value.name,
        'auth_token': value.authToken,
        'message': MessageToJSON(value.message),
        'hidden_fields': value.hiddenFields,
        'input_fields': value.inputFields,
        'session_data': SessionDataToJSON(value.sessionData),
        'head': value.head,
    };
}

