/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Email,
    EmailFromJSON,
    EmailFromJSONTyped,
    EmailToJSON,
} from './Email';
import {
    Mobile,
    MobileFromJSON,
    MobileFromJSONTyped,
    MobileToJSON,
} from './Mobile';

/**
 * 
 * @export
 * @interface ContactDetails
 */
export interface ContactDetails {
    /**
     * 
     * @type {Mobile}
     * @memberof ContactDetails
     */
    mobile?: Mobile;
    /**
     * 
     * @type {Email}
     * @memberof ContactDetails
     */
    email?: Email;
}

export function ContactDetailsFromJSON(json: any): ContactDetails {
    return ContactDetailsFromJSONTyped(json, false);
}

export function ContactDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactDetails {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mobile': !exists(json, 'mobile') ? undefined : MobileFromJSON(json['mobile']),
        'email': !exists(json, 'email') ? undefined : EmailFromJSON(json['email']),
    };
}

export function ContactDetailsToJSON(value?: ContactDetails | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mobile': MobileToJSON(value.mobile),
        'email': EmailToJSON(value.email),
    };
}

