/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StoredCard
 */
export interface StoredCard {
    /**
     * The Velocity ID for a stored card.
     * @type {number}
     * @memberof StoredCard
     */
    id?: number;
    /**
     * Shows if a customer has set a card as a preferred one.
     * @type {boolean}
     * @memberof StoredCard
     */
    preferred?: boolean;
    /**
     * The ID of a PSP used for a card.
     * @type {number}
     * @memberof StoredCard
     */
    pspId?: number;
    /**
     * 
     * @type {number}
     * @memberof StoredCard
     */
    typeId?: number;
    /**
     * Indicates if currency conversion is available.
     * @type {boolean}
     * @memberof StoredCard
     */
    dcc?: boolean;
    /**
     * The name a customer gives to a card.
     * @type {string}
     * @memberof StoredCard
     */
    name?: string;
    /**
     * The masked card number.
     * @type {string}
     * @memberof StoredCard
     */
    cardNumberMask?: string;
    /**
     * The expiry date of a card.
     * @type {string}
     * @memberof StoredCard
     */
    expiry?: string;
}

export function StoredCardFromJSON(json: any): StoredCard {
    return StoredCardFromJSONTyped(json, false);
}

export function StoredCardFromJSONTyped(json: any, ignoreDiscriminator: boolean): StoredCard {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'preferred': !exists(json, 'preferred') ? undefined : json['preferred'],
        'pspId': !exists(json, 'psp_id') ? undefined : json['psp_id'],
        'typeId': !exists(json, 'type_id') ? undefined : json['type_id'],
        'dcc': !exists(json, 'dcc') ? undefined : json['dcc'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'cardNumberMask': !exists(json, 'card_number_mask') ? undefined : json['card_number_mask'],
        'expiry': !exists(json, 'expiry') ? undefined : json['expiry'],
    };
}

export function StoredCardToJSON(value?: StoredCard | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'preferred': value.preferred,
        'psp_id': value.pspId,
        'type_id': value.typeId,
        'dcc': value.dcc,
        'name': value.name,
        'card_number_mask': value.cardNumberMask,
        'expiry': value.expiry,
    };
}

