/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InitializeResponseCard,
    InitializeResponseCardFromJSON,
    InitializeResponseCardFromJSONTyped,
    InitializeResponseCardToJSON,
} from './InitializeResponseCard';

/**
 * 
 * @export
 * @interface InitializeResponseCards
 */
export interface InitializeResponseCards {
    /**
     * 
     * @type {Array<InitializeResponseCard>}
     * @memberof InitializeResponseCards
     */
    card?: Array<InitializeResponseCard>;
}

export function InitializeResponseCardsFromJSON(json: any): InitializeResponseCards {
    return InitializeResponseCardsFromJSONTyped(json, false);
}

export function InitializeResponseCardsFromJSONTyped(json: any, ignoreDiscriminator: boolean): InitializeResponseCards {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'card': !exists(json, 'card') ? undefined : ((json['card'] as Array<any>).map(InitializeResponseCardFromJSON)),
    };
}

export function InitializeResponseCardsToJSON(value?: InitializeResponseCards | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'card': value.card === undefined ? undefined : ((value.card as Array<any>).map(InitializeResponseCardToJSON)),
    };
}

