import { CardMeta } from "../../../types/cpd-init";

const frontImage = new URL('./cvv_hint_front.svg', import.meta.url);
const backImage = new URL('./cvv_hint_back.svg', import.meta.url);

export function getCVVHintImage(cardType: CardMeta): string {
  if (cardType.id === 1) { // American express (amex)
    return frontImage.href;
  }

  return backImage.href;
}
