/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * These are serialized/url-escaped relay parameters that would be returned back for any non-standard details, but may be required for completion of the transaction.
 * @export
 * @interface InitializeTransactionCustomVariables
 */
export interface InitializeTransactionCustomVariables {
    /**
     * 
     * @type {Array<object>}
     * @memberof InitializeTransactionCustomVariables
     */
    any?: Array<object>;
}

export function InitializeTransactionCustomVariablesFromJSON(json: any): InitializeTransactionCustomVariables {
    return InitializeTransactionCustomVariablesFromJSONTyped(json, false);
}

export function InitializeTransactionCustomVariablesFromJSONTyped(json: any, ignoreDiscriminator: boolean): InitializeTransactionCustomVariables {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'any': !exists(json, 'any') ? undefined : json['any'],
    };
}

export function InitializeTransactionCustomVariablesToJSON(value?: InitializeTransactionCustomVariables | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'any': value.any,
    };
}

