/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Account,
    AccountFromJSON,
    AccountFromJSONTyped,
    AccountToJSON,
} from './Account';

/**
 * The list of all the storefronts enabled for the merchant.
 * @export
 * @interface ClientConfigAccounts
 */
export interface ClientConfigAccounts {
    /**
     * 
     * @type {Array<Account>}
     * @memberof ClientConfigAccounts
     */
    account?: Array<Account>;
}

export function ClientConfigAccountsFromJSON(json: any): ClientConfigAccounts {
    return ClientConfigAccountsFromJSONTyped(json, false);
}

export function ClientConfigAccountsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientConfigAccounts {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'account': !exists(json, 'account') ? undefined : ((json['account'] as Array<any>).map(AccountFromJSON)),
    };
}

export function ClientConfigAccountsToJSON(value?: ClientConfigAccounts | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'account': value.account === undefined ? undefined : ((value.account as Array<any>).map(AccountToJSON)),
    };
}

