/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaymentMethod
 */
export interface PaymentMethod {
    /**
     * The Logo name for the available card type.
     * @type {string}
     * @memberof PaymentMethod
     */
    logoName?: string;
    /**
     * The Logo URL for the available card type.
     * @type {string}
     * @memberof PaymentMethod
     */
    logoUrl?: string | null;
    /**
     * The UI display name for the available card type.
     * @type {string}
     * @memberof PaymentMethod
     */
    displayName?: string;
    /**
     * The issuing bank type for the available card type.
     * @type {string}
     * @memberof PaymentMethod
     */
    issuingBank?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentMethod
     */
    displayOrder?: number;
}

export function PaymentMethodFromJSON(json: any): PaymentMethod {
    return PaymentMethodFromJSONTyped(json, false);
}

export function PaymentMethodFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentMethod {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'logoName': !exists(json, 'logo_name') ? undefined : json['logo_name'],
        'logoUrl': !exists(json, 'logo_url') ? undefined : json['logo_url'],
        'displayName': !exists(json, 'display_name') ? undefined : json['display_name'],
        'issuingBank': !exists(json, 'issuing_bank') ? undefined : json['issuing_bank'],
        'displayOrder': !exists(json, 'display_order') ? undefined : json['display_order'],
    };
}

export function PaymentMethodToJSON(value?: PaymentMethod | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'logo_name': value.logoName,
        'logo_url': value.logoUrl,
        'display_name': value.displayName,
        'issuing_bank': value.issuingBank,
        'display_order': value.displayOrder,
    };
}

