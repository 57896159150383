/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TripTrafficInfo
 */
export interface TripTrafficInfo {
    /**
     * 
     * @type {string}
     * @memberof TripTrafficInfo
     */
    cancelled?: string;
}

export function TripTrafficInfoFromJSON(json: any): TripTrafficInfo {
    return TripTrafficInfoFromJSONTyped(json, false);
}

export function TripTrafficInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TripTrafficInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cancelled': !exists(json, 'cancelled') ? undefined : json['cancelled'],
    };
}

export function TripTrafficInfoToJSON(value?: TripTrafficInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cancelled': value.cancelled,
    };
}

