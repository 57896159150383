/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InitializeResponseCardUrl
 */
export interface InitializeResponseCardUrl {
    /**
     * 
     * @type {string}
     * @memberof InitializeResponseCardUrl
     */
    method?: string;
}

export function InitializeResponseCardUrlFromJSON(json: any): InitializeResponseCardUrl {
    return InitializeResponseCardUrlFromJSONTyped(json, false);
}

export function InitializeResponseCardUrlFromJSONTyped(json: any, ignoreDiscriminator: boolean): InitializeResponseCardUrl {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'method': !exists(json, 'method') ? undefined : json['method'],
    };
}

export function InitializeResponseCardUrlToJSON(value?: InitializeResponseCardUrl | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'method': value.method,
    };
}

