/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SessionData
 */
export interface SessionData {
    /**
     * 
     * @type {string}
     * @memberof SessionData
     */
    epochTimestamp?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionData
     */
    expiresAt?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionData
     */
    merchantSessionIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionData
     */
    nonce?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionData
     */
    merchantIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionData
     */
    domainName?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionData
     */
    displayName?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionData
     */
    signature?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionData
     */
    operationalAnalyticsIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionData
     */
    retries?: string;
}

export function SessionDataFromJSON(json: any): SessionData {
    return SessionDataFromJSONTyped(json, false);
}

export function SessionDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): SessionData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'epochTimestamp': !exists(json, 'epochTimestamp') ? undefined : json['epochTimestamp'],
        'expiresAt': !exists(json, 'expiresAt') ? undefined : json['expiresAt'],
        'merchantSessionIdentifier': !exists(json, 'merchantSessionIdentifier') ? undefined : json['merchantSessionIdentifier'],
        'nonce': !exists(json, 'nonce') ? undefined : json['nonce'],
        'merchantIdentifier': !exists(json, 'merchantIdentifier') ? undefined : json['merchantIdentifier'],
        'domainName': !exists(json, 'domainName') ? undefined : json['domainName'],
        'displayName': !exists(json, 'displayName') ? undefined : json['displayName'],
        'signature': !exists(json, 'signature') ? undefined : json['signature'],
        'operationalAnalyticsIdentifier': !exists(json, 'operationalAnalyticsIdentifier') ? undefined : json['operationalAnalyticsIdentifier'],
        'retries': !exists(json, 'retries') ? undefined : json['retries'],
    };
}

export function SessionDataToJSON(value?: SessionData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'epochTimestamp': value.epochTimestamp,
        'expiresAt': value.expiresAt,
        'merchantSessionIdentifier': value.merchantSessionIdentifier,
        'nonce': value.nonce,
        'merchantIdentifier': value.merchantIdentifier,
        'domainName': value.domainName,
        'displayName': value.displayName,
        'signature': value.signature,
        'operationalAnalyticsIdentifier': value.operationalAnalyticsIdentifier,
        'retries': value.retries,
    };
}

