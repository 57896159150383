/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetStatusResponseAmount,
    GetStatusResponseAmountFromJSON,
    GetStatusResponseAmountFromJSONTyped,
    GetStatusResponseAmountToJSON,
} from './GetStatusResponseAmount';
import {
    GetStatusResponseTransactionMessages,
    GetStatusResponseTransactionMessagesFromJSON,
    GetStatusResponseTransactionMessagesFromJSONTyped,
    GetStatusResponseTransactionMessagesToJSON,
} from './GetStatusResponseTransactionMessages';

/**
 * 
 * @export
 * @interface GetStatusResponseTransaction
 */
export interface GetStatusResponseTransaction {
    /**
     * The velocity internal transaction ID.
     * @type {number}
     * @memberof GetStatusResponseTransaction
     */
    id: number;
    /**
     * An ID out of the messages which holds the current status for the transaction.
     * @type {number}
     * @memberof GetStatusResponseTransaction
     */
    currentState?: number;
    /**
     * 
     * @type {number}
     * @memberof GetStatusResponseTransaction
     */
    typeId?: number;
    /**
     * The order ID that a merchant generates. 
It is an alphanumeric string.

> **Note**: Some Payment Providers have strict guidelines for the order-no. The recommended pattern is "[a-zA-Z0-9._-]

     * @type {string}
     * @memberof GetStatusResponseTransaction
     */
    orderNo?: string;
    /**
     * 
     * @type {GetStatusResponseAmount}
     * @memberof GetStatusResponseTransaction
     */
    captured?: GetStatusResponseAmount;
    /**
     * 
     * @type {GetStatusResponseAmount}
     * @memberof GetStatusResponseTransaction
     */
    amount?: GetStatusResponseAmount;
    /**
     * The amount pending for split payment.
     * @type {number}
     * @memberof GetStatusResponseTransaction
     */
    pendingAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof GetStatusResponseTransaction
     */
    paymentStatus?: string;
    /**
     * 
     * @type {GetStatusResponseTransactionMessages}
     * @memberof GetStatusResponseTransaction
     */
    messages?: GetStatusResponseTransactionMessages;
}

export function GetStatusResponseTransactionFromJSON(json: any): GetStatusResponseTransaction {
    return GetStatusResponseTransactionFromJSONTyped(json, false);
}

export function GetStatusResponseTransactionFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetStatusResponseTransaction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'currentState': !exists(json, 'current_state') ? undefined : json['current_state'],
        'typeId': !exists(json, 'type_id') ? undefined : json['type_id'],
        'orderNo': !exists(json, 'order_no') ? undefined : json['order_no'],
        'captured': !exists(json, 'captured') ? undefined : GetStatusResponseAmountFromJSON(json['captured']),
        'amount': !exists(json, 'amount') ? undefined : GetStatusResponseAmountFromJSON(json['amount']),
        'pendingAmount': !exists(json, 'pending_amount') ? undefined : json['pending_amount'],
        'paymentStatus': !exists(json, 'payment_status') ? undefined : json['payment_status'],
        'messages': !exists(json, 'messages') ? undefined : GetStatusResponseTransactionMessagesFromJSON(json['messages']),
    };
}

export function GetStatusResponseTransactionToJSON(value?: GetStatusResponseTransaction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'current_state': value.currentState,
        'type_id': value.typeId,
        'order_no': value.orderNo,
        'captured': GetStatusResponseAmountToJSON(value.captured),
        'amount': GetStatusResponseAmountToJSON(value.amount),
        'pending_amount': value.pendingAmount,
        'payment_status': value.paymentStatus,
        'messages': GetStatusResponseTransactionMessagesToJSON(value.messages),
    };
}

