/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AdditionalData,
    AdditionalDataFromJSON,
    AdditionalDataFromJSONTyped,
    AdditionalDataToJSON,
} from './AdditionalData';
import {
    Amount,
    AmountFromJSON,
    AmountFromJSONTyped,
    AmountToJSON,
} from './Amount';
import {
    ContactDetails,
    ContactDetailsFromJSON,
    ContactDetailsFromJSONTyped,
    ContactDetailsToJSON,
} from './ContactDetails';

/**
 * The passenger details for a ticket.
 * @export
 * @interface Passenger
 */
export interface Passenger {
    /**
     * 
     * @type {number}
     * @memberof Passenger
     */
    seq?: number;
    /**
     * 
     * @type {string}
     * @memberof Passenger
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Passenger
     */
    profileId?: string;
    /**
     * Title of the passenger. E.g. Mr, Ms, Mrs.
     * @type {string}
     * @memberof Passenger
     */
    title?: string;
    /**
     * Passenger's first name.
     * @type {string}
     * @memberof Passenger
     */
    firstName: string;
    /**
     * Passenger's last name.
     * @type {string}
     * @memberof Passenger
     */
    lastName: string;
    /**
     * Type of passenger. E.g. CHILD, ADULT, SENIOR, etc.
     * @type {string}
     * @memberof Passenger
     */
    type?: string;
    /**
     * Passenger's date of birth.
     * @type {Date}
     * @memberof Passenger
     */
    dateOfBirth?: Date;
    /**
     * 
     * @type {ContactDetails}
     * @memberof Passenger
     */
    contactInfo?: ContactDetails;
    /**
     * 
     * @type {Amount}
     * @memberof Passenger
     */
    amount?: Amount;
    /**
     * 
     * @type {number}
     * @memberof Passenger
     */
    gender?: number;
    /**
     * 
     * @type {AdditionalData}
     * @memberof Passenger
     */
    additionalData?: AdditionalData;
}

export function PassengerFromJSON(json: any): Passenger {
    return PassengerFromJSONTyped(json, false);
}

export function PassengerFromJSONTyped(json: any, ignoreDiscriminator: boolean): Passenger {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'seq': !exists(json, 'seq') ? undefined : json['seq'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'profileId': !exists(json, 'profile_id') ? undefined : json['profile_id'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'firstName': json['first_name'],
        'lastName': json['last_name'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'dateOfBirth': !exists(json, 'date_of_birth') ? undefined : (new Date(json['date_of_birth'])),
        'contactInfo': !exists(json, 'contact_info') ? undefined : ContactDetailsFromJSON(json['contact_info']),
        'amount': !exists(json, 'amount') ? undefined : AmountFromJSON(json['amount']),
        'gender': !exists(json, 'gender') ? undefined : json['gender'],
        'additionalData': !exists(json, 'additional_data') ? undefined : AdditionalDataFromJSON(json['additional_data']),
    };
}

export function PassengerToJSON(value?: Passenger | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'seq': value.seq,
        'id': value.id,
        'profile_id': value.profileId,
        'title': value.title,
        'first_name': value.firstName,
        'last_name': value.lastName,
        'type': value.type,
        'date_of_birth': value.dateOfBirth === undefined ? undefined : (value.dateOfBirth.toISOString().substr(0,10)),
        'contact_info': ContactDetailsToJSON(value.contactInfo),
        'amount': AmountToJSON(value.amount),
        'gender': value.gender,
        'additional_data': AdditionalDataToJSON(value.additionalData),
    };
}

