import { CardMeta } from "../../types/cpd-init";

export function getCardType(cardNumber: string, cardsMeta: CardMeta[]): CardMeta | undefined {
	let res: CardMeta | undefined = undefined;

	if (!Array.isArray(cardsMeta)) {
		console.error("cards meta", cardsMeta);
		throw (new Error('The cards meta is not an array of cards!'));
	}

	cardsMeta.forEach(meta => {
		let hasMatch = false;

		meta.prefixes.forEach(prefix => {
			const prefixLength = prefix.min.toString().length;
			const cardPrefix: number = parseInt(cardNumber.slice(0, prefixLength), 10);

			if (cardPrefix >= prefix.min && cardPrefix <= prefix.max) {
				hasMatch = true;
			}
		})

		if (hasMatch) {
			res = meta;
		}
	})

	return res;
}
