/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetStatusResponseMessage,
    GetStatusResponseMessageFromJSON,
    GetStatusResponseMessageFromJSONTyped,
    GetStatusResponseMessageToJSON,
} from './GetStatusResponseMessage';

/**
 * 
 * @export
 * @interface GetStatusResponseTransactionMessages
 */
export interface GetStatusResponseTransactionMessages {
    /**
     * 
     * @type {Array<GetStatusResponseMessage>}
     * @memberof GetStatusResponseTransactionMessages
     */
    message?: Array<GetStatusResponseMessage>;
}

export function GetStatusResponseTransactionMessagesFromJSON(json: any): GetStatusResponseTransactionMessages {
    return GetStatusResponseTransactionMessagesFromJSONTyped(json, false);
}

export function GetStatusResponseTransactionMessagesFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetStatusResponseTransactionMessages {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'message': !exists(json, 'message') ? undefined : ((json['message'] as Array<any>).map(GetStatusResponseMessageFromJSON)),
    };
}

export function GetStatusResponseTransactionMessagesToJSON(value?: GetStatusResponseTransactionMessages | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'message': value.message === undefined ? undefined : ((value.message as Array<any>).map(GetStatusResponseMessageToJSON)),
    };
}

