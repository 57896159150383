/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Fee,
    FeeFromJSON,
    FeeFromJSONTyped,
    FeeToJSON,
} from './Fee';

/**
 * The amount the customer will pay in fees for the line item in the cart.
 * @export
 * @interface LineItemFees
 */
export interface LineItemFees {
    /**
     * 
     * @type {Array<Fee>}
     * @memberof LineItemFees
     */
    fee?: Array<Fee>;
}

export function LineItemFeesFromJSON(json: any): LineItemFees {
    return LineItemFeesFromJSONTyped(json, false);
}

export function LineItemFeesFromJSONTyped(json: any, ignoreDiscriminator: boolean): LineItemFees {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fee': !exists(json, 'fee') ? undefined : ((json['fee'] as Array<any>).map(FeeFromJSON)),
    };
}

export function LineItemFeesToJSON(value?: LineItemFees | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fee': value.fee === undefined ? undefined : ((value.fee as Array<any>).map(FeeToJSON)),
    };
}

