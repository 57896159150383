/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AddressAllOf,
    AddressAllOfFromJSON,
    AddressAllOfFromJSONTyped,
    AddressAllOfToJSON,
} from './AddressAllOf';
import {
    ContactDetails,
    ContactDetailsFromJSON,
    ContactDetailsFromJSONTyped,
    ContactDetailsToJSON,
} from './ContactDetails';
import {
    Names,
    NamesFromJSON,
    NamesFromJSONTyped,
    NamesToJSON,
} from './Names';

/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * First name.
     * @type {string}
     * @memberof Address
     */
    firstName?: string;
    /**
     * Last name.
     * @type {string}
     * @memberof Address
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    fullName?: string;
    /**
     * The user's company name.
     * @type {string}
     * @memberof Address
     */
    company?: string;
    /**
     * User's street as per the billing address.
     * @type {string}
     * @memberof Address
     */
    street?: string;
    /**
     * User's city name as per the billing address.
     * @type {string}
     * @memberof Address
     */
    city?: string;
    /**
     * User's postal code as per the billing address.
     * @type {string}
     * @memberof Address
     */
    postalCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    state?: string;
    /**
     * Country code based on ISO 3166-1 alpha-2 standard:
  * US - usa
  * CN - china
  * JP - japan
  * DE - germany
  * GB - uk
  * IN - india
  * FR - france
  * IT - italy
  * CA - canada

Full list: https://www.iso.org/obp/ui

     * @type {string}
     * @memberof Address
     */
    country?: string;
    /**
     * 
     * @type {ContactDetails}
     * @memberof Address
     */
    contactDetails?: ContactDetails;
}

export function AddressFromJSON(json: any): Address {
    return AddressFromJSONTyped(json, false);
}

export function AddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): Address {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': !exists(json, 'first_name') ? undefined : json['first_name'],
        'lastName': !exists(json, 'last_name') ? undefined : json['last_name'],
        'fullName': !exists(json, 'full_name') ? undefined : json['full_name'],
        'company': !exists(json, 'company') ? undefined : json['company'],
        'street': json['street'],
        'city': json['city'],
        'postalCode': !exists(json, 'postal_code') ? undefined : json['postal_code'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'country': json['country'],
        'contactDetails': !exists(json, 'contact_details') ? undefined : ContactDetailsFromJSON(json['contact_details']),
    };
}

export function AddressToJSON(value?: Address | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'first_name': value.firstName,
        'last_name': value.lastName,
        'full_name': value.fullName,
        'company': value.company,
        'street': value.street,
        'city': value.city,
        'postal_code': value.postalCode,
        'state': value.state,
        'country': value.country,
        'contact_details': ContactDetailsToJSON(value.contactDetails),
    };
}

