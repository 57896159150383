import { CardMeta } from '../../../types/cpd-init';

const cardIdMap = {
	"1": new URL('./card_amex.svg', import.meta.url),
	"3": new URL('./card_diners.svg', import.meta.url),
	"5": new URL('./card_jcb.svg', import.meta.url),
	"7": new URL('./card_mastercard.svg', import.meta.url),
	"8": new URL('./card_visa.svg', import.meta.url),
	"21": new URL('./card_uatp.svg', import.meta.url),
	"22": new URL('./card_discover.svg', import.meta.url),
	"28": new URL('./card_paypal.svg', import.meta.url),
	"82": new URL('./card_elo.svg', import.meta.url)
}

export function getCardHintURL(cardType: CardMeta): string {
	if (cardIdMap[cardType.id]) {
		return cardIdMap[cardType.id].href;
	}

	return (new URL('./card_default.svg', import.meta.url)).href;
}
