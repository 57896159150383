import { CPDOptions } from "../../types/cpd-init";

export function validateOptions(params: CPDOptions): string {
    if (!params.txRef) {
        return 'txRef is missing from iframe options';
    }

    if (!params.field) {
        return 'field is missing from iframe options'
    }

    if (!params.meta) {
        return 'meta is missing from iframe options'
    }

	return '';
}