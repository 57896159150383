/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SplitPaymentConfigurationCombinations,
    SplitPaymentConfigurationCombinationsFromJSON,
    SplitPaymentConfigurationCombinationsFromJSONTyped,
    SplitPaymentConfigurationCombinationsToJSON,
} from './SplitPaymentConfigurationCombinations';

/**
 * 
 * @export
 * @interface SplitPaymentConfiguration
 */
export interface SplitPaymentConfiguration {
    /**
     * Maximum number of splits allowed for a payment.
     * @type {number}
     * @memberof SplitPaymentConfiguration
     */
    splitCount?: number;
    /**
     * 
     * @type {SplitPaymentConfigurationCombinations}
     * @memberof SplitPaymentConfiguration
     */
    combinations?: SplitPaymentConfigurationCombinations;
}

export function SplitPaymentConfigurationFromJSON(json: any): SplitPaymentConfiguration {
    return SplitPaymentConfigurationFromJSONTyped(json, false);
}

export function SplitPaymentConfigurationFromJSONTyped(json: any, ignoreDiscriminator: boolean): SplitPaymentConfiguration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'splitCount': !exists(json, 'split_count') ? undefined : json['split_count'],
        'combinations': !exists(json, 'combinations') ? undefined : SplitPaymentConfigurationCombinationsFromJSON(json['combinations']),
    };
}

export function SplitPaymentConfigurationToJSON(value?: SplitPaymentConfiguration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'split_count': value.splitCount,
        'combinations': SplitPaymentConfigurationCombinationsToJSON(value.combinations),
    };
}

