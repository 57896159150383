import { CPDOptions } from "../../types/cpd-init";
import { PaymentsApi } from "../generated-sources/openapi/apis/PaymentsApi";
import { GetStatusResponse } from "../generated-sources/openapi/models/GetStatusResponse";

export async function submitGetStatus(options: CPDOptions, paymentsApi: PaymentsApi): Promise<GetStatusResponse> {
    return await paymentsApi.status({
        authorization: "Bearer " + options.authToken,
        hostResolver: options.hostResolver,
        contentType: "application/json",

        getStatusRequest: {
            clientId: options.meta.clientId,
            account: options.meta.account,
            transactions: {
                transaction: [{
                    id: parseInt(options.txRef, 10)
                }]
            }
        }
    });
}
