import { CardMeta, CPDOptions } from '../../types/cpd-init';
import { CPInput, CPInputProps } from './input';
import { CPInputWrapper } from './input-wrapper';
import { getCardHintURL } from '../methods/cards/get-card-hint-img';
import { getCardType } from '../methods/get-card-type';
import { html } from 'htm/preact';
import { useEffect, useRef, useState } from 'preact/hooks';

export interface NumberInputProps extends CPInputProps {
	opt: CPDOptions
}

export function NumberInput(props: NumberInputProps) {
	const ref = useRef();
	const [ cardPreview, setCardPreview ] = useState<string | null>(null);
	const [ maxLength, setMaxLength ]  = useState<number>(19);

	function updateCardPreview(inputvalue: string) {
		const cardMeta: CardMeta | undefined = getCardType(inputvalue, props.opt.cardsMeta);
		if (cardMeta !== undefined) {
			setCardPreview(getCardHintURL(cardMeta));
		}
	}

	function updateCardMaxLength(inputvalue: string) {
		const cardMeta: CardMeta | undefined = getCardType(inputvalue, props.opt.cardsMeta);
		if (cardMeta !== undefined && maxLength !== cardMeta.max_length) {
			setMaxLength(cardMeta.max_length);
		}
	}

	function formatInputWithSpaces(target: HTMLInputElement) {
		let caretPos = target.selectionStart;
		let newVal = target.value.replace(/\D+/g, '');

		// if we remove a letter, then move the caret back
		if(newVal && target.value.replace(/ /ig, '') !== newVal) {
			caretPos = caretPos-1;
		}

		if (newVal) {
			newVal = newVal.match(/.{1,4}/g).join(' ');

			// If we added a space, then move the caret as well
			const spacesBefore = (target.value.slice(0, caretPos).split(" ").length - 1)
			const spacesAfter = (newVal.slice(0, caretPos).split(" ").length - 1)
			if (spacesBefore < spacesAfter) {
				caretPos = caretPos+1;
			}
		}

		if (target.value !== newVal) {
			target.value = newVal;
			target.setSelectionRange(caretPos, caretPos)
		}
	}

	useEffect(() => {
		if (ref.current) {
			const elm: Element = ref.current as Element;

			const onInputChange = (e: KeyboardEvent) => {
				const target = e.target as HTMLInputElement;

				updateCardPreview(target.value);
				updateCardMaxLength(target.value);
				formatInputWithSpaces(target);
			}

			elm.addEventListener('keyup', onInputChange);
			elm.addEventListener('change', onInputChange);

			return () => {
				elm.removeEventListener('keyup', onInputChange);
				elm.removeEventListener('change', onInputChange);
			};
		}
	}, [ ref.current ]);

	const hintImage = cardPreview !== null ? html`<img src=${cardPreview} className="hint-image" />` : '';

	return html`
		<${CPInputWrapper}>
			<${CPInput} ref=${ref} ...${props} maxLength=${maxLength} />

			${hintImage}
		</${CPInputWrapper}>
	`;
}
