/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Input Fields.
 * @export
 * @interface InputFields
 */
export interface InputFields {
    /**
     * 
     * @type {boolean}
     * @memberof InputFields
     */
    optimized?: boolean;
}

export function InputFieldsFromJSON(json: any): InputFields {
    return InputFieldsFromJSONTyped(json, false);
}

export function InputFieldsFromJSONTyped(json: any, ignoreDiscriminator: boolean): InputFields {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'optimized': !exists(json, 'optimized') ? undefined : json['optimized'],
    };
}

export function InputFieldsToJSON(value?: InputFields | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'optimized': value.optimized,
    };
}

