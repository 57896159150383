/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * The amount charged for the given entity.
 * @export
 * @interface GetStatusResponseAmount
 */
export interface GetStatusResponseAmount {
    /**
     * Amount charged in the currency's smallest monetary unit.
     * @type {number}
     * @memberof GetStatusResponseAmount
     */
    value: number;
    /**
     * Country code based on ISO 3166-1 alpha-2 standard:
  * US - usa
  * CN - china
  * JP - japan
  * DE - germany
  * GB - uk
  * IN - india
  * FR - france
  * IT - italy
  * CA - canada

Full list: https://www.iso.org/obp/ui

     * @type {string}
     * @memberof GetStatusResponseAmount
     */
    country?: string;
    /**
     * The 3 character currency code.
     * @type {string}
     * @memberof GetStatusResponseAmount
     */
    currency: string;
    /**
     * The symbol that is used to represent the currency (kr. $, € etc.).
     * @type {string}
     * @memberof GetStatusResponseAmount
     */
    symbol: string;
}

export function GetStatusResponseAmountFromJSON(json: any): GetStatusResponseAmount {
    return GetStatusResponseAmountFromJSONTyped(json, false);
}

export function GetStatusResponseAmountFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetStatusResponseAmount {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': json['value'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'currency': json['currency'],
        'symbol': json['symbol'],
    };
}

export function GetStatusResponseAmountToJSON(value?: GetStatusResponseAmount | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': value.value,
        'country': value.country,
        'currency': value.currency,
        'symbol': value.symbol,
    };
}

