/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ClientInfo,
    ClientInfoFromJSON,
    ClientInfoFromJSONTyped,
    ClientInfoToJSON,
} from './ClientInfo';
import {
    InitializeTransaction,
    InitializeTransactionFromJSON,
    InitializeTransactionFromJSONTyped,
    InitializeTransactionToJSON,
} from './InitializeTransaction';

/**
 * 
 * @export
 * @interface InitializeRequest
 */
export interface InitializeRequest {
    /**
     * The unique ID configured for a merchant in Velocity.
     * @type {number}
     * @memberof InitializeRequest
     */
    clientId: number;
    /**
     * The number for a sub-account with which a payment transaction is associated.
The account ID is an integer greater than 100000 and account number is an integer smaller than 1000.

The payment transaction is associated with the default sub-account if merchants do not provide this parameter.

     * @type {number}
     * @memberof InitializeRequest
     */
    account?: number;
    /**
     * 
     * @type {InitializeTransaction}
     * @memberof InitializeRequest
     */
    transaction: InitializeTransaction;
    /**
     * Auth token used to perform a Single Sign-On (SSO) using third party user management system.
     * @type {string}
     * @memberof InitializeRequest
     */
    authToken?: string;
    /**
     * 
     * @type {ClientInfo}
     * @memberof InitializeRequest
     */
    clientInfo: ClientInfo;
}

export function InitializeRequestFromJSON(json: any): InitializeRequest {
    return InitializeRequestFromJSONTyped(json, false);
}

export function InitializeRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): InitializeRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'clientId': json['client_id'],
        'account': !exists(json, 'account') ? undefined : json['account'],
        'transaction': InitializeTransactionFromJSON(json['transaction']),
        'authToken': !exists(json, 'auth_token') ? undefined : json['auth_token'],
        'clientInfo': ClientInfoFromJSON(json['client_info']),
    };
}

export function InitializeRequestToJSON(value?: InitializeRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'client_id': value.clientId,
        'account': value.account,
        'transaction': InitializeTransactionToJSON(value.transaction),
        'auth_token': value.authToken,
        'client_info': ClientInfoToJSON(value.clientInfo),
    };
}

