import { CPDOptions } from "../../types/cpd-init";
import { PaymentsApi } from "../generated-sources/openapi/apis/PaymentsApi";
import { AuthorizeResponse } from "../generated-sources/openapi/models/AuthorizeResponse";
import { AuthorizeTransactionCard } from "../generated-sources/openapi/models/AuthorizeTransactionCard";

import { CardDetails } from "./validate-card-details";

export async function submitAuthorize(options: CPDOptions , cardDetails: CardDetails, paymentsApi: PaymentsApi): Promise<AuthorizeResponse> {
    const clientInfo = {  // Why are we sending this?
        language: options.meta.language,
        platform: options.meta.platform,
        version: options.meta.version
    };

    return await paymentsApi.authorize({
        authorization: "Bearer " + options.authToken,
        hostResolver: options.hostResolver,
        contentType: "application/json",

        authorizeRequest: {
            clientId: options.meta.clientId,
            account: options.meta.account,
            transaction: {
                id: parseInt(options.txRef, 10),
                card: {
                    typeId: cardDetails.cardTypeId,
                    cvc: cardDetails.cardCVV,
                    cardHolderName: cardDetails.cardHolderName,
                    cardNumber: cardDetails.cardNumber,
                    expiry: cardDetails.cardExpirationDate,

                    amount: options.amount,
                    address: cardDetails.address
                } as AuthorizeTransactionCard,
            },

            clientInfo // Why are we sending this?
        }
    });
}
