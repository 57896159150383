/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AuthorizeTransaction,
    AuthorizeTransactionFromJSON,
    AuthorizeTransactionFromJSONTyped,
    AuthorizeTransactionToJSON,
} from './AuthorizeTransaction';
import {
    ClientInfo,
    ClientInfoFromJSON,
    ClientInfoFromJSONTyped,
    ClientInfoToJSON,
} from './ClientInfo';

/**
 * 
 * @export
 * @interface AuthorizeRequest
 */
export interface AuthorizeRequest {
    /**
     * The password associated with the stored card being used.
     * @type {string}
     * @memberof AuthorizeRequest
     */
    password?: string;
    /**
     * The unique ID configured for a merchant in Velocity.
     * @type {number}
     * @memberof AuthorizeRequest
     */
    clientId: number;
    /**
     * The number for a sub-account with which a payment transaction is associated.
The account ID is an integer greater than 100000 and account number is an integer smaller than 1000.

The payment transaction is associated with the default sub-account if merchants do not provide this parameter.

     * @type {number}
     * @memberof AuthorizeRequest
     */
    account?: number;
    /**
     * Auth token used to perform a Single Sign-On (SSO) using third party user management system.
     * @type {string}
     * @memberof AuthorizeRequest
     */
    authToken?: string;
    /**
     * 
     * @type {AuthorizeTransaction}
     * @memberof AuthorizeRequest
     */
    transaction: AuthorizeTransaction;
    /**
     * 
     * @type {ClientInfo}
     * @memberof AuthorizeRequest
     */
    clientInfo: ClientInfo;
}

export function AuthorizeRequestFromJSON(json: any): AuthorizeRequest {
    return AuthorizeRequestFromJSONTyped(json, false);
}

export function AuthorizeRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthorizeRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'password': !exists(json, 'password') ? undefined : json['password'],
        'clientId': json['client_id'],
        'account': !exists(json, 'account') ? undefined : json['account'],
        'authToken': !exists(json, 'auth_token') ? undefined : json['auth_token'],
        'transaction': AuthorizeTransactionFromJSON(json['transaction']),
        'clientInfo': ClientInfoFromJSON(json['client_info']),
    };
}

export function AuthorizeRequestToJSON(value?: AuthorizeRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'password': value.password,
        'client_id': value.clientId,
        'account': value.account,
        'auth_token': value.authToken,
        'transaction': AuthorizeTransactionToJSON(value.transaction),
        'client_info': ClientInfoToJSON(value.clientInfo),
    };
}

