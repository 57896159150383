import styled from "styled-components";

export const CPInputWrapper = styled.div`
	.hint-image {
		position: absolute;
		top: 0;
		right: 0;
		height: 100%;
		width: 2rem;
	}
`;
