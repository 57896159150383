import { html } from "htm/preact";
import { useEffect, useRef } from 'preact/hooks';
import styled from "styled-components";
import { CPInput } from "./input";

const NameInputElm = styled(CPInput)``;

export function NameInput(props: any) {
	const ref = useRef();

	useEffect(() => {
		if (ref.current) {
			const elm: Element = ref.current as Element;

			const listener = (e: KeyboardEvent) => {
				const target = e.target as HTMLInputElement;
				const newVal = target.value.split('').map((i, v) => i.replace(/([a-zA-Z ])/ig, '') === '' ? i : '').join('');
				let caretPos = target.selectionStart;

				if(newVal && target.value.replace(/ /ig, '') !== newVal) {
					caretPos = caretPos-1;
				}

				if (target.value !== newVal) {
					target.value = newVal;
					target.setSelectionRange(caretPos, caretPos)
				}
			}

			elm.addEventListener('keyup', listener);
			elm.addEventListener('change', listener);

			return () => {
				elm.removeEventListener('keyup', listener);
				elm.removeEventListener('change', listener);
			};
		}
	}, [ ref.current ]);

	return html`<${NameInputElm} ...${props} maxLength="255" ref=${ref}  />`
}
