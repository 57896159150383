/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Email,
    EmailFromJSON,
    EmailFromJSONTyped,
    EmailToJSON,
} from './Email';
import {
    LanguageCode,
    LanguageCodeFromJSON,
    LanguageCodeFromJSONTyped,
    LanguageCodeToJSON,
} from './LanguageCode';
import {
    Mobile,
    MobileFromJSON,
    MobileFromJSONTyped,
    MobileToJSON,
} from './Mobile';

/**
 * An object of client-info, which contains the following merchant details: language, version, platform, and profileId.

 * @export
 * @interface ClientInfo
 */
export interface ClientInfo {
    /**
     * The unique reference ID given by a merchant for a customer. 
Velocity uses this token to identify a user. 
The customer reference is included in the request to a specified auth-URL to identify customers when they:

- Pay with a stored card through the mVault
- Use single sign-on.

> **Note**: Customer-ref can be mandatory or optional, based on the requirement of a merchant or a PSP.

     * @type {string}
     * @memberof ClientInfo
     */
    customerRef?: string;
    /**
     * 
     * @type {Mobile}
     * @memberof ClientInfo
     */
    mobile?: Mobile;
    /**
     * 
     * @type {Email}
     * @memberof ClientInfo
     */
    email?: Email;
    /**
     * The end-user's device ID that can be used for identification.

> **Note**: Device ID is optional for a web channel.

     * @type {string}
     * @memberof ClientInfo
     */
    deviceId?: string;
    /**
     * The IP address of the calling system.
     * @type {string}
     * @memberof ClientInfo
     */
    ip?: string;
    /**
     * 
     * @type {number}
     * @memberof ClientInfo
     */
    appId?: number;
    /**
     * The front-end's platform.
     * @type {string}
     * @memberof ClientInfo
     */
    platform: string;
    /**
     * 
     * @type {string}
     * @memberof ClientInfo
     */
    version: string | null;
    /**
     * 
     * @type {LanguageCode}
     * @memberof ClientInfo
     */
    language: LanguageCode;
    /**
     * 
     * @type {string}
     * @memberof ClientInfo
     */
    pushId?: string;
    /**
     * The version of SDK used to send the request.
     * @type {string}
     * @memberof ClientInfo
     */
    sdkVersion?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientInfo
     */
    appVersion?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientInfo
     */
    profileId?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientInfo
     */
    locale?: string;
}

export function ClientInfoFromJSON(json: any): ClientInfo {
    return ClientInfoFromJSONTyped(json, false);
}

export function ClientInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customerRef': !exists(json, 'customer_ref') ? undefined : json['customer_ref'],
        'mobile': !exists(json, 'mobile') ? undefined : MobileFromJSON(json['mobile']),
        'email': !exists(json, 'email') ? undefined : EmailFromJSON(json['email']),
        'deviceId': !exists(json, 'device_id') ? undefined : json['device_id'],
        'ip': !exists(json, 'ip') ? undefined : json['ip'],
        'appId': !exists(json, 'app_id') ? undefined : json['app_id'],
        'platform': json['platform'],
        'version': json['version'],
        'language': LanguageCodeFromJSON(json['language']),
        'pushId': !exists(json, 'push_id') ? undefined : json['push_id'],
        'sdkVersion': !exists(json, 'sdk_version') ? undefined : json['sdk_version'],
        'appVersion': !exists(json, 'app_version') ? undefined : json['app_version'],
        'profileId': !exists(json, 'profile_id') ? undefined : json['profile_id'],
        'locale': !exists(json, 'locale') ? undefined : json['locale'],
    };
}

export function ClientInfoToJSON(value?: ClientInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customer_ref': value.customerRef,
        'mobile': MobileToJSON(value.mobile),
        'email': EmailToJSON(value.email),
        'device_id': value.deviceId,
        'ip': value.ip,
        'app_id': value.appId,
        'platform': value.platform,
        'version': value.version,
        'language': LanguageCodeToJSON(value.language),
        'push_id': value.pushId,
        'sdk_version': value.sdkVersion,
        'app_version': value.appVersion,
        'profile_id': value.profileId,
        'locale': value.locale,
    };
}

