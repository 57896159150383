import styled from 'styled-components';

export interface FontStyles {
    color?: string;
    fontSize?: string;
    fontWeight?: string;
    fontFamily?: string;
    align?: string;
}

export interface CPInputStyles {
    input?: FontStyles;
    '::placeholder'?: FontStyles;
    '.invalid'?: FontStyles;
    ':focus'?: FontStyles;
    ':hover'?: FontStyles;
}

export interface CPInputProps {
    message: boolean;
    styles?: CPInputStyles
}

function getStyles(obj: FontStyles) {
    return `
        ${obj?.color ? `color: ${obj.color};` : ''}
        ${obj?.fontFamily ? `font-family: ${obj.fontFamily};` : ''}
        ${obj?.fontWeight ? `font-weight: ${obj.fontWeight};` : ''}
        ${obj?.fontSize ? `font-size: ${obj.fontSize};` : ''}
        ${obj?.align ? `font-size: ${obj.align};` : ''}
    `;
}

export const CPInput = styled.input<CPInputProps>`
    height: 100vh;
    width: 100vw;
    box-sizing: border-box;

    ${({message}) => {
        return message && 'background-color: tomato;'
    }}

    ${({styles}) => {
        return styles?.input ? getStyles(styles?.input) : '';
    }}

    &::placeholder {
        ${({styles}) => {
            return styles?.['::placeholder'] ? getStyles(styles?.['::placeholder']) : '';
        }}
    }

    &:focus {
        ${({styles}) => {
            return styles?.[':focus'] ? getStyles(styles?.[':focus']) : '';
        }}
    }

    &:hover {
        ${({styles}) => {
            return styles?.[':hover'] ? getStyles(styles?.[':hover']) : '';
        }}
    }
`;
