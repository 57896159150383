/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * String representing the 3DS data representing that the card verification is completed by the card issuer and card holder.
 * @export
 * @interface Cryptogram
 */
export interface Cryptogram {
    /**
     * 
     * @type {string}
     * @memberof Cryptogram
     */
    value: string;
    /**
     * The method used for creation of the 3DS data if either the 3ds/emv method is used.
     * @type {string}
     * @memberof Cryptogram
     */
    type: CryptogramTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof Cryptogram
     */
    eci?: number;
}

/**
* @export
* @enum {string}
*/
export enum CryptogramTypeEnum {
    Emv = 'emv',
    _3ds = '3ds'
}

export function CryptogramFromJSON(json: any): Cryptogram {
    return CryptogramFromJSONTyped(json, false);
}

export function CryptogramFromJSONTyped(json: any, ignoreDiscriminator: boolean): Cryptogram {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': json['value'],
        'type': json['type'],
        'eci': !exists(json, 'eci') ? undefined : json['eci'],
    };
}

export function CryptogramToJSON(value?: Cryptogram | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': value.value,
        'type': value.type,
        'eci': value.eci,
    };
}

