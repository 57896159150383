/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Combination,
    CombinationFromJSON,
    CombinationFromJSONTyped,
    CombinationToJSON,
} from './Combination';

/**
 * One combination can have multiple payment_types.
 * @export
 * @interface SplitPaymentConfigurationCombinations
 */
export interface SplitPaymentConfigurationCombinations {
    /**
     * 
     * @type {Array<Combination>}
     * @memberof SplitPaymentConfigurationCombinations
     */
    combination?: Array<Combination>;
}

export function SplitPaymentConfigurationCombinationsFromJSON(json: any): SplitPaymentConfigurationCombinations {
    return SplitPaymentConfigurationCombinationsFromJSONTyped(json, false);
}

export function SplitPaymentConfigurationCombinationsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SplitPaymentConfigurationCombinations {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'combination': !exists(json, 'combination') ? undefined : ((json['combination'] as Array<any>).map(CombinationFromJSON)),
    };
}

export function SplitPaymentConfigurationCombinationsToJSON(value?: SplitPaymentConfigurationCombinations | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'combination': value.combination === undefined ? undefined : ((value.combination as Array<any>).map(CombinationToJSON)),
    };
}

