import styled from 'styled-components';
import { CPDOptions } from '../../types/cpd-init';
import { html } from 'htm/preact';
import { openApiConfiguration } from '../api';
import { PaymentsApi } from '../generated-sources/openapi';
import { submit } from '../methods/submit';
import { useEffect, useState } from 'preact/hooks';
import { CPInputStyles } from './input';

export const CPSubmitBtnElm = styled.button<{ styles: CPInputStyles }>`
	width: 100vw;
	height: 100vh;
	border: 0;
	background-color: transparent;
	cursor: pointer;

    ${({styles}) => {
        return `
            ${styles?.input?.color ? `color: ${styles?.input?.color};` : ''}
            ${styles?.input?.fontFamily ? `font-family: ${styles?.input?.fontFamily};` : ''}
            ${styles?.input?.fontWeight ? `font-weight: ${styles?.input?.fontWeight};` : ''}
            ${styles?.input?.fontSize ? `font-size: ${styles?.input?.fontSize};` : ''}
        `;
    }}

    &::placeholder {
        ${({styles}) => {
            return `
                ${styles?.['::placeholder']?.color ? `color: ${styles?.['::placeholder']?.color};` : ''}
                ${styles?.['::placeholder']?.fontFamily ? `font-family: ${styles?.['::placeholder']?.fontFamily};` : ''}
                ${styles?.['::placeholder']?.fontWeight ? `font-weight: ${styles?.['::placeholder']?.fontWeight};` : ''}
                ${styles?.['::placeholder']?.fontSize ? `font-size: ${styles?.['::placeholder']?.fontSize};` : ''}
            `;
        }}
    }

    &:focus {
        ${({styles}) => {
            return `
                ${styles?.[':focus']?.color ? `color: ${styles?.[':focus']?.color};` : ''}
                ${styles?.[':focus']?.fontFamily ? `font-family: ${styles?.[':focus']?.fontFamily};` : ''}
                ${styles?.[':focus']?.fontWeight ? `font-weight: ${styles?.[':focus']?.fontWeight};` : ''}
                ${styles?.[':focus']?.fontSize ? `font-size: ${styles?.[':focus']?.fontSize};` : ''}
            `;
        }}
    }

    &:hover {
        ${({styles}) => {
            return `
                ${styles?.[':hover']?.color ? `color: ${styles?.[':hover']?.color};` : ''}
                ${styles?.[':hover']?.fontFamily ? `font-family: ${styles?.[':hover']?.fontFamily};` : ''}
                ${styles?.[':hover']?.fontWeight ? `font-weight: ${styles?.[':hover']?.fontWeight};` : ''}
                ${styles?.[':hover']?.fontSize ? `font-size: ${styles?.[':hover']?.fontSize};` : ''}
            `;
        }}
    }
`;

export function CPSubmitBtn(attr: { opt: CPDOptions }) {
	const [ paymentsApi ] = useState(new PaymentsApi(openApiConfiguration));

	return html`
        <${CPSubmitBtnElm} styles=${attr.opt.styles} onClick=${() => submit(attr.opt, paymentsApi)}>
            ${attr.opt.label || 'Pay'}
        </${CPSubmitBtnElm}>
    `;
}
