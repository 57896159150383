export const MessageTypes = {
	API_ERROR: 'CPD_WEB:API_ERROR',
	STATUS_DONE: 'CPD_WEB:STATUS_DONE',
	STATUS_PENDING: 'CPD_WEB:STATUS_PENDING',
	SUBMITTED: 'CPD_WEB:SUBMITTED',
	AUTHORIZE_SUCCESS: 'CPD_WEB:AUTHORIZE_SUCCESS',
	PAY_SUCCESS: 'CPD_WEB:PAY_SUCCESS',
	VALIDATION_ERROR: 'CPD_WEB:VALIDATION_ERROR'
}

export interface FrameMessage {
	type: string;
	data: string;
}

export function sendMessage(msg: FrameMessage) {
	window.parent.postMessage(JSON.stringify(msg), '*');
}
