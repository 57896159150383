/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Format,
    FormatFromJSON,
    FormatFromJSONTyped,
    FormatToJSON,
} from './Format';

/**
 * A fee charged by the merchant, which is on top of the cost of the item purchased.
 * @export
 * @interface Fee
 */
export interface Fee {
    /**
     * The amount of the fee, in the currency's smallest monetary unit.
     * @type {number}
     * @memberof Fee
     */
    value: number;
    /**
     * 
     * @type {string}
     * @memberof Fee
     */
    type?: string;
    /**
     * 
     * @type {number}
     * @memberof Fee
     */
    typeId?: number;
    /**
     * Country code based on ISO 3166-1 alpha-2 standard:
  * US - usa
  * CN - china
  * JP - japan
  * DE - germany
  * GB - uk
  * IN - india
  * FR - france
  * IT - italy
  * CA - canada

Full list: https://www.iso.org/obp/ui

     * @type {string}
     * @memberof Fee
     */
    country?: string;
    /**
     * ISO 4217 currency code.
     * @type {string}
     * @memberof Fee
     */
    currency?: string;
    /**
     * The symbol that is used to represent the currency (kr. $, € etc.).
     * @type {string}
     * @memberof Fee
     */
    symbol?: string;
    /**
     * 
     * @type {Format}
     * @memberof Fee
     */
    format?: Format;
}

export function FeeFromJSON(json: any): Fee {
    return FeeFromJSONTyped(json, false);
}

export function FeeFromJSONTyped(json: any, ignoreDiscriminator: boolean): Fee {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': json['value'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'typeId': !exists(json, 'type_id') ? undefined : json['type_id'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'currency': !exists(json, 'currency') ? undefined : json['currency'],
        'symbol': !exists(json, 'symbol') ? undefined : json['symbol'],
        'format': !exists(json, 'format') ? undefined : FormatFromJSON(json['format']),
    };
}

export function FeeToJSON(value?: Fee | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': value.value,
        'type': value.type,
        'type_id': value.typeId,
        'country': value.country,
        'currency': value.currency,
        'symbol': value.symbol,
        'format': FormatToJSON(value.format),
    };
}

