/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * The MSISDN of a customer without International Dialing Code.

> **Note**: Mobile number can be mandatory or optional, based on the requirement of a merchant or a PSP.

 * @export
 * @interface Mobile
 */
export interface Mobile {
    /**
     * 
     * @type {string}
     * @memberof Mobile
     */
    value: string;
    /**
     * Country code based on ISO 3166-1 alpha-2 standard:
  * US - usa
  * CN - china
  * JP - japan
  * DE - germany
  * GB - uk
  * IN - india
  * FR - france
  * IT - italy
  * CA - canada

Full list: https://www.iso.org/obp/ui

     * @type {string}
     * @memberof Mobile
     */
    country: string;
    /**
     * The GSM operator ID for a given country, this is relevant while sharing the mobile number.
     * @type {number}
     * @memberof Mobile
     */
    operatorId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Mobile
     */
    verified?: boolean;
}

export function MobileFromJSON(json: any): Mobile {
    return MobileFromJSONTyped(json, false);
}

export function MobileFromJSONTyped(json: any, ignoreDiscriminator: boolean): Mobile {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'value': json['value'],
        'country': json['country'],
        'operatorId': !exists(json, 'operator_id') ? undefined : json['operator_id'],
        'verified': !exists(json, 'verified') ? undefined : json['verified'],
    };
}

export function MobileToJSON(value?: Mobile | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'value': value.value,
        'country': value.country,
        'operator_id': value.operatorId,
        'verified': value.verified,
    };
}

