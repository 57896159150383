import { CPDOptions, CreditCardField } from '../types/cpd-init';
import { CPInput } from './controls/input';
import { CPSubmitBtn } from './controls/submit-btn';
import { CVVInput } from './controls/cvv-input';
import { ExpirationInput } from './controls/expiration-input';
import { html } from 'htm/preact';
import { NameInput } from './controls/name-input';
import { NumberInput } from './controls/number-input';
import { TextInput } from './controls/text-input';
import { validateOptions } from './methods/validate-options';

function createInput(Comp, opt: CPDOptions, label: string) {
    return html`<${Comp} styles=${opt?.styles} opt=${opt} placeholder=${opt.label || label} name=${opt.field} />`
}

export function FrameApp() {
    const queryParams = new URLSearchParams(window.location.search);
    const opt: CPDOptions = JSON.parse(queryParams.get('opt') || '{}');
    const optError = validateOptions(opt);

    opt.meta.platform = "Web SDK";
    opt.meta.version = "0.0.1";

    const inputMap = {
        // Credit card fields
        [CreditCardField.name]: () => createInput(NameInput, opt, 'Name on card'),
        [CreditCardField.number]: () => createInput(NumberInput, opt, 'Card number'),
        [CreditCardField.cvv]: () => createInput(CVVInput, opt, 'CVV'),
        [CreditCardField.expirationDate]: () => createInput(ExpirationInput, opt, 'Month / Year'),

        // Address related fields
        [CreditCardField.address.city]: () => createInput(TextInput, opt, 'City'),
        [CreditCardField.address.country]: () => createInput(TextInput, opt, 'Country'),
        [CreditCardField.address.firstName]: () => createInput(TextInput, opt, 'First name'),
        [CreditCardField.address.lastName]: () => createInput(TextInput, opt, 'Last name'),
        [CreditCardField.address.postalCode]: () => createInput(TextInput, opt, 'Postal code'),
        [CreditCardField.address.state]: () => createInput(TextInput, opt, 'State'),
        [CreditCardField.address.street]: () => createInput(TextInput, opt, 'Street'),

        // Submit btn
        [CreditCardField.submit]: () => html`<${CPSubmitBtn} styles=${opt?.styles} opt=${opt} name=${opt.field} />`
    }

    if (optError !== '' ){
        console.error(optError);
        return html`<${CPInput} message={true} placeholder=${optError} disabled />`;
    }

    if (inputMap[opt.field] === undefined) {
        console.error(`Unhandled field type: ${opt.field}`);
        return html`<${CPInput} message={true} placeholder="Invalid field type!" disabled />`;
    }

    return inputMap[opt.field]();
}
