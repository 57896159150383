/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GetStatusResponseTransaction,
    GetStatusResponseTransactionFromJSON,
    GetStatusResponseTransactionFromJSONTyped,
    GetStatusResponseTransactionToJSON,
} from './GetStatusResponseTransaction';

/**
 * 
 * @export
 * @interface GetStatusResponse
 */
export interface GetStatusResponse {
    /**
     * 
     * @type {string}
     * @memberof GetStatusResponse
     */
    paymentStatus?: string;
    /**
     * 
     * @type {GetStatusResponseTransaction}
     * @memberof GetStatusResponse
     */
    transaction?: GetStatusResponseTransaction;
}

export function GetStatusResponseFromJSON(json: any): GetStatusResponse {
    return GetStatusResponseFromJSONTyped(json, false);
}

export function GetStatusResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetStatusResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'paymentStatus': !exists(json, 'payment_status') ? undefined : json['payment_status'],
        'transaction': !exists(json, 'transaction') ? undefined : GetStatusResponseTransactionFromJSON(json['transaction']),
    };
}

export function GetStatusResponseToJSON(value?: GetStatusResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'payment_status': value.paymentStatus,
        'transaction': GetStatusResponseTransactionToJSON(value.transaction),
    };
}

