/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AdditionalConfig,
    AdditionalConfigFromJSON,
    AdditionalConfigFromJSONTyped,
    AdditionalConfigToJSON,
} from './AdditionalConfig';
import {
    ClientConfigAccounts,
    ClientConfigAccountsFromJSON,
    ClientConfigAccountsFromJSONTyped,
    ClientConfigAccountsToJSON,
} from './ClientConfigAccounts';
import {
    Installment,
    InstallmentFromJSON,
    InstallmentFromJSONTyped,
    InstallmentToJSON,
} from './Installment';

/**
 * Contains the merchant configurations.
 * @export
 * @interface ClientConfig
 */
export interface ClientConfig {
    /**
     * The number for the sub-account with which the payment transaction is associated. 
The Account ID is an integer greater than 100000 and account number is an integer smaller than 1000. 
The payment transaction is associated with the default sub-account if you do not provide this parameter. 
CPD recommends you provide this parameter.

     * @type {number}
     * @memberof ClientConfig
     */
    account?: number;
    /**
     * Shows if you have enabled auto-capture.
     * @type {boolean}
     * @memberof ClientConfig
     */
    autoCapture?: boolean;
    /**
     * Boolean flag that mandates cvv field along with the card data.
     * @type {boolean}
     * @memberof ClientConfig
     */
    enableCvv?: boolean;
    /**
     * The unique ID of a merchant for Velocity platform.
     * @type {number}
     * @memberof ClientConfig
     */
    id?: number;
    /**
     * The maximum number of cards stored by a customer.
     * @type {number}
     * @memberof ClientConfig
     */
    maxStoredCards?: number;
    /**
     * A parameter used in SDK to switch between production and staging environment.
     * @type {number}
     * @memberof ClientConfig
     */
    mode?: number;
    /**
     * The type of configuration merchants have done to store cards.

0. Cards are not stored
1. INVALID!
2. Stored cards are available only for specific accounts.
3. Use only stored cards and make them available only for specific accounts. The e-money based prepaid account is unavailable.
4. Stored cards are available for all accounts. *Note: Velocity must be hosted by a Master Merchant.*

     * @type {number}
     * @memberof ClientConfig
     */
    storeCard?: number;
    /**
     * 
     * @type {Installment}
     * @memberof ClientConfig
     */
    installment?: Installment;
    /**
     * 
     * @type {string}
     * @memberof ClientConfig
     */
    name?: string;
    /**
     * The absolute URL to the back office of a merchant where Velocity POP sends the payment status.

> **Note**: If you do not provide this parameter, Velocity uses the default URL.

     * @type {string}
     * @memberof ClientConfig
     */
    callbackUrl?: string | null;
    /**
     * The absolute URL where Velocity directs a customer after successfully completing a payment transaction.

> **Note**: If you do not provide this parameter, Velocity uses the default URL. This parameter is not applicable for most of the API based integrations.

     * @type {string}
     * @memberof ClientConfig
     */
    acceptUrl?: string | null;
    /**
     * The absolute URL where Velocity directs a customer after cancelling the transaction.
     * @type {string}
     * @memberof ClientConfig
     */
    cancelUrl?: string | null;
    /**
     * Custom URL scheme used for app URL switch from web
     * @type {string}
     * @memberof ClientConfig
     */
    appUrl?: string | null;
    /**
     * The absolute URL of the CSS stylesheet hosted by the merchant to apply styles on the hosted payment page.
     * @type {string}
     * @memberof ClientConfig
     */
    cssUrl?: string | null;
    /**
     * The absolute URL of the merchant logo hosted by the merchant to display the logo branding on the hosted payment page.
     * @type {string}
     * @memberof ClientConfig
     */
    logoUrl?: string | null;
    /**
     * The base CDN url wherein the image of the merchant (icons) are hosted.
     * @type {string}
     * @memberof ClientConfig
     */
    baseImageUrl?: string | null;
    /**
     * 
     * @type {AdditionalConfig}
     * @memberof ClientConfig
     */
    additionalConfig?: AdditionalConfig | null;
    /**
     * 
     * @type {ClientConfigAccounts}
     * @memberof ClientConfig
     */
    accounts?: ClientConfigAccounts;
}

export function ClientConfigFromJSON(json: any): ClientConfig {
    return ClientConfigFromJSONTyped(json, false);
}

export function ClientConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'account': !exists(json, 'account') ? undefined : json['account'],
        'autoCapture': !exists(json, 'auto_capture') ? undefined : json['auto_capture'],
        'enableCvv': !exists(json, 'enable_cvv') ? undefined : json['enable_cvv'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'maxStoredCards': !exists(json, 'max_stored_cards') ? undefined : json['max_stored_cards'],
        'mode': !exists(json, 'mode') ? undefined : json['mode'],
        'storeCard': !exists(json, 'store_card') ? undefined : json['store_card'],
        'installment': !exists(json, 'installment') ? undefined : InstallmentFromJSON(json['installment']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'callbackUrl': !exists(json, 'callback_url') ? undefined : json['callback_url'],
        'acceptUrl': !exists(json, 'accept_url') ? undefined : json['accept_url'],
        'cancelUrl': !exists(json, 'cancel_url') ? undefined : json['cancel_url'],
        'appUrl': !exists(json, 'app_url') ? undefined : json['app_url'],
        'cssUrl': !exists(json, 'css_url') ? undefined : json['css_url'],
        'logoUrl': !exists(json, 'logo_url') ? undefined : json['logo_url'],
        'baseImageUrl': !exists(json, 'base_image_url') ? undefined : json['base_image_url'],
        'additionalConfig': !exists(json, 'additional_config') ? undefined : AdditionalConfigFromJSON(json['additional_config']),
        'accounts': !exists(json, 'accounts') ? undefined : ClientConfigAccountsFromJSON(json['accounts']),
    };
}

export function ClientConfigToJSON(value?: ClientConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'account': value.account,
        'auto_capture': value.autoCapture,
        'enable_cvv': value.enableCvv,
        'id': value.id,
        'max_stored_cards': value.maxStoredCards,
        'mode': value.mode,
        'store_card': value.storeCard,
        'installment': InstallmentToJSON(value.installment),
        'name': value.name,
        'callback_url': value.callbackUrl,
        'accept_url': value.acceptUrl,
        'cancel_url': value.cancelUrl,
        'app_url': value.appUrl,
        'css_url': value.cssUrl,
        'logo_url': value.logoUrl,
        'base_image_url': value.baseImageUrl,
        'additional_config': AdditionalConfigToJSON(value.additionalConfig),
        'accounts': ClientConfigAccountsToJSON(value.accounts),
    };
}

