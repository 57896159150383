/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Trip,
    TripFromJSON,
    TripFromJSONTyped,
    TripToJSON,
} from './Trip';

/**
 * 
 * @export
 * @interface AirlineDataTrips
 */
export interface AirlineDataTrips {
    /**
     * 
     * @type {Array<Trip>}
     * @memberof AirlineDataTrips
     */
    trip?: Array<Trip>;
}

export function AirlineDataTripsFromJSON(json: any): AirlineDataTrips {
    return AirlineDataTripsFromJSONTyped(json, false);
}

export function AirlineDataTripsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AirlineDataTrips {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'trip': !exists(json, 'trip') ? undefined : ((json['trip'] as Array<any>).map(TripFromJSON)),
    };
}

export function AirlineDataTripsToJSON(value?: AirlineDataTrips | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'trip': value.trip === undefined ? undefined : ((value.trip as Array<any>).map(TripToJSON)),
    };
}

