/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Address,
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';
import {
    Amount,
    AmountFromJSON,
    AmountFromJSONTyped,
    AmountToJSON,
} from './Amount';
import {
    Cryptogram,
    CryptogramFromJSON,
    CryptogramFromJSONTyped,
    CryptogramToJSON,
} from './Cryptogram';
import {
    Network,
    NetworkFromJSON,
    NetworkFromJSONTyped,
    NetworkToJSON,
} from './Network';

/**
 * 
 * @export
 * @interface AuthorizeTransactionCard
 */
export interface AuthorizeTransactionCard {
    /**
     * 
     * @type {number}
     * @memberof AuthorizeTransactionCard
     */
    id?: number;
    /**
     * 
     * @type {Network}
     * @memberof AuthorizeTransactionCard
     */
    network?: Network;
    /**
     * A type of card.
     * @type {number}
     * @memberof AuthorizeTransactionCard
     */
    typeId: number;
    /**
     * 
     * @type {Amount}
     * @memberof AuthorizeTransactionCard
     */
    amount: Amount;
    /**
     * 
     * @type {string}
     * @memberof AuthorizeTransactionCard
     */
    cvc?: string;
    /**
     * The card number used for transaction.
     * @type {string}
     * @memberof AuthorizeTransactionCard
     */
    cardNumber?: string;
    /**
     * The month on which a card expires.
     * @type {string}
     * @memberof AuthorizeTransactionCard
     */
    expiryMonth?: string;
    /**
     * The year on which a card expires.
     * @type {string}
     * @memberof AuthorizeTransactionCard
     */
    expiryYear?: string;
    /**
     * The date on which a card expires.
     * @type {string}
     * @memberof AuthorizeTransactionCard
     */
    expiry?: string;
    /**
     * The date the card was issued, this field is often used when paying using AMEX cards.
     * @type {string}
     * @memberof AuthorizeTransactionCard
     */
    validFrom?: string;
    /**
     * The name of a card holder.
     * @type {string}
     * @memberof AuthorizeTransactionCard
     */
    cardHolderName?: string;
    /**
     * 
     * @type {Cryptogram}
     * @memberof AuthorizeTransactionCard
     */
    cryptogram?: Cryptogram;
    /**
     * 
     * @type {string}
     * @memberof AuthorizeTransactionCard
     */
    token?: string;
    /**
     * The OAuth verifier sent to the user application along with the access token.
     * @type {string}
     * @memberof AuthorizeTransactionCard
     */
    verifier?: string;
    /**
     * The URL that contains the checkoutId for performing checkout operation with MasterPass.
     * @type {string}
     * @memberof AuthorizeTransactionCard
     */
    checkoutUrl?: string | null;
    /**
     * 
     * @type {Address}
     * @memberof AuthorizeTransactionCard
     */
    address?: Address;
    /**
     * The first six digits of a card number which can be used for fraud screening
     * @type {number}
     * @memberof AuthorizeTransactionCard
     */
    issuerIdentificationNumber?: number;
    /**
     * 
     * @type {string}
     * @memberof AuthorizeTransactionCard
     */
    applePayToken?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthorizeTransactionCard
     */
    visaKey?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthorizeTransactionCard
     */
    visaData?: string;
}

export function AuthorizeTransactionCardFromJSON(json: any): AuthorizeTransactionCard {
    return AuthorizeTransactionCardFromJSONTyped(json, false);
}

export function AuthorizeTransactionCardFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthorizeTransactionCard {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'network': !exists(json, 'network') ? undefined : NetworkFromJSON(json['network']),
        'typeId': json['type_id'],
        'amount': AmountFromJSON(json['amount']),
        'cvc': !exists(json, 'cvc') ? undefined : json['cvc'],
        'cardNumber': !exists(json, 'card_number') ? undefined : json['card_number'],
        'expiryMonth': !exists(json, 'expiry_month') ? undefined : json['expiry_month'],
        'expiryYear': !exists(json, 'expiry_year') ? undefined : json['expiry_year'],
        'expiry': !exists(json, 'expiry') ? undefined : json['expiry'],
        'validFrom': !exists(json, 'valid_from') ? undefined : json['valid_from'],
        'cardHolderName': !exists(json, 'card_holder_name') ? undefined : json['card_holder_name'],
        'cryptogram': !exists(json, 'cryptogram') ? undefined : CryptogramFromJSON(json['cryptogram']),
        'token': !exists(json, 'token') ? undefined : json['token'],
        'verifier': !exists(json, 'verifier') ? undefined : json['verifier'],
        'checkoutUrl': !exists(json, 'checkout_url') ? undefined : json['checkout_url'],
        'address': !exists(json, 'address') ? undefined : AddressFromJSON(json['address']),
        'issuerIdentificationNumber': !exists(json, 'issuer_identification_number') ? undefined : json['issuer_identification_number'],
        'applePayToken': !exists(json, 'apple_pay_token') ? undefined : json['apple_pay_token'],
        'visaKey': !exists(json, 'visa_key') ? undefined : json['visa_key'],
        'visaData': !exists(json, 'visa_data') ? undefined : json['visa_data'],
    };
}

export function AuthorizeTransactionCardToJSON(value?: AuthorizeTransactionCard | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'network': NetworkToJSON(value.network),
        'type_id': value.typeId,
        'amount': AmountToJSON(value.amount),
        'cvc': value.cvc,
        'card_number': value.cardNumber,
        'expiry_month': value.expiryMonth,
        'expiry_year': value.expiryYear,
        'expiry': value.expiry,
        'valid_from': value.validFrom,
        'card_holder_name': value.cardHolderName,
        'cryptogram': CryptogramToJSON(value.cryptogram),
        'token': value.token,
        'verifier': value.verifier,
        'checkout_url': value.checkoutUrl,
        'address': AddressToJSON(value.address),
        'issuer_identification_number': value.issuerIdentificationNumber,
        'apple_pay_token': value.applePayToken,
        'visa_key': value.visaKey,
        'visa_data': value.visaData,
    };
}

