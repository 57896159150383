/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Carrier
 */
export interface Carrier {
    /**
     * 
     * @type {string}
     * @memberof Carrier
     */
    typeId?: string;
    /**
     * 
     * @type {string}
     * @memberof Carrier
     */
    code?: string;
    /**
     * 
     * @type {number}
     * @memberof Carrier
     */
    number?: number;
}

export function CarrierFromJSON(json: any): Carrier {
    return CarrierFromJSONTyped(json, false);
}

export function CarrierFromJSONTyped(json: any, ignoreDiscriminator: boolean): Carrier {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'typeId': !exists(json, 'type_id') ? undefined : json['type_id'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'number': !exists(json, 'number') ? undefined : json['number'],
    };
}

export function CarrierToJSON(value?: Carrier | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type_id': value.typeId,
        'code': value.code,
        'number': value.number,
    };
}

