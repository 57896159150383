/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuthorizeRequest,
    AuthorizeRequestFromJSON,
    AuthorizeRequestToJSON,
    AuthorizeResponse,
    AuthorizeResponseFromJSON,
    AuthorizeResponseToJSON,
    GetStatusRequest,
    GetStatusRequestFromJSON,
    GetStatusRequestToJSON,
    GetStatusResponse,
    GetStatusResponseFromJSON,
    GetStatusResponseToJSON,
    InitializeRequest,
    InitializeRequestFromJSON,
    InitializeRequestToJSON,
    InitializeResponse,
    InitializeResponseFromJSON,
    InitializeResponseToJSON,
    PayRequest,
    PayRequestFromJSON,
    PayRequestToJSON,
    PayResponse,
    PayResponseFromJSON,
    PayResponseToJSON,
    Statuses,
    StatusesFromJSON,
    StatusesToJSON,
} from '../models';

export interface AuthorizeOperationRequest {
    authorization: string;
    hostResolver: string;
    contentType: string;
    authorizeRequest: AuthorizeRequest;
}

export interface InitializeOperationRequest {
    authorization: string;
    hostResolver: string;
    contentType: string;
    initializeRequest: InitializeRequest;
}

export interface PayOperationRequest {
    authorization: string;
    hostResolver: string;
    contentType: string;
    payRequest: PayRequest;
}

export interface StatusRequest {
    authorization: string;
    hostResolver: string;
    contentType: string;
    getStatusRequest: GetStatusRequest;
}

/**
 * 
 */
export class PaymentsApi extends runtime.BaseAPI {

    /**
     * Authorize a payment for a specific payment method.  Capture can be configured to happen automatically, or later via the `/capture` endpoint. 
     * Authorize
     */
    async authorizeRaw(requestParameters: AuthorizeOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AuthorizeResponse>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling authorize.');
        }

        if (requestParameters.hostResolver === null || requestParameters.hostResolver === undefined) {
            throw new runtime.RequiredError('hostResolver','Required parameter requestParameters.hostResolver was null or undefined when calling authorize.');
        }

        if (requestParameters.contentType === null || requestParameters.contentType === undefined) {
            throw new runtime.RequiredError('contentType','Required parameter requestParameters.contentType was null or undefined when calling authorize.');
        }

        if (requestParameters.authorizeRequest === null || requestParameters.authorizeRequest === undefined) {
            throw new runtime.RequiredError('authorizeRequest','Required parameter requestParameters.authorizeRequest was null or undefined when calling authorize.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.hostResolver !== undefined && requestParameters.hostResolver !== null) {
            headerParameters['Host-resolver'] = String(requestParameters.hostResolver);
        }

        if (requestParameters.contentType !== undefined && requestParameters.contentType !== null) {
            headerParameters['Content-Type'] = String(requestParameters.contentType);
        }

        const response = await this.request({
            path: `/payment/authorize`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AuthorizeRequestToJSON(requestParameters.authorizeRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthorizeResponseFromJSON(jsonValue));
    }

    /**
     * Authorize a payment for a specific payment method.  Capture can be configured to happen automatically, or later via the `/capture` endpoint. 
     * Authorize
     */
    async authorize(requestParameters: AuthorizeOperationRequest, initOverrides?: RequestInit): Promise<AuthorizeResponse> {
        const response = await this.authorizeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Initialize a new payment transaction and returns all the available payment methods with the payment card stored for the given end-user. 
     * Initialize
     */
    async initializeRaw(requestParameters: InitializeOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InitializeResponse>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling initialize.');
        }

        if (requestParameters.hostResolver === null || requestParameters.hostResolver === undefined) {
            throw new runtime.RequiredError('hostResolver','Required parameter requestParameters.hostResolver was null or undefined when calling initialize.');
        }

        if (requestParameters.contentType === null || requestParameters.contentType === undefined) {
            throw new runtime.RequiredError('contentType','Required parameter requestParameters.contentType was null or undefined when calling initialize.');
        }

        if (requestParameters.initializeRequest === null || requestParameters.initializeRequest === undefined) {
            throw new runtime.RequiredError('initializeRequest','Required parameter requestParameters.initializeRequest was null or undefined when calling initialize.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.hostResolver !== undefined && requestParameters.hostResolver !== null) {
            headerParameters['Host-resolver'] = String(requestParameters.hostResolver);
        }

        if (requestParameters.contentType !== undefined && requestParameters.contentType !== null) {
            headerParameters['Content-Type'] = String(requestParameters.contentType);
        }

        const response = await this.request({
            path: `/payment/initialize`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InitializeRequestToJSON(requestParameters.initializeRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InitializeResponseFromJSON(jsonValue));
    }

    /**
     * Initialize a new payment transaction and returns all the available payment methods with the payment card stored for the given end-user. 
     * Initialize
     */
    async initialize(requestParameters: InitializeOperationRequest, initOverrides?: RequestInit): Promise<InitializeResponse> {
        const response = await this.initializeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * The Pay request is an internal request from a merchant’s portal to Velocity, when a customer initializes the payment.  The purpose of this request is to:  * Retrieve information about how to proceed with the payment or PSP confirmation which gives an idea about the parameters required to authorize a transaction. * Ensure that a complete audit trail is maintained for each payment transaction.  This request can return different responses depending on the type of payment such as 3ds, non-3ds, wallet, APM, store card. 
     * Pay
     */
    async payRaw(requestParameters: PayOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PayResponse>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling pay.');
        }

        if (requestParameters.hostResolver === null || requestParameters.hostResolver === undefined) {
            throw new runtime.RequiredError('hostResolver','Required parameter requestParameters.hostResolver was null or undefined when calling pay.');
        }

        if (requestParameters.contentType === null || requestParameters.contentType === undefined) {
            throw new runtime.RequiredError('contentType','Required parameter requestParameters.contentType was null or undefined when calling pay.');
        }

        if (requestParameters.payRequest === null || requestParameters.payRequest === undefined) {
            throw new runtime.RequiredError('payRequest','Required parameter requestParameters.payRequest was null or undefined when calling pay.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.hostResolver !== undefined && requestParameters.hostResolver !== null) {
            headerParameters['Host-resolver'] = String(requestParameters.hostResolver);
        }

        if (requestParameters.contentType !== undefined && requestParameters.contentType !== null) {
            headerParameters['Content-Type'] = String(requestParameters.contentType);
        }

        const response = await this.request({
            path: `/payment/pay`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PayRequestToJSON(requestParameters.payRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PayResponseFromJSON(jsonValue));
    }

    /**
     * The Pay request is an internal request from a merchant’s portal to Velocity, when a customer initializes the payment.  The purpose of this request is to:  * Retrieve information about how to proceed with the payment or PSP confirmation which gives an idea about the parameters required to authorize a transaction. * Ensure that a complete audit trail is maintained for each payment transaction.  This request can return different responses depending on the type of payment such as 3ds, non-3ds, wallet, APM, store card. 
     * Pay
     */
    async pay(requestParameters: PayOperationRequest, initOverrides?: RequestInit): Promise<PayResponse> {
        const response = await this.payRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Status of a given transaction. 
     * Status
     */
    async statusRaw(requestParameters: StatusRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GetStatusResponse>> {
        if (requestParameters.authorization === null || requestParameters.authorization === undefined) {
            throw new runtime.RequiredError('authorization','Required parameter requestParameters.authorization was null or undefined when calling status.');
        }

        if (requestParameters.hostResolver === null || requestParameters.hostResolver === undefined) {
            throw new runtime.RequiredError('hostResolver','Required parameter requestParameters.hostResolver was null or undefined when calling status.');
        }

        if (requestParameters.contentType === null || requestParameters.contentType === undefined) {
            throw new runtime.RequiredError('contentType','Required parameter requestParameters.contentType was null or undefined when calling status.');
        }

        if (requestParameters.getStatusRequest === null || requestParameters.getStatusRequest === undefined) {
            throw new runtime.RequiredError('getStatusRequest','Required parameter requestParameters.getStatusRequest was null or undefined when calling status.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.hostResolver !== undefined && requestParameters.hostResolver !== null) {
            headerParameters['Host-resolver'] = String(requestParameters.hostResolver);
        }

        if (requestParameters.contentType !== undefined && requestParameters.contentType !== null) {
            headerParameters['Content-Type'] = String(requestParameters.contentType);
        }

        const response = await this.request({
            path: `/payment/get_status`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetStatusRequestToJSON(requestParameters.getStatusRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetStatusResponseFromJSON(jsonValue));
    }

    /**
     * Status of a given transaction. 
     * Status
     */
    async status(requestParameters: StatusRequest, initOverrides?: RequestInit): Promise<GetStatusResponse> {
        const response = await this.statusRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
