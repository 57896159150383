/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InitializeResponseAmount,
    InitializeResponseAmountFromJSON,
    InitializeResponseAmountFromJSONTyped,
    InitializeResponseAmountToJSON,
} from './InitializeResponseAmount';

/**
 * The payment session that pertains to a sale on the retail platform. 
This session may contain more than one transactions which are made for a single retail purchase.

 * @export
 * @interface Session
 */
export interface Session {
    /**
     * The unique session ID in Velocity.
     * @type {number}
     * @memberof Session
     */
    id?: number;
    /**
     * The total session amount that indicates the retail purchase amount.
     * @type {number}
     * @memberof Session
     */
    totalAmount?: number;
    /**
     * The type of session that the user has opted for, with the assigned integer values: 

1. Full Payment Session - Wherein the payment is completed by the user in one transaction. 
2. Split Payment Session - Wherein the payment is completed in multiple transactions towards the same retails purchase.

     * @type {number}
     * @memberof Session
     */
    type?: number;
    /**
     * 
     * @type {InitializeResponseAmount}
     * @memberof Session
     */
    amount?: InitializeResponseAmount;
    /**
     * 
     * @type {number}
     * @memberof Session
     */
    status?: number;
}

export function SessionFromJSON(json: any): Session {
    return SessionFromJSONTyped(json, false);
}

export function SessionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Session {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'totalAmount': !exists(json, 'total_amount') ? undefined : json['total_amount'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'amount': !exists(json, 'amount') ? undefined : InitializeResponseAmountFromJSON(json['amount']),
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function SessionToJSON(value?: Session | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'total_amount': value.totalAmount,
        'type': value.type,
        'amount': InitializeResponseAmountToJSON(value.amount),
        'status': value.status,
    };
}

