/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AuthorizeResponseUrl,
    AuthorizeResponseUrlFromJSON,
    AuthorizeResponseUrlFromJSONTyped,
    AuthorizeResponseUrlToJSON,
} from './AuthorizeResponseUrl';
import {
    InputFields,
    InputFieldsFromJSON,
    InputFieldsFromJSONTyped,
    InputFieldsToJSON,
} from './InputFields';

/**
 * Action.
 * @export
 * @interface Action
 */
export interface Action {
    /**
     * 
     * @type {number}
     * @memberof Action
     */
    typeId?: number;
    /**
     * 
     * @type {AuthorizeResponseUrl}
     * @memberof Action
     */
    url?: AuthorizeResponseUrl;
    /**
     * Hidden fields contain name value pairs inorder to prepare the request that will be sent to the <url>.
     * @type {{ [key: string]: string; }}
     * @memberof Action
     */
    hiddenFields?: { [key: string]: string; };
    /**
     * 
     * @type {InputFields}
     * @memberof Action
     */
    inputFields?: InputFields;
}

export function ActionFromJSON(json: any): Action {
    return ActionFromJSONTyped(json, false);
}

export function ActionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Action {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'typeId': !exists(json, 'type_id') ? undefined : json['type_id'],
        'url': !exists(json, 'url') ? undefined : AuthorizeResponseUrlFromJSON(json['url']),
        'hiddenFields': !exists(json, 'hidden_fields') ? undefined : json['hidden_fields'],
        'inputFields': !exists(json, 'input_fields') ? undefined : InputFieldsFromJSON(json['input_fields']),
    };
}

export function ActionToJSON(value?: Action | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type_id': value.typeId,
        'url': AuthorizeResponseUrlToJSON(value.url),
        'hidden_fields': value.hiddenFields,
        'input_fields': InputFieldsToJSON(value.inputFields),
    };
}

