import { CardDetails, validateCardDetails } from './validate-card-details';
import { CPDOptions } from '../../types/cpd-init';
import { CPFields, getFields } from './get-fields';
import { getCardType } from './get-card-type';
import { MessageTypes, sendMessage } from './send-message';
import { submitAuthorize } from './submit-authorize';
import { submitGetStatus } from './submit-get-status';
import { submitPay } from './submit-pay';
import { wait } from './wait';
import { PaymentsApi } from '../generated-sources/openapi/apis/PaymentsApi';
import { GetStatusResponse } from '../generated-sources/openapi/models/GetStatusResponse';
import { Address } from '../generated-sources/openapi/models/Address';
import { fixAddress } from './fix-address';

//@TODO, ensure this is blocked to prevent calling it multiple times, and throw an error if already active..
export async function submit(options: CPDOptions, paymentsApi: PaymentsApi) {
    sendMessage({ type: MessageTypes.SUBMITTED, data: 'Form was submitted' });
    const fields: CPFields = getFields();

    try {
        validateCardDetails(fields, options);
    } catch(e) {
        sendMessage({ type: MessageTypes.VALIDATION_ERROR, data: e.message });
        return;
    }

    const cardType = getCardType(fields.cardNumber, options.cardsMeta);
    const cardAddress =fixAddress({
        ...options.cardAddress,
        ...fields.address
    });

    if (cardAddress && cardAddress.country === "") {
        throw (new Error('Card address country is not valid country code'));
    }

    const cardDetails: CardDetails = {
        cardHolderName: fields.cardName,
        cardNumber: fields.cardNumber,
        cardCVV: fields.cardCVV,
        cardExpirationDate: fields.cardExpirationDate,
        cardTypeId: cardType.type_id,
        address: cardAddress
    };

    const billingAddress: Address = fixAddress(options.billingAddress);

    async function submitPollStatusTillDone(options: CPDOptions, paymentsApi: PaymentsApi): Promise<GetStatusResponse> {
        let statusResp = await submitGetStatus(options, paymentsApi);

        if (statusResp.paymentStatus === 'Pending') {
            sendMessage({ type: MessageTypes.STATUS_PENDING, data: 'Payment is pending' });
            await wait(1000);
            statusResp = await submitPollStatusTillDone(options, paymentsApi);
        }

        return statusResp;
    }

    try {
        await submitPay(options, cardDetails, paymentsApi, billingAddress);
        sendMessage({ type: MessageTypes.PAY_SUCCESS, data: 'Pay was a success' });

        await submitAuthorize(options, cardDetails, paymentsApi);
        sendMessage({ type: MessageTypes.AUTHORIZE_SUCCESS, data: 'The payment was authorized' });

        await submitPollStatusTillDone(options, paymentsApi);
        sendMessage({ type: MessageTypes.STATUS_DONE, data: 'Payment has completed' });
    } catch(e) {
        console.error(e);
        sendMessage({ type: MessageTypes.API_ERROR, data: 'An error occurred while talking to CPD API' });
    }
}
