/* tslint:disable */
/* eslint-disable */
/**
 * CellPoint Digital Payment API
 * CellPoint Digital Payment API
 *
 * The version of the OpenAPI document: 2.28.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AirlineDataBillingSummaryFareDetail,
    AirlineDataBillingSummaryFareDetailFromJSON,
    AirlineDataBillingSummaryFareDetailFromJSONTyped,
    AirlineDataBillingSummaryFareDetailToJSON,
} from './AirlineDataBillingSummaryFareDetail';

/**
 * 
 * @export
 * @interface AirlineDataBillingSummary
 */
export interface AirlineDataBillingSummary {
    /**
     * 
     * @type {AirlineDataBillingSummaryFareDetail}
     * @memberof AirlineDataBillingSummary
     */
    fareDetail?: AirlineDataBillingSummaryFareDetail;
}

export function AirlineDataBillingSummaryFromJSON(json: any): AirlineDataBillingSummary {
    return AirlineDataBillingSummaryFromJSONTyped(json, false);
}

export function AirlineDataBillingSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): AirlineDataBillingSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fareDetail': !exists(json, 'fare_detail') ? undefined : AirlineDataBillingSummaryFareDetailFromJSON(json['fare_detail']),
    };
}

export function AirlineDataBillingSummaryToJSON(value?: AirlineDataBillingSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fare_detail': AirlineDataBillingSummaryFareDetailToJSON(value.fareDetail),
    };
}

